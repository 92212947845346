import { baseStage, baseAPIUrl } from '../config';
import _ from 'lodash';

export const getUrl = (baseAction, action, query) => {
    let queryPart = '';
    if (query) {
        _.keys(query).forEach((key, index) => {
            queryPart += `${index === 0 ? '?' : '&'}${key}=${query[key]}`;
        });
    }
    return `${baseAPIUrl}/${baseAction}-${baseStage}/${action}${queryPart}`
}