import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button } from '../../components';
import { automationActions } from '../../webapi';
import { whiteLabelLoaded } from '../../actions';
import { isTheBest, getApiError } from '../../session';
import { renderTitle, renderDescription, renderTextStep, renderOpenLinkStep } from './helper';

const STEP_HOSTING = '1';
const STEP_DEPLOY = '2';
const STEP_INIT = '3';
const STEP_TC = '4';
const STEP_SUBDOMAIN = '5';

class WebSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      message: {},
    };
    this.steps = [
      { label: 'Set up Hosting', name: STEP_HOSTING, render: this.renderStep1 },
      { label: 'Deploy', name: STEP_DEPLOY, render: this.renderStep2 },
      { label: 'Initialise Site', name: STEP_INIT, render: this.renderStep3 },
      { label: 'Set up Terms & Conditions', name: STEP_TC, render: this.renderStep4 },
      { label: 'Set up Sub Domain', name: STEP_SUBDOMAIN, render: this.renderStep5 },
    ];
  }

  canPerformSetup = () => {
    const { activeWhiteLabel } = this.props;
    const { loading } = this.state;
    return activeWhiteLabel.RowId && activeWhiteLabel.AWSAccessKey && activeWhiteLabel.AWSSecretKey && !loading;
  };

  canSetupHosting = () => this.canPerformSetup();

  canInitialiseSite = () => {
    const { activeWhiteLabel } = this.props;
    return this.canPerformSetup() && activeWhiteLabel.ApiGatewayName && activeWhiteLabel.HostingDomainName;
  };

  canSetupSubdomain = () => {
    const { activeWhiteLabel } = this.props;
    return this.canPerformSetup() && activeWhiteLabel.HostingDomainName;
  };

  canSetupTC = () => {
    const { activeWhiteLabel } = this.props;
    return this.canPerformSetup() && activeWhiteLabel.HostingUrl && activeWhiteLabel.AdminIntialised;
  };

  onMoveStep = (stepIndex) => {
    if (this.props.onMoveStep) this.props.onMoveStep(stepIndex);
  };

  onSetup = (canSetup, step, inProgressMessage, action) => {
    if (canSetup && !canSetup()) return;

    const { message } = this.state;
    const { activeWhiteLabel } = this.props;

    message[step] = inProgressMessage;
    this.setState({ loading: true, message }, async () => {
      try {
        const { data } = await automationActions.setupWeb(activeWhiteLabel.RowId, action);
        this.props.whiteLabelLoaded(data);
        message[step] = '';
        this.setState({ loading: false });
      } catch (error) {
        message[step] = getApiError(error).message;
        this.setState({ loading: false, message });
      }
    });
  };

  renderHostingUrl = () => {
    const { activeWhiteLabel } = this.props;
    return (
      <div>
        Hosting Domain Url:{' '}
        <a href={`https://${activeWhiteLabel.HostingDomainName}`} target="_blank" rel="noopener noreferrer">
          {activeWhiteLabel.HostingDomainName}
        </a>
        <FontAwesome className="marginLeft-10 text-teal" name={'check'} />
      </div>
    );
  };

  renderSetupHosting = () => {
    const { activeWhiteLabel } = this.props;
    const { message } = this.state;
    const exists =
      !_.isEmpty(activeWhiteLabel.HostingBucket) &&
      !_.isEmpty(activeWhiteLabel.HostingUrl) &&
      !_.isEmpty(activeWhiteLabel.HostingDomainId) &&
      !_.isEmpty(activeWhiteLabel.HostingDomainName);
    const isValid = this.canSetupHosting();
    return (
      <div className="flex flex-row flex-center marginTop-10">
        {exists ? (
          <div>
            <div>
              {`Hosting Bucket: ${activeWhiteLabel.HostingBucket}`}
              <FontAwesome className="marginLeft-10 text-teal" name={'check'} />
            </div>
            <div>
              {`Hosting Domain Id: ${activeWhiteLabel.HostingDomainId}`}
              <FontAwesome className="marginLeft-10 text-teal" name={'check'} />
            </div>
            {this.renderHostingUrl()}
          </div>
        ) : (
          <Button
            style={{ width: 90 }}
            inline
            buttonType="primary"
            onClick={() => this.onSetup(this.canSetupHosting, STEP_HOSTING, 'Setting up hosting for admin console...', 'hosting')}
            isActive={isValid}
          >
            Setup
          </Button>
        )}
        <div className="marginLeft-16">{message[STEP_HOSTING]}</div>
      </div>
    );
  };

  renderSetupTC = () => {
    const { activeWhiteLabel } = this.props;
    const { message } = this.state;
    const exists = activeWhiteLabel.TermsSet;
    const isValid = this.canSetupTC();
    return (
      <div className="marginTop-10">
        <div>
          Terms & Conditions Set:
          {exists ? (
            <FontAwesome className="marginLeft-10 text-teal" name={'check'} />
          ) : (
            <FontAwesome className="marginLeft-10 text-plussRed" name={'times'} />
          )}
        </div>
        {!exists ? (
          <div className="flex flex-row flex-center marginTop-10">
            <Button
              style={{ width: 90 }}
              inline
              buttonType="primary"
              onClick={() => this.onSetup(this.canSetupTC, STEP_TC, 'Setting up default terms and conditions...', 'tc')}
              isActive={isValid}
            >
              Setup
            </Button>
            <div className="marginLeft-16">{message[STEP_TC]}</div>
          </div>
        ) : null}
      </div>
    );
  };

  renderInitialise = () => {
    const { activeWhiteLabel } = this.props;
    const { message } = this.state;
    const exists = !_.isEmpty(activeWhiteLabel.HostingUrl) && activeWhiteLabel.AdminIntialised;
    const isValid = this.canInitialiseSite();
    return (
      <div className="flex flex-row flex-center marginTop-10">
        {exists ? (
          <div>
            <div>
              {`Initialised: ${activeWhiteLabel.AdminIntialised}`}
              {activeWhiteLabel.AdminIntialised ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}
            </div>
            {this.renderHostingUrl()}
          </div>
        ) : (
          <Button
            style={{ width: 90 }}
            inline
            buttonType="primary"
            onClick={() => this.onSetup(this.canInitialiseSite, STEP_INIT, 'Initialising site...', 'init')}
            isActive={isValid}
          >
            Initialise
          </Button>
        )}
        <div className="marginLeft-16">{message[STEP_INIT]}</div>
      </div>
    );
  };

  renderSetupSubdomain = () => {
    const { activeWhiteLabel } = this.props;
    const { message } = this.state;
    const exists = !_.isEmpty(activeWhiteLabel.HostingDomainUrl);
    const isValid = this.canSetupSubdomain();

    return (
      <div className="flex flex-row flex-center marginTop-10">
        {exists ? (
          <div>
            <div>
              Url:{' '}
              <a href={`https://${activeWhiteLabel.HostingDomainUrl}`} target="_blank" rel="noopener noreferrer">
                {activeWhiteLabel.HostingDomainUrl}
              </a>
              <FontAwesome className="marginLeft-10 text-teal" name={'check'} />
            </div>
            <div className="marginTop-5">
              <b>Note:</b> It could take as long as 8 hours before the above URL can be reached.
            </div>
          </div>
        ) : (
          <Button
            style={{ width: 90 }}
            inline
            buttonType="primary"
            onClick={() => this.onSetup(this.canSetupSubdomain, STEP_SUBDOMAIN, 'Setting up the sub domain...', 'subdomain')}
            isActive={isValid}
          >
            Setup
          </Button>
        )}
        <div className="marginLeft-16">{message[STEP_SUBDOMAIN]}</div>
      </div>
    );
  };

  renderStep1 = () => {
    return (
      <div>
        {renderTitle('Set up Hosting')}
        {renderDescription('In this step, we will setup hosting for the admin console website.')}
        <ol>
          {renderTextStep(
            <div>
              Create hosting
              {this.renderSetupHosting()}
            </div>,
          )}
        </ol>
      </div>
    );
  };

  renderStep2 = () => {
    const { activeWhiteLabel } = this.props;

    return (
      <div>
        {renderTitle('Deploy the Community Manager')}
        {renderDescription('In this step, we will deploy the Community Manager and required backend services.')}
        <ol>
          {renderTextStep(
            <div>
              Click on the <b>Deploy</b> menu on the left.
            </div>,
          )}
          {renderTextStep(
            <div>
              Under the <b>Deploy Environment</b> section, select <b>{activeWhiteLabel.ClientCode}</b> environment.
            </div>,
          )}
          {renderTextStep(
            <div>
              Once loaded, under the <b>Deploy AWS Branch</b> section, click <b>Deploy</b> button to start deploying the backend services
              required for the Community Manager.
            </div>,
          )}
          {renderTextStep(
            <div>
              Under the <b>Deploy Web Branch</b> section, click <b>Deploy</b> button to start deploying the Community Manager.
            </div>,
          )}
          {renderTextStep(
            <div>
              <b>DO NOT</b> deploy App Branch at this stage, this will be done once iOS and Android app has been set up.
            </div>,
          )}
          {renderTextStep(
            <div>
              Wait for the deployments to complete by monitoring the build queue at the top. This is required for initialising the site and
              setting up default terms and conditions.
            </div>,
          )}
        </ol>
      </div>
    );
  };

  renderStep3 = () => {
    return (
      <div>
        {renderTitle('Initialise Site Data')}
        {renderDescription('In this step, we will create master users and setup default user types.')}
        <ol>
          {renderTextStep(
            <div>
              Initialise the site
              {this.renderInitialise()}
            </div>,
          )}
        </ol>
      </div>
    );
  };

  renderStep4 = () => {
    return (
      <div>
        {renderTitle('Set up Terms & Conditions')}
        {renderDescription('In this step, we will setup the terms and conditions for the app.')}
        <ol>
          {renderTextStep(
            <div>
              Setup default Terms & Conditions
              {this.renderSetupTC()}
            </div>,
          )}
        </ol>
      </div>
    );
  };

  renderStep5 = () => {
    const { activeWhiteLabel } = this.props;
    return (
      <div>
        {renderTitle('Set up Sub Domain')}
        {renderDescription(
          'In this step, we will setup sub domain for the admin console website. This is an optional step and is not required to access the Commnity Manager.',
        )}
        <ol>
          {renderTextStep(
            <div>
              Follow the steps below in order to setup a sub domain for the Community Manager. Otherwise, you can access the Community
              Manager using the folloiwng link.
              <br />
              <br />
              {this.renderHostingUrl()}
            </div>,
          )}
          {renderTextStep(
            <div>
              Create sub domain
              {this.renderSetupSubdomain()}
            </div>,
          )}
        </ol>
      </div>
    );
  };

  render() {
    if (!isTheBest(this.props.auth, true)) return null;

    const { stepIndex } = this.props;
    const step = stepIndex && stepIndex < this.steps.length ? stepIndex : 0;
    return (
      <div className="flex-1 automation">
        <StepProgressBar
          startingStep={step}
          progressClass="progressBar"
          primaryBtnClass="primaryBtn"
          secondaryBtnClass="secondaryBtn"
          submitBtnName="Done"
          onPrevious={() => this.onMoveStep(step - 1)}
          onNext={() => this.onMoveStep(step + 1)}
          onSubmit={() => (this.props.onDone ? this.props.onDone() : null)}
          steps={this.steps}
        />
        {this.steps[step].render()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, automation } = state;
  return {
    auth,
    activeWhiteLabel: automation.active,
  };
};

export default connect(mapStateToProps, { whiteLabelLoaded })(WebSetup);
