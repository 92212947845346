import moment from 'moment';
import _ from 'lodash';
import { getUrl } from './helper';
import { getUTCFromTimeDatePickers } from '../helper';
import { authedFunction } from '../session';
import { baseClient, baseStage, baseAPIUrl } from '../config';

export const liveStreamActions = {
  getInitialLiveStreamState: () => {
    return {
      streamInfo: null,
      hasLiveStream: false,
      ownLiveStream: true,
      streamUrl: '',
      streamDate: moment().format('YYYY-MM-DD'),
      streamDateText: moment().format('DD/MM/YYYY'),
      showStreamDate: false,
      streamStartTime: '12:00pm',
      streamEndTime: '1:00pm',
      liveBroadcasters: [],
      broadcasterSelectorVisible: false,
    };
  },
  parseLiveStreamInfo: (item) => {
    let extraState = {};
    if (!_.isNil(item.StreamInfo) && !_.isEmpty(item.StreamInfo)) {
      const { StreamInfo } = item;

      extraState.streamInfo = StreamInfo;
      extraState.hasLiveStream = true;
      if (StreamInfo.start) {
        const streamStart = moment(StreamInfo.start);
        extraState.streamDate = streamStart.format('YYYY-MM-DD');
        extraState.streamDateText = streamStart.format('DD/MM/YYYY');
        extraState.streamStartTime = streamStart.format('hh:mm a');
      }
      if (StreamInfo.end) {
        const streamEnd = moment(StreamInfo.end);
        extraState.streamEndTime = streamEnd.format('hh:mm a');
      }

      if (_.isNil(StreamInfo.streamId)) {
        extraState.ownLiveStream = false;
        extraState.streamUrl = StreamInfo.streamUrl;
        extraState.liveBroadcasters = [];
      } else {
        extraState.ownLiveStream = true;
        extraState.streamUrl = '';
        extraState.liveBroadcasters = StreamInfo.broadcasters;
      }
    } else {
      extraState = liveStreamActions.getInitialLiveStreamState();
    }
    return extraState;
  },
  validateLiveStream: (state) => {
    if (state.hasLiveStream) {
      const streamStart = getUTCFromTimeDatePickers(state.streamDate, state.streamStartTime).valueOf();
      const streamEnd = getUTCFromTimeDatePickers(state.streamDate, state.streamEndTime).valueOf();
      return (
        !_.isEmpty(state.streamDate) &&
        !_.isEmpty(state.streamStartTime) &&
        !_.isEmpty(state.streamEndTime) &&
        streamStart < streamEnd &&
        (state.ownLiveStream || !_.isEmpty(state.streamUrl)) &&
        (!state.ownLiveStream || !_.isEmpty(state.liveBroadcasters))
      );
    }
    return true;
  },
  compileLiveStream: (state, type) => {
    return new Promise(async (resolve) => {
      try {
        const { hasLiveStream, ownLiveStream, streamInfo } = state;
        const ownStreamExists = streamInfo && streamInfo.streamId;
        let output = {};
        if (hasLiveStream) {
          let streamUpdate;
          if (!ownLiveStream && state.streamUrl) {
            streamUpdate = { streamUrl: state.streamUrl };
            // Remove existing own stream if existed
            if (ownStreamExists) await liveStreamActions.deleteLiveStream(streamInfo.streamId);
            streamUpdate.broadcasters = [];
          } else {
            // Create new own stream
            streamUpdate = ownStreamExists ? streamInfo : (await liveStreamActions.createLiveStream(type)).data;
            streamUpdate.broadcasters = state.liveBroadcasters;
          }
          streamUpdate.start = getUTCFromTimeDatePickers(state.streamDate, state.streamStartTime).valueOf();
          streamUpdate.end = getUTCFromTimeDatePickers(state.streamDate, state.streamEndTime).valueOf();
          output = {
            StreamId: streamUpdate.streamId,
            StreamInfo: streamUpdate,
          };
        } else {
          // Removing stream info
          if (ownStreamExists) await liveStreamActions.deleteLiveStream(streamInfo.streamId);
          output = {
            StreamId: undefined,
            StreamInfo: undefined,
          };
        }
        resolve(output);
      } catch (error) {
        console.log('compileLiveStream', error);
        resolve({});
      }
    });
  },
  createLiveStream: (type) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('livestream', 'createLiveStream'),
      data: {
        type,
        apiUrl: baseAPIUrl,
        client: baseClient,
        stage: baseStage,
      },
    });
  },
  deleteLiveStream: (streamId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('livestream', 'deleteLiveStream'),
      data: {
        streamId,
      },
    });
  },
};
