import _ from 'lodash';
import { WHITE_LABEL_LOADED, WHITE_LABEL_ADDED, WHITE_LABEL_PURGE } from '../actions/types';

const INITIAL_INFO = {
  RowId: '',
  ClientCode: '',
  ClientName: '',
  Section: 0,
  Step: 0,
};
const INITIAL_STATE = {
  active: _.clone(INITIAL_INFO),
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WHITE_LABEL_LOADED:
    case WHITE_LABEL_PURGE:
      return { ...state, active: action.payload || _.clone(INITIAL_INFO) };
    case WHITE_LABEL_ADDED:
      const active = _.clone(INITIAL_INFO);
      if (action.payload) active.ClientCode = action.payload;
      return { active };
    default:
      return state;
  }
};
