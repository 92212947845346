import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { userActions } from '../../webapi';
import { usersUpdate } from '../../actions';
import { Button, OverlayPage, OverlayPageContents, OverlayPageSection, OverlayPageBottomButtons, CheckBox } from '../../components';

import { TEXT_LIGHT, COLOUR_TEAL, COLOUR_RED } from '../../js';

class BulkUploads extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,

            data: [],

            chosenFile: null
        };
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        /*   this.onNewProps(nextProps); */
    }

    UNSAFE_componentWillMount() {
        /*    this.onNewProps(this.props); */
    }

    componentDidMount() {
        this.setState({
            loading: true
        });

        userActions.getbulks(this.props.auth.site)
            .then((res) => {
                console.log(res.data)
                this.setState({
                    loading: false,
                    data: res.data.bulkList.Items
                })
            }).catch((error) => {
                this.setState({ loading: false, PageError: 'Something went wrong. Please try again.' });
                console.log(error);
                // console.log(error.response.data.error.message)
            });
    }

    renderFileDetails() {
        if (this.state.chosenFile != null) {
            return (
                <div className="padding-60 paddingVertical-40">
                    <p className="text-sectionTitle">
                        UPLOAD RESULTS
                    </p>
                    <div className='marginTop-24'>
                        {this.state.chosenFile.Users.map((ev, index) => {
                            return (
                                <div
                                    key={index}
                                    className='genericBoxShadow padding-8 flex flex-center marginBottom-5'
                                >
                                    <div className='fontRegular' style={{ minWidth: 100 }}>
                                        {ev.Name.substring(0, 30)}
                                    </div>
                                    <div className='fontHeavy' style={{ minWidth: 100, color: _.isUndefined(ev.Success) ? TEXT_LIGHT : ev.Success ? COLOUR_TEAL : COLOUR_RED }}>
                                        {_.isUndefined(ev.Success) ? 'Not Started' : ev.Success ? 'Success' : 'Fail'}
                                    </div>
                                    {!_.isUndefined(ev.Success) && !ev.Success &&
                                        <div className='fontRegular'>
                                            {ev.Message}
                                        </div>
                                    }
                                </div>
                            );
                        })}
                    </div>
                </div>
            )
        }
    }

    getColour(ev) {
        if (ev.JobStatus === 'Complete') {
            return COLOUR_TEAL
        }
        return TEXT_LIGHT;
    }

    renderFiles() {
        if (!_.isEmpty(this.state.data)) {
            return (
                <div className='marginTop-24'>
                    {this.state.data.map((ev, index) => {
                        return (
                            <div
                                key={index}
                                onClick={() => { console.log(ev); this.setState({ chosenFile: ev }); }}
                                className='pointer genericBoxShadow padding-8 flex flex-center flex-between marginBottom-8'
                            >
                                <div className='flex flex-center'>
                                    <CheckBox
                                        // label={'Is Active'}
                                        isActive={this.state.chosenFile != null && this.state.chosenFile.RowId === ev.RowId}
                                        onChange={() => { }}
                                        style={{ marginRight: 14, marginBottom: 0 }}
                                    />
                                    <div className='fontRegular' style={{ minWidth: 250 }}>
                                        Uploaded on {moment.unix(ev.CreatedUnix).local().format('hh:mm a, Do MMM YY ')}
                                    </div>
                                    <div className='fontRegular'>
                                        Users to upload: {ev.Users.length}
                                    </div>
                                </div>
                                <div
                                    className='fontHeavy'
                                    style={{
                                        marginRight: 8,
                                        color: this.getColour(ev)
                                    }}
                                >
                                    {ev.JobStatus}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )
        }
    }

    renderPage() {
        return (
            <div>
                <div className="padding-60 paddingVertical-40 bottomDivideBorder">
                    <p className="text-sectionTitle">
                        BULK UPLOADS
                    </p>
                    {this.renderFiles()}
                </div>
                {this.renderFileDetails()}
            </div>
        )
    }

    render() {
        return (
            <OverlayPage>
                <OverlayPageContents>
                    <OverlayPageSection className='pageSectionWrapper--newPopup'>
                        {this.renderPage()}
                    </OverlayPageSection>
                </OverlayPageContents>
                <OverlayPageBottomButtons>
                    <Button
                        inline
                        buttonType='tertiary'
                        onClick={() => { window.history.back(); }}
                        isActive
                    >
                        Back
                    </Button>
                </OverlayPageBottomButtons>
            </OverlayPage>
        );
    }
}

const mapStateToProps = (state) => {
    const { auth } = state;
    return { auth };
};

export default connect(mapStateToProps, { usersUpdate })(withRouter(BulkUploads));