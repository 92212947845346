import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import { Header, AddButton } from '../../components';
import { validateAccess } from '../../session';
import { offersLoaded, setAuth } from '../../actions';
import { marketActions } from '../../webapi';
import { COLOUR_BRANDING_OFF } from '../../js';
import Offers from './Offers';
import OffersHubAnalytics from './OffersHubAnalytics';

class OffersHub extends Component {
  state = {
    selectedSection: 'current',
    location: '',

    loadingOffers: false,

    offers: [],
    currentOffers: [],
    pastOffers: [],
    now: moment.utc().valueOf(),
  };

  UNSAFE_componentWillMount() {
    this.updateProps(this.props);

    this.loadOffers();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps);
  }

  updateProps(props) {
    const offers = [];

    props.offers.forEach((ev) => {
      if (ev != null && !ev.Deleted) {
        offers.push(ev);
      }
    });

    const currentOffers = _.filter(offers, (ev) => {
      return this.state.now > ev.StartUnixTimestamp && this.state.now < ev.ExpiryUnixTimestamp;
    });

    const pastOffers = _.filter(offers, (ev) => {
      return this.state.now > ev.ExpiryUnixTimestamp;
    });

    this.setState({ offers, currentOffers, pastOffers });
  }

  loadOffers() {
    this.setState({
      loadingOffers: true,
    });

    marketActions
      .getOffers(this.props.auth.site)
      .then((res) => {
        this.setState({
          loadingOffers: false,
        });
        if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
          this.props.offersLoaded(res.data);
        }
      })
      .catch((res) => {
        this.setState({ loadingOffers: false });
        alert('Something went wrong with the request. Please try again.');
      });
  }

  addNew() {
    if (this.canAddNew()) {
      this.props.history.push(`/offers/offer`);
    }
  }

  canAddNew(isClass) {
    if (validateAccess(this.props.auth.site, 'offers', this.props.auth)) {
      return isClass ? '' : true;
    }
    return isClass ? ' hub-sideContent-topButton--hide' : false;
  }

  getSideBarSectionColour(id) {
    return this.state.selectedSection === id ? { backgroundColor: '#fff' } : {};
  }

  renderStats(component, loading) {
    if (loading) {
      return <FontAwesome style={styles.spinner} name="spinner fa-pulse fa-fw" />;
    }
    return component;
  }

  renderLeftBar() {
    return (
      <div className="hub-sideContent">
        {/* Top Add Button */}
        <div className="hub-sideContent-topButton" />
        <div style={{ paddingLeft: 15, width: '100%' }}>
          {/* Title */}
          <div className="fontMedium fontSize-36 text-dark" style={styles.sideBarTitleSection}>
            Offers
          </div>
          {/* Content */}
          {/* Analytics */}
          <div
            onClick={() => {
              this.setState({ selectedSection: 'Analytics' });
            }}
            className="sideBarSection"
            style={this.getSideBarSectionColour('Analytics')}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              <FontAwesome name="line-chart" className="sideBarSection__icon" />
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">Analytics</div>
          </div>
          {/* All Offers */}
          <div
            onClick={() => {
              this.setState({ selectedSection: 'all' });
            }}
            className="sideBarSection"
            style={this.getSideBarSectionColour('all')}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats(this.state.offers.length, this.state.loadingOffers)}
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">All Offers</div>
          </div>
          {/* current Offers */}
          <div
            onClick={() => {
              this.setState({ selectedSection: 'current' });
            }}
            className="sideBarSection"
            style={this.getSideBarSectionColour('current')}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats(this.state.currentOffers.length, this.state.loadingOffers)}
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">Current Offers</div>
          </div>
          {/* Past Offers */}
          <div
            onClick={() => {
              this.setState({ selectedSection: 'past' });
            }}
            className="sideBarSection"
            style={this.getSideBarSectionColour('past')}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats(this.state.pastOffers.length, this.state.loadingOffers)}
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">Past Offers</div>
          </div>
        </div>
      </div>
    );
  }

  renderRight() {
    if (this.state.selectedSection === 'Analytics') {
      return <OffersHubAnalytics offers={this.state.offers} />;
    } else {
      return <Offers filterOption={this.state.selectedSection} />;
    }
  }

  render() {
    return (
      <div className="hub-wrapperContainer">
        {this.renderLeftBar()}
        <div className="hub-headerContentWrapper">
          <Header>{this.canAddNew() && <AddButton onClick={this.addNew.bind(this)} text="NEW OFFER" />}</Header>
          <div className="hub-contentWrapper">{this.renderRight()}</div>
        </div>
      </div>
    );
  }
}

const styles = {
  sideBarTitleSection: {
    lineHeight: '50px',
    marginTop: 30,
    marginBottom: 30,
    paddingLeft: 24,
  },
  sideBarSection: {
    weight: '100%',
    minWidth: 200,
    padding: 32,
    paddingLeft: 24,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  spinner: {
    fontSize: 32,
    color: COLOUR_BRANDING_OFF,
  },
};

const mapStateToProps = (state) => {
  const { offers } = state.market;
  const { auth } = state;
  return {
    offers,
    auth,
  };
};

export default connect(mapStateToProps, { offersLoaded, setAuth })(OffersHub);
