import React, { Component } from 'react';
import { FormGroup } from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { offersUpdate, addRecentlyCreated } from '../../actions';
import {
  Button,
  ImageInput,
  GenericInput,
  OverlayPage,
  OverlayPageContents,
  OverlayPageSection,
  OverlayPageBottomButtons,
  SuccessPopup,
  DatePicker,
  RadioButton,
  TextFormatPopup,
  OptionsSection,
  Tag,
  FileInput,
  P60Icon,
  DropdownInput,
} from '../../components';
import { marketActions, fileActions } from '../../webapi';
import { safeReadParams, getUTCFromTimeDatePickers, getThumb300, get1400, getFileName, isEmail } from '../../helper';
import { checkLoggedIn } from '../../session';
import { Text } from '../../components/text';
import bookingTypes from '../../json/eventBookingTypes.json';

class AddOffer extends Component {
  initialState = {
    infoId: safeReadParams(this.props, 'infoId'),
    titleInput: '',
    subtitleInput: '',
    descriptionInput: '',
    voucherInput: '',
    termsInput: '',

    startDate: null,
    startDateText: '',

    expiryDate: null,
    expiryDateText: '',
    shouldNotify: false,

    isTvMode: false,
    showWarnings: false,
    success: false,
    submitting: false,

    buttonText: '',
    buttonLink: '',
    emailRequestBooking: '',
    hasBookings: false,
    bookingData: {
      type: 'External',
    },

    offerFields: [],
    additionalInfo: [],
    addingGeneralTag: false,
    generalTagInput: '',
    generalTags: [],
    availableGeneralTags: [],
  };

  state = { ...this.initialState };

  UNSAFE_componentWillReceiveProps(nextProps) {}

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
    if (this.state.infoId) {
      this.getData();
    }
  }

  componentDidMount() {
    this.getGeneralTags();
    this.getOfferFields();

    this.props.addRecentlyCreated('offers');
  }

  getGeneralTags() {
    marketActions.getGeneralTags(this.props.auth.site).then((res) => {
      this.setState({
        availableGeneralTags: _.sortBy(res.data, (t) => t.Tag).map((t) => {
          return t.Tag;
        }),
      });
    });
  }

  getOfferFields = async () => {
    try {
      const { data } = await marketActions.getOfferFields(this.props.auth.site);
      // console.log('getOfferFields', data);
      this.setState({ offerFields: data });
    } catch (error) {
      console.error('getOfferFields error', error);
    }
  };

  getData() {
    marketActions.getOffer(this.state.infoId).then((res) => {
      // console.log('getData', res.data);
      this.parseUpdate(res.data);
    });
  }

  selectOption = (o) => {
    this.setState({
      selectedOption: o,
    });
  };

  parseUpdate(update) {
    const newState = {
      titleInput: update.Title,
      subtitleInput: update.Subtitle,
      descriptionInput: update.Description,
      voucherInput: update.Voucher,
      termsInput: update.Terms,

      startDate:
        update.StartUnixTimestamp !== Number.MAX_SAFE_INTEGER && update.StartUnixTimestamp !== 0
          ? moment.utc(update.StartUnixTimestamp).local().format('YYYY-MM-DD')
          : null,
      startDateText:
        update.StartUnixTimestamp !== Number.MAX_SAFE_INTEGER && update.StartUnixTimestamp !== 0
          ? moment.utc(update.StartUnixTimestamp).local().format('DD/MM/YYYY')
          : '',

      expiryDate:
        update.ExpiryUnixTimestamp !== Number.MAX_SAFE_INTEGER ? moment.utc(update.ExpiryUnixTimestamp).local().format('YYYY-MM-DD') : null,
      expiryDateText:
        update.ExpiryUnixTimestamp !== Number.MAX_SAFE_INTEGER ? moment.utc(update.ExpiryUnixTimestamp).local().format('DD/MM/YYYY') : '',
      isTvMode: Boolean(update.TVMode),
      image: update.Image,
      generalTags: update.GeneralTags || [],
      additionalInfo: update.AdditionalInfo || [],
    };

    if (!_.isUndefined(update.HasBookingOptions) && update.HasBookingOptions) {
      newState.hasBookings = true;
      newState.bookingData = update.BookingData;

      // if external booking type, set booking button/link fields
      if (
        update.BookingData.type === 'External' &&
        !_.isUndefined(update.BookingData.ButtonText) &&
        !_.isUndefined(update.BookingData.ButtonLink)
      ) {
        newState.buttonText = update.BookingData.ButtonText;
        newState.buttonLink = update.BookingData.ButtonLink;
      }
      if (update.BookingData.type === 'EmailRequest') {
        newState.emailRequestBooking = update.BookingData.RequestEmail;
      }
    }

    this.setState(newState);
    this.checkSetImage(this.refs.imageInput, update.Image);
    this.checkSetImage(this.refs.logoInput, update.TVModeLogo);
  }

  checkSetImage(ref, url) {
    if (ref) {
      ref.getWrappedInstance().setValue(url);
    } else {
      setTimeout(() => {
        this.checkSetImage(ref, url);
      }, 100);
    }
  }

  isEmpty(text) {
    return text === '';
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  onOfferFieldChange = (event) => {
    const additionalInfo = this.getAdditionalInfoState();
    const field = additionalInfo.find((f) => f.key === event.target.getAttribute('id'));
    if (field) field.value = event.target.value;
    this.setState({ additionalInfo });
  };

  handleDateClear = (key) => {
    this.setState({
      [`${key}`]: null,
      [`${key}Text`]: '',
      [`${key}Show`]: false,
    });
  };

  handleDateTextChange(key, value) {
    const newState = { [`${key}Text`]: value };
    const m = moment(value, 'DD/MM/YYYY');

    if (m.isValid() && m.year() > 1900) {
      newState[key] = m.format('YYYY-MM-DD');
    }
    this.setState(newState);
  }

  handleDateChange(key, date) {
    var stateChange = {
      [`${key}`]: date,
      [`${key}Text`]: moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      [`${key}Show`]: false,
    };
    this.setState(stateChange);
  }

  handleFileChange(event) {
    const file = event.target.files[0];
    if (!file || this.state.uploadingImage) {
      return;
    }
    this.setState({
      uploadingImage: true,
    });
    fileActions
      .uploadMediaAsync(file, file.name)
      .then((fileRes) => {
        this.setState({
          sourceInput: fileRes,
          uploadingImage: false,
        });
      })
      .catch((uploadErrorRes) => {
        this.setState({
          uploadingImage: false,
        });
      });
  }

  onImageUpdated = (url) => {
    this.setState({
      image: url,
    });
  };

  toggleTextFormat(isOpen) {
    this.setState({
      textFormatOpen: isOpen,
    });
  }

  validateLoading() {
    if (this.state.submitting) {
      return false;
    }
    return true;
  }

  validateImage() {
    return this.state.image && this.state.image.match(/\.(jpeg|jpg|gif|png|ashx)/) != null;
  }

  validateCompulsoryText() {
    if (this.isEmpty(this.state.titleInput)) {
      return false;
    }
    if (!this.validateImage()) {
      return false;
    }
    return true;
  }

  isValidLink() {
    const regexp = /(http:\/\/|https:\/\/)/;
    return regexp.test(this.state.buttonLink);
  }

  validateButtonLink() {
    const { hasBookings, bookingData, buttonText, buttonLink } = this.state;
    return hasBookings && bookingData.type === 'External' && (this.isEmpty(buttonText) || this.isEmpty(buttonLink)) ? false : true;
  }

  validateEmailRequestField = () => {
    const { hasBookings, bookingData, emailRequestBooking } = this.state;
    return hasBookings && bookingData.type === 'EmailRequest' && (this.isEmpty(emailRequestBooking) || !isEmail(emailRequestBooking))
      ? false
      : true;
  };

  validateForm() {
    if (!this.validateCompulsoryText()) {
      return false;
    }
    if (!this.validateLoading()) {
      return false;
    }
    if (!this.validateButtonLink()) {
      return false;
    }
    if (!this.validateEmailRequestField()) {
      return false;
    }
    return true;
  }

  compileJson() {
    const result = {
      Title: this.state.titleInput,
      Subtitle: this.state.subtitleInput,
      Description: this.state.descriptionInput,
      Voucher: this.state.voucherInput,
      Terms: this.state.termsInput,
      Image: this.refs.imageInput.getWrappedInstance().getValue(),
      TVMode: this.state.isTvMode,
      HasBookingOptions: this.state.hasBookings,
      BookingData: null,
    };

    if (!this.state.startDate) {
      result.StartTimestamp = '';
      result.StartUnixTimestamp = 0;
    } else {
      const startTime = getUTCFromTimeDatePickers(this.state.startDate, '12:00am');
      result.StartTimestamp = startTime.toISOString();
      result.StartUnixTimestamp = startTime.valueOf();
    }

    if (!this.state.startDate) {
      result.ExpiryTimestamp = '';
      result.ExpiryUnixTimestamp = Number.MAX_SAFE_INTEGER;
    } else {
      const expiryTime = getUTCFromTimeDatePickers(this.state.expiryDate, '11:59pm');
      result.ExpiryTimestamp = expiryTime.toISOString();
      result.ExpiryUnixTimestamp = expiryTime.valueOf();
    }

    result.Thumbnail = getThumb300(result.Image);
    result.Image = get1400(result.Image);

    if (this.state.isTvMode && this.refs.logoInput) {
      result.TVModeLogo = this.refs.logoInput.getWrappedInstance().getValue();
    }
    result.Notification = _.includes(this.props.auth.hidden, 'offerNotifications') ? false : this.state.shouldNotify;

    if (result.HasBookingOptions) {
      result.BookingData = { type: this.state.bookingData.type };
      if (result.BookingData.type === 'External') {
        result.BookingData.ButtonText = this.state.buttonText;
        result.BookingData.ButtonLink = this.state.buttonLink;

        if (!this.isValidLink(this.state.buttonLink)) {
          result.BookingData.ButtonLink = 'http://' + this.state.buttonLink;
        }
      }
      if (result.BookingData.type === 'EmailRequest') {
        result.BookingData.RequestEmail = this.state.emailRequestBooking;
      }
    }

    result.GeneralTags = this.state.generalTags;
    this.setAdditionalInfo(result);

    return result;
  }

  handleSubmit() {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({ submitting: true });

    const data = this.compileJson();

    if (this.state.infoId) {
      marketActions
        .editOffer(this.props.auth.site, this.state.infoId, data)
        .then((res) => {
          this.setState({
            success: true,
            submitting: false,
          });
          this.props.offersUpdate(this.props.auth.site);
        })
        .catch((res) => {
          this.setState({ submitting: false });
          alert('Something went wrong with the request. Please try again.');
        });
    } else {
      marketActions
        .addOffer(this.props.auth.site, data)
        .then((res) => {
          this.setState({
            success: true,
            submitting: false,
          });
          this.props.offersUpdate(this.props.auth.site);
        })
        .catch((res) => {
          this.setState({ submitting: false });
          alert('Something went wrong with the request. Please try again.');
        });
    }
  }

  clearForm() {
    this.setState(this.initialState);
  }

  clearSuccess() {
    this.setState({
      success: false,
      submitting: false,
      showWarnings: false,
    });
  }

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return (
      <SuccessPopup
        text={`Offer has been ${this.state.infoId != null ? 'edited' : 'added'}`}
        buttons={[
          {
            type: 'outlined',
            onClick: () => {
              window.history.back();
            },
            text: 'Go to home',
          },
        ]}
      />
    );
  }

  inputsDisabled() {
    if (this.state.submitting) {
      return true;
    }
    return false;
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div>
        <Button
          inline
          buttonType="tertiary"
          onClick={() => {
            window.history.back();
          }}
          isActive
          style={{ marginRight: 16 }}
        >
          {!this.inputsDisabled() ? 'Cancel' : 'Back'}
        </Button>
        {!this.inputsDisabled() && (
          <Button inline buttonType="primary" onClick={() => this.handleSubmit()} isActive={this.validateForm()}>
            Save
          </Button>
        )}
      </div>
    );
  }

  renderSelectedOption(selectedOption) {
    switch (selectedOption) {
      case 'details':
        return this.renderAdditionalInfo();
      case 'bookingOption':
        return this.renderBookingOptions();
      case 'publishOptions':
        return this.renderPublishOptions();
      case 'terms':
        return this.renderTerms();
      case 'tags':
        return this.renderTags();
      case 'addtionalInfo':
        return this.renderMoreInfo();
      default:
        return null;
    }
  }

  renderOptionsSection() {
    const { offerFields } = this.state;

    let options = [
      {
        key: 'details',
        icon: 'info',
        text: 'Details',
      },
      {
        key: 'bookingOption',
        icon: 'pencil-o',
        text: 'Booking Option',
      },
      {
        key: 'publishOptions',
        icon: 'bell',
        text: 'Publish Options',
      },
      {
        key: 'terms',
        icon: 'surveys2',
        text: 'Terms',
      },
      {
        key: 'tags',
        icon: 'tag',
        text: 'Tags',
      },
      {
        key: 'addtionalInfo',
        icon: 'surveys3',
        text: 'Additional Info',
      },
    ];
    if (!offerFields || offerFields.length === 0) {
      options = _.filter(options, (o) => o.key !== 'addtionalInfo');
    }
    if (this.state.infoId || _.includes(this.props.auth.hidden, 'offerNotifications')) {
      options = _.filter(options, (o) => o.key !== 'publishOptions');
    }
    if (_.isEmpty(options)) {
      return null;
    }

    const selectedOption = this.state.selectedOption || options[0].key;

    return (
      <OptionsSection options={options} selected={selectedOption} selectOption={this.selectOption}>
        {this.renderSelectedOption(selectedOption)}
      </OptionsSection>
    );
  }

  onSelectBookingType = (tag) => {
    if (!bookingTypes[tag]) return null;
    const bookingData = { ...this.state.bookingData };
    bookingData.type = tag;
    this.setState({ bookingData });
  };

  getSelectedBookingType = () => {
    return bookingTypes[this.state.bookingData.type] || { Title: 'Select' };
  };

  renderBookingOptions() {
    const inputsDisabled = this.inputsDisabled();
    // TODO: Re-enable email booking once confirmed
    const enabledBookingTypes = _.omit(bookingTypes, 'EmailRequest');

    return (
      <div className="optionsContent_bottom">
        <RadioButton
          label={`Does this offer have booking options?`}
          isActive={this.state.hasBookings}
          options={[
            { Label: 'Yes', Value: true, onChange: () => this.setState({ hasBookings: true }) },
            { Label: 'No', Value: false, onChange: () => this.setState({ hasBookings: false }) },
          ]}
          disabled={inputsDisabled}
        />
        {this.state.hasBookings && (
          <div style={{ marginTop: 8 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: '100%', maxWidth: '50%', marginRight: 24 }}>
                <DropdownInput
                  id="bookingType"
                  label="Booking Type"
                  placeholder="Booking Type"
                  value={this.getSelectedBookingType().Title}
                  options={enabledBookingTypes}
                  onSelect={this.onSelectBookingType}
                  disabled={inputsDisabled}
                />
              </div>
              {this.state.bookingData.type === 'External' && (
                <GenericInput
                  id="buttonText"
                  type="text"
                  label="Button Text"
                  placeholder="Button Text"
                  showError={() => {
                    return this.state.showWarnings && this.isEmpty(this.state.buttonText);
                  }}
                  value={this.state.buttonText}
                  onChange={(e) => this.handleChange(e)}
                  style={{ width: '100%', marginLeft: 24 }}
                  disabled={inputsDisabled}
                  alwaysShowLabel
                />
              )}
              {this.state.bookingData.type === 'EmailRequest' && (
                <GenericInput
                  id="emailRequestBooking"
                  type="text"
                  label="Email address"
                  placeholder="example@domain.com"
                  help="Enter an email address that receives these requests"
                  showError={() => {
                    return this.state.showWarnings && this.isEmpty(this.state.emailRequestBooking);
                  }}
                  value={this.state.emailRequestBooking}
                  onChange={(e) => this.handleChange(e)}
                  style={{ width: '100%', marginLeft: 24 }}
                  disabled={inputsDisabled}
                  alwaysShowLabel
                />
              )}
            </div>
            {this.state.bookingData.type === 'External' && (
              <GenericInput
                id="buttonLink"
                type="text"
                label="Button Link"
                placeholder="External link (https://...)"
                help="Dynamic url parameters are available: {userEmail}, {userTags}, {userName}, {offerTitle}"
                showError={() => {
                  return this.state.showWarnings && this.isEmpty(this.state.buttonLink);
                }}
                value={this.state.buttonLink}
                onChange={(e) => this.handleChange(e)}
                disabled={inputsDisabled}
                alwaysShowLabel
              />
            )}
          </div>
        )}
      </div>
    );
  }

  renderAdditionalInfo() {
    return (
      <div className="optionsContent_bottom">
        {/* voucher */}
        <GenericInput
          id="voucherInput"
          type="text"
          label="Voucher"
          placeholder="XYZ123"
          value={this.state.voucherInput}
          onChange={(e) => this.handleChange(e)}
          alwaysShowLabel
        />
        <GenericInput
          id="startDateText"
          label="Start Date"
          alwaysShowLabel
          value={this.state.startDateText}
          onChange={(e) => this.handleDateTextChange('startDate', e.target.value)}
          onClick={() => this.setState({ startDateShow: !this.state.startDateShow })}
          rightContent={
            !_.isEmpty(this.state.startDateText) && (
              <FontAwesome
                name="remove"
                className="removeoption"
                onClick={() => {
                  this.handleDateClear('startDate');
                }}
              />
            )
          }
        />
        {this.state.startDateShow && (
          <DatePicker selectedDate={this.state.startDate || moment()} selectDate={this.handleDateChange.bind(this, 'startDate')} />
        )}
        <GenericInput
          className="marginTop-16"
          id="expiryDateText"
          label="Expiry Date"
          alwaysShowLabel
          value={this.state.expiryDateText}
          onChange={(e) => this.handleDateTextChange('expiryDate', e.target.value)}
          onClick={() => this.setState({ expiryDateShow: !this.state.expiryDateShow })}
          rightContent={
            !_.isEmpty(this.state.expiryDateText) && (
              <FontAwesome
                name="remove"
                className="removeoption"
                onClick={() => {
                  this.handleDateClear('expiryDate');
                }}
              />
            )
          }
        />
        {this.state.expiryDateShow && (
          <DatePicker selectedDate={this.state.expiryDate || moment()} selectDate={this.handleDateChange.bind(this, 'expiryDate')} />
        )}
      </div>
    );
  }

  renderTerms() {
    return (
      <div className="optionsContent_bottom">
        <GenericInput
          id="termsInput"
          label="Terms and Conditions"
          type="textarea"
          componentClass="textarea"
          placeholder="Enter terms and conditions"
          value={this.state.termsInput}
          onChange={(e) => this.handleChange(e)}
          inputStyle={{
            height: 120,
          }}
          alwaysShowLabel
        />
      </div>
    );
  }

  startAddingGeneralTag = () => {
    this.setState({
      addingGeneralTag: true,
      generalTagInput: '',
    });
  };

  detachGeneralTag = (t) => {
    this.setState({
      generalTags: _.filter(this.state.generalTags, (lt) => {
        return lt !== t;
      }),
    });
  };

  saveGeneralTag = () => {
    if (_.isEmpty(this.state.generalTagInput)) {
      return;
    }
    this.attachGeneralTag(this.state.generalTagInput);
    this.setState({
      availableGeneralTags: _.uniq([...this.state.availableGeneralTags, this.state.generalTagInput]),
      generalTagInput: '',
      addingGeneralTag: false,
    });
  };

  attachGeneralTag = (t) => {
    this.setState({
      generalTags: _.uniq([...this.state.generalTags, t]),
    });
  };

  deleteGeneralTag = (t) => {
    if (!window.confirm('Are you sure you want to delete this tag? This will not remove the tag from other offers.')) {
      return;
    }
    marketActions.deleteGeneralTag(this.props.auth.site, t);

    this.setState({
      availableGeneralTags: _.filter(this.state.availableGeneralTags, (lt) => {
        return lt !== t;
      }),
    });
  };

  renderTags() {
    const { generalTags, addingGeneralTag, isEditingGeneralTags, generalTagInput, availableGeneralTags } = this.state;
    const availableTags = _.filter(availableGeneralTags || [], (t) => {
      return !_.includes(generalTags, t);
    });
    return (
      <div className="optionsContent_bottom">
        <Text type="formLabel" className="marginTop-8">
          Assigned Tags
        </Text>
        <div className="userTagsSite">
          <div className="userTagsSite_tags">
            {_.isEmpty(generalTags) && (
              <Text type="body" className="text-italic">
                No tags yet
              </Text>
            )}
            {generalTags.map((t, i) => {
              return (
                <Tag
                  key={t}
                  className={i > 0 ? 'marginLeft-16' : ''}
                  text={t}
                  rightIcon="remove"
                  rightClick={() => {
                    this.detachGeneralTag(t);
                  }}
                />
              );
            })}
          </div>
        </div>
        <Text type="formLabel" className="marginTop-32">
          Available Tags
        </Text>
        <div className="userTagsAvailableSite">
          <div className="userTagsAvailableSite_tags">
            {!addingGeneralTag && !isEditingGeneralTags && (
              <Tag
                className="tag-outlined tag-minSize"
                centerClass="tag_center-spaceAround"
                text="Add Tag"
                onClick={() => {
                  this.startAddingGeneralTag();
                }}
                leftIcon="plus"
                leftIconStyle={{ paddingTop: 2 }}
              />
            )}
            {addingGeneralTag && !isEditingGeneralTags && (
              <Tag
                className="tag-outlined tag-minSize"
                rightIcon="check-circle"
                rightIconStyle={{ fontSize: 16 }}
                rightClick={this.saveGeneralTag}
              >
                <GenericInput
                  value={generalTagInput}
                  id={`generalTagInput`}
                  onChange={(e) => {
                    this.setState({ generalTagInput: e.target.value });
                  }}
                  autofocus
                  className="tag_input"
                />
              </Tag>
            )}
            {availableTags.map((t) => {
              return (
                <Tag
                  key={t}
                  className="marginLeft-16"
                  text={t}
                  onClick={
                    isEditingGeneralTags
                      ? undefined
                      : () => {
                          this.attachGeneralTag(t);
                        }
                  }
                  rightIcon="trash"
                  rightClick={(e) => {
                    e.stopPropagation();
                    this.deleteGeneralTag(t);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  renderMoreInfoString(field) {
    return (
      <GenericInput
        key={field.key}
        id={field.key}
        type="text"
        label={field.label}
        placeholder={field.placeholder}
        value={field.value}
        onChange={(e) => this.onOfferFieldChange(e)}
        alwaysShowLabel
      />
    );
  }

  getAdditionalInfoState = () => {
    const { additionalInfo, offerFields } = this.state;
    const infoState = _.unionWith(additionalInfo, offerFields || [], (x, y) => x.key === y.key);
    // console.log('getAdditionalInfoState', infoState);
    return infoState;
  };

  onAddAttachement = (fieldKey, url) => {
    if (_.isEmpty(url)) return;

    const additionalInfo = this.getAdditionalInfoState();
    const info = additionalInfo.find((i) => i.key === fieldKey);
    if (!info.value) info.value = [];
    const id = _.isEmpty(info.value) ? 0 : _.maxBy(info.value, 'Id').Id + 1;
    info.value.push({
      Id: id,
      Title: getFileName(url, true),
      Source: url,
      Uploading: false,
      Type: 'pdf',
    });
    // console.log('onAddAttachement', additionalInfo);
    this.setState({ additionalInfo });

    this.pdFileInput && this.pdFileInput.getWrappedInstance().setValue('');
  };

  onRemoveAttachment(fieldKey, index) {
    const additionalInfo = this.getAdditionalInfoState();
    const attachments = additionalInfo.find((i) => i.key === fieldKey).value;
    attachments[index].Removed = true;
    this.setState({ additionalInfo });
  }

  onAttachmentChange(fieldKey, index, column, event) {
    const additionalInfo = this.getAdditionalInfoState();
    const attachments = additionalInfo.find((i) => i.key === fieldKey).value;
    attachments[index][column] = event.target.value;
    this.setState({ additionalInfo });
  }

  setAdditionalInfo(input) {
    const additionalInfo = this.getAdditionalInfoState();
    additionalInfo.forEach((i) => {
      if (i.type === 'pdf' && i.value && Array.isArray(i.value)) {
        i.value = i.value
          .filter((att) => !att.Removed && !_.isEmpty(att.Source))
          .map((att) => {
            return {
              Id: att.Id,
              Title: att.Title,
              Source: att.Source,
            };
          });
      }
    });
    input.AdditionalInfo = additionalInfo;
  }

  renderAddAttachment(fieldKey) {
    if (this.inputsDisabled()) {
      return null;
    }
    return (
      <FileInput
        ref={(ref) => {
          this.pdFileInput = ref;
        }}
        style={{ height: 100, width: 240 }}
        refreshCallback={(url) => this.onAddAttachement(fieldKey, url)}
        accept="application/pdf"
        simpleStyle
      />
    );
  }

  renderMoreInfoPdf(field) {
    const { additionalInfo } = this.state;
    const info = additionalInfo.find((i) => i.key === field.key);
    const attachments = info ? info.value : [];
    const hasAttachments = attachments && attachments.length > 0;

    return (
      <div key={field.key}>
        <div className="fieldLabel">{field.label}</div>
        <FormGroup controlId={`offerAttachment-${field.key}`} style={hasAttachments ? null : { marginTop: 8 }}>
          {hasAttachments &&
            attachments.map((attachment, index) => {
              if (attachment.Removed) return null;

              return (
                <div key={index} className="pdfAttachmentInput">
                  <img
                    src="https://s3-ap-southeast-2.amazonaws.com/pluss60-dev-media/pluss/document.svg"
                    className="pdfAttachmentInput_icon"
                    alt="file"
                  />
                  <Text type="formTitleSmall" className="pdfAttachmentInput_title">
                    {getFileName(attachment.Source)}
                  </Text>
                  <GenericInput
                    id={`offerAttachmentTitle${index}`}
                    label="Title"
                    type="text"
                    placeholder="Title"
                    value={attachment.Title}
                    onChange={(e) => this.onAttachmentChange(field.key, index, 'Title', e)}
                    disabled={this.inputsDisabled()}
                    alwaysShowLabel
                    style={{ margin: 0, flex: 1 }}
                  />
                  <P60Icon
                    className="removeoption pdfAttachmentInput_remove"
                    icon="remove-circle"
                    onClick={() => this.onRemoveAttachment(field.key, index)}
                  />
                </div>
              );
            })}
          {this.renderAddAttachment(field.key)}
        </FormGroup>
      </div>
    );
  }

  renderMoreInfo() {
    // TODO: Need to handle video types differently (not as string)
    const additionalInfo = this.getAdditionalInfoState();
    return (
      <div className="optionsContent_bottom">
        {(additionalInfo || []).map((f) => {
          switch (f.type) {
            case 'string':
            case 'link':
            case 'video':
              return this.renderMoreInfoString(f);
            case 'pdf':
              return this.renderMoreInfoPdf(f);
          }
        })}
      </div>
    );
  }

  renderPublishOptions() {
    return <div className="optionsContent_bottom optionsContent_bottom-noTopPadding">{this.renderNotify()}</div>;
  }

  renderNotify() {
    return (
      <div className="marginTop-16">
        <Text type="formTitleSmall" className="marginBottom-16">
          Notification
        </Text>
        <RadioButton
          label="Do you want to send a notification with this offer?"
          isActive={this.state.shouldNotify}
          options={[
            { Label: 'Yes', Value: true, onChange: () => this.setState({ shouldNotify: true }) },
            { Label: 'No', Value: false, onChange: () => this.setState({ shouldNotify: false }) },
          ]}
          help="This will send an alert to all users who have enabled push notifications."
        />
      </div>
    );
  }

  renderForm() {
    if (this.state.success) {
      return null;
    }
    return (
      <div>
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          <Text type="formTitleLarge" className="marginBottom-24">
            {this.state.infoId == null ? 'New' : 'Edit'} Offer
          </Text>
          <div className="flex flex-reverse">
            <ImageInput
              ref="imageInput"
              label="IMAGE"
              isRequired
              refreshCallback={this.onImageUpdated}
              showError={() => {
                return this.state.showWarnings && !this.validateImage();
              }}
              containerStyle={{ marginLeft: 40 }}
            />
            <div className="flex-1">
              {/* title */}
              <GenericInput
                id="titleInput"
                type="text"
                label="Title"
                placeholder="Enter an offer title"
                value={this.state.titleInput}
                onChange={(e) => this.handleChange(e)}
                isRequired
                isValid={() => {
                  return !this.isEmpty(this.state.titleInput);
                }}
                showError={() => {
                  return this.state.showWarnings && this.isEmpty(this.state.titleInput);
                }}
                alwaysShowLabel
              />
              {/* subtitle */}
              <GenericInput
                id="subtitleInput"
                type="text"
                label="Subtitle"
                placeholder="Enter a subtitle"
                value={this.state.subtitleInput}
                onChange={(e) => this.handleChange(e)}
                alwaysShowLabel
              />
              {/* description */}
              <GenericInput
                id="descriptionInput"
                label="Description"
                type="textarea"
                componentClass="textarea"
                placeholder="Enter a description"
                value={this.state.descriptionInput}
                onChange={(e) => this.handleChange(e)}
                inputStyle={{
                  height: 120,
                }}
                help={
                  <a
                    onClick={() => {
                      this.toggleTextFormat(true);
                    }}
                    className="pointer"
                  >
                    Text formatting
                  </a>
                }
                alwaysShowLabel
              />
            </div>
          </div>
        </div>
        {this.renderOptionsSection()}
      </div>
    );
  }

  render() {
    return (
      <OverlayPage>
        <TextFormatPopup onClose={this.toggleTextFormat.bind(this, false)} isOpen={this.state.textFormatOpen} />
        <OverlayPageContents noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--newPopup">
            {this.renderForm()}
            {this.renderSuccess()}
          </OverlayPageSection>
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderSubmit()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, { offersUpdate, addRecentlyCreated })(AddOffer);
