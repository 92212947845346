import React, { Component } from 'react';
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';
import { connect } from 'react-redux';
import _ from 'lodash';
import { isTheBest, getApiError } from '../../session';
import { automationActions } from '../../webapi';
import { whiteLabelLoaded } from '../../actions';
import { FileInput, GenericInput, Button } from '../../components';
import {
  renderTitle,
  renderDescription,
  renderOpenLinkStep,
  renderTextStep,
  renderImageStep,
  renderEmbed,
  renderCopyToClipboardText,
} from './helper';
import { automationConfig } from '../../config';

const STEP_FCM = 0;
const STEP_FCM_GOOGLE = 0.1;
const STEP_FCM_TOKEN = 0.2;
const STEP_BUNDLE = 1;
const STEP_APP = 2;

class AppSetupAndroid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      message: {},
      whiteLabelInfo: props.activeWhiteLabel,
    };
    this.steps = [
      { label: 'Set up FCM', name: STEP_FCM, render: this.renderStep1 },
      { label: 'Build App Bundle', name: STEP_BUNDLE, render: this.renderStep2 },
      { label: 'Set up Android App', name: STEP_APP, render: this.renderStep3 },
    ];
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps', nextProps.activeWhiteLabel);
    if (!_.isEqual(this.props.activeWhiteLabel, nextProps.activeWhiteLabel)) {
      this.setState({ whiteLabelInfo: nextProps.activeWhiteLabel });
    }
  }

  onMoveStep = (stepIndex) => {
    if (this.props.onMoveStep) this.props.onMoveStep(stepIndex);
  };

  onAddGoogleServicesFile = (file) => {
    return new Promise((resolve, reject) => {
      const { message } = this.state;

      var reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        const { activeWhiteLabel } = this.props;

        message[STEP_FCM_GOOGLE] = 'Processing...';
        this.setState({ loading: true, message }, async () => {
          try {
            const googleServiceContent = e.target.result;
            const { data } = await automationActions.setupAppAndroid(activeWhiteLabel.RowId, googleServiceContent);
            message[STEP_FCM_GOOGLE] = 'Saved successfully';
            this.props.whiteLabelLoaded(data);
            this.setState({ loading: false, message }, resolve());
          } catch (error) {
            message[STEP_FCM_GOOGLE] = getApiError(error).message;
            this.setState({ loading: false, message }, reject(error));
          }
        });
      };
      reader.onerror = (error) => {
        message[STEP_FCM_GOOGLE] = error.message;
        reject(error);
      };
    });
  };

  onChangeText = (event, fieldName) => {
    const { whiteLabelInfo } = this.state;
    whiteLabelInfo[fieldName] = event.target.value;
    this.setState({ whiteLabelInfo });
  };

  onSaveWhiteLabel = (step) => {
    const { whiteLabelInfo, message } = this.state;

    message[step] = 'Saving information...';
    this.setState({ loading: true, message }, async () => {
      try {
        const { data } = await automationActions.updateWhiteLabel(whiteLabelInfo);
        this.props.whiteLabelLoaded(data);
        message[step] = data ? 'Saved successfully' : '';
        this.setState({ loading: false, message });
      } catch (error) {
        message[step] = getApiError(error).message;
        this.setState({ loading: false, message });
      }
    });
  };

  renderStep1 = () => {
    const { message, loading, whiteLabelInfo } = this.state;
    const cloudMessageTokenValid = !_.isEmpty(whiteLabelInfo.CloudMessageToken);

    return (
      <div>
        {renderTitle('Set up Firebase Cloud Messaging')}
        {renderDescription('In this step, we will set up the Firebase Cloud Messaging to enable push notification for the Android app.')}
        <ol>
          {renderOpenLinkStep('https://console.firebase.google.com')}
          {renderImageStep(
            <div>
              Sign in to Google
              <ol className="marginBottom-16">
                <li>
                  Enter <b>Email</b> <b>{renderCopyToClipboardText(automationConfig.devAccountEmail)}</b>
                </li>
                <li>
                  Enter <b>Password</b>
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/1+-+Sign+In.png',
            '50%',
            '20%',
          )}
          {renderImageStep(
            <div>
              Click <b>Add project</b>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/Add+Project.png',
            '50%',
            '40%',
          )}
          {renderImageStep(
            <div>
              Enter the project name <b>{renderCopyToClipboardText(`pluss-${whiteLabelInfo.ClientCode}`)}</b>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/Project+Name.png',
            '50%',
            '40%',
          )}
          {renderImageStep(
            <div>
              Click <b>Select parent resource</b>
              <ul>
                <li>
                  Select <b>joinpluss.com</b> and then click <b>Done</b>
                </li>
                <li>
                  Click <b>Continue</b>
                </li>
              </ul>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/Parent+Resource.png',
            '50%',
            '40%',
          )}
          {renderImageStep(
            <div>
              Uncheck <b>Enable Google Analytis for this project</b> then click <b>Create project</b>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/Disable+Google+Analytics.png',
            '50%',
            '50%',
          )}
          {renderImageStep(
            <div>
              Once new project is ready, click <b>Continue</b>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/Project+Is+Ready.png',
            '50%',
            '30%',
          )}
          {renderImageStep(
            <div>
              Next to the <b>Project Overview</b>, click the cog symbol and click <b>Project settings</b>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/Open+Project+Settings.png',
            '50%',
            '40%',
          )}
          {renderImageStep(
            <div>
              Scroll down to <b>Your apps</b> section and click the <b>Android</b> button
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/Add+Android+App.png',
            '30%',
            '70%',
          )}
          {renderImageStep(
            <div>
              Under <b>Register app</b>,
              <ol className="marginBottom-16">
                <li>
                  Enter <b>Android package name</b> <b>{renderCopyToClipboardText(`com.pluss.${whiteLabelInfo.ClientCode}`)}</b> (OR the
                  actual package name saved during the configuration steps)
                </li>
                <li>
                  Enter <b>App nickname</b> <b>{renderCopyToClipboardText(whiteLabelInfo.ClientName)}</b>
                </li>
              </ol>
              Then click <b>Register app</b> button
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/4+-+Register+App.png',
            '40%',
            '40%',
          )}
          {renderImageStep(
            <div>
              Click <b>Download google-services.json</b> button to download the configuration file
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/5+-+Download+Config.png',
            '40%',
            '40%',
          )}
          {renderTextStep(
            <div>
              Drop and upload <b>google-services.json</b> file downloaded
              <div className="flex flex-row flex-center marginTop-10">
                <FileInput
                  ref={(ref) => {
                    this.jsonFileInput = ref;
                  }}
                  style={{ height: 120, width: 240 }}
                  handleFile={this.onAddGoogleServicesFile}
                  accept="application/json"
                  simpleStyle
                  multiple={false}
                />
                <div className="marginLeft-16">{message[STEP_FCM_GOOGLE]}</div>
              </div>
            </div>,
          )}
          {renderImageStep(
            <div>
              Click <b>Cloud Messaging</b> tab at the top
              <ul>
                <li>
                  Under <b>Project credentials</b>, copy the <b>Token</b> next to the <b>Server key</b>
                </li>
                <li>
                  Paste the <b>Token</b> below
                  <GenericInput
                    className="marginTop-10"
                    id="cloudMessageToken"
                    style={{ width: 300 }}
                    type="text"
                    isValid={() => cloudMessageTokenValid}
                    value={whiteLabelInfo.CloudMessageToken}
                    onChange={(e) => this.onChangeText(e, 'CloudMessageToken')}
                    isRequired
                  />
                  <div className="flex flex-row flex-center">
                    <Button
                      inline
                      buttonType="primary"
                      onClick={() => this.onSaveWhiteLabel(STEP_FCM_TOKEN)}
                      isActive={cloudMessageTokenValid && !loading}
                    >
                      Save
                    </Button>
                    <div className="marginLeft-16">{message[STEP_FCM_TOKEN]}</div>
                  </div>
                </li>
              </ul>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/1+-+Set+up+FCM/6+-+Get+Server+Key.png',
            '50%',
            '50%',
          )}
          {renderTextStep('Close the Incognito window')}
        </ol>
      </div>
    );
  };

  renderStep2 = () => {
    const { whiteLabelInfo } = this.state;

    return (
      <div>
        {renderTitle('Build android App Bundle')}
        {renderDescription(
          'In this step, we will build an App Bundle file to upload. The following steps assume that you have Docker Desktop installed and have the plussdev/default image.',
        )}
        <ol>
          {renderTextStep(
            <div>
              For detailed instructions on how to set up the Docker Desktop for building the app, refer to{' '}
              <a href="https://docs.google.com/document/d/1bxv7Xvy_K4Xk16L0bolRzIsLj6EtXC8O6sHgXt5Bxr4/edit?usp=sharing" target="_blank">
                Building and Uploading Apps
              </a>
            </div>,
          )}
          {renderImageStep(
            <div>
              Ensure you are running a docker container from the <b>plussdev/default</b> image then click <b>Run</b> button without changing
              any settings.
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/2+-+Build+APK/Run+Docker+Image.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Click on the <b>Containers/Apps</b> menu on the left and then click the CLI button to open the command prompt
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/2+-+Build+APK/Open+Command+Prompt.png',
            '40%',
            '60%',
          )}
          {renderTextStep(
            <div>
              Ensure you are in <b>minuss-expo</b> folder. If not, switch to the folder by typing,
              {renderEmbed('cd minuss-expo')}
            </div>,
          )}
          {renderTextStep(
            <div>
              Start building the android app bundle by typing,
              {renderEmbed(`npm run build ${whiteLabelInfo.ClientCode} android`)}
            </div>,
          )}
          {renderImageStep(
            <div>
              When prompted to <b>Choose the build type</b>, choose <b>app-bundle</b>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/2+-+Build+APK/Choose+Build+Type.png',
            '40%',
            '60%',
          )}
          {renderTextStep(
            <div>
              When prompted to create the <b>keystore</b>, allow Expo to handle the process
            </div>,
          )}
          {renderTextStep(<div>Wait for the build to complete</div>)}
          {renderImageStep(
            <div>
              Download App Bundle file
              <ol className="marginBottom-16">
                <li>
                  Right click on the url to the standalone app built (e.g.{' '}
                  <b>https://expo.io/artifacts/94e075f9-852d-48a6-bd17-e8228a9ca618</b>)
                </li>
                <li>Open the url to start downloading the App Bundle file</li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/2+-+Build+APK/Build+Complete.png',
            '40%',
            '60%',
          )}
          {renderTextStep(
            <div>
              Upload cloud messaging server key written down from previous steps to the Expo server by typing,
              {renderEmbed(`expo push:android:upload --api-key ${whiteLabelInfo.CloudMessageToken}`)}
            </div>,
          )}
          {renderTextStep('Close the terminal window')}
        </ol>
      </div>
    );
  };

  renderStep3 = () => {
    const { whiteLabelInfo } = this.state;

    return (
      <div>
        {renderTitle('Set up new app')}
        {renderDescription('In this step, we will set up a new app in Google Play Console')}
        <ol>
          {renderOpenLinkStep('https://play.google.com/apps/publish/?account=8994129238190593164#AppListPlace')}
          {renderImageStep(
            <div>
              Sign in to Google
              <ol className="marginBottom-16">
                <li>
                  Enter <b>Email</b> <b>{renderCopyToClipboardText(automationConfig.devAccountEmail)}</b>
                </li>
                <li>
                  Enter <b>Password</b>
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/1+-+Sign+In.png',
            '50%',
            '20%',
          )}
          {renderImageStep(
            <div>
              Click <b>Create app</b>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/2+-+All+Apps.png',
            '30%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Under <b>App details</b>,
              <ol className="marginBottom-16">
                <li>
                  Enter <b>App name</b> <b>{renderCopyToClipboardText(whiteLabelInfo.ClientName)}</b>
                </li>
                <li>
                  Select <b>Default language</b> - <b>English (Australia) - en-AU</b>
                </li>
                <li>
                  Select <b>App or game</b> - <b>App</b>
                </li>
                <li>
                  Select <b>Free or paid</b> - <b>Free</b>
                </li>
              </ol>
              Under <b>Declarations</b>,
              <ol className="marginBottom-16">
                <li>
                  For <b>Developer Program Policies</b>, check <b>Confirm app meets the Developer Program Policies</b>
                </li>
                <li>
                  For <b>US export laws</b>, check <b>Accept US export laws</b>
                </li>
              </ol>
              Then click <b>Create app</b> button
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/3+-+Create+App.png',
            '40%',
            '50%',
          )}
          {renderImageStep(
            <div>
              Under <b>Grow/Store presence</b> on the left menu, click <b>Main store listing</b>
              <ol className="marginBottom-16">
                <li>
                  Enter <b>App name</b> <b>{renderCopyToClipboardText(whiteLabelInfo.ClientName)}</b>
                </li>
                <li>
                  Enter <b>Short description</b>
                </li>
                <li>
                  Enter <b>Full description</b>
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/4+-+App+Details.png',
            '40%',
            '50%',
          )}
          {renderImageStep(
            <div>
              Under <b>Graphics</b>,
              <ol className="marginBottom-16">
                <li>
                  Upload <b>App Icon</b>
                </li>
                <li>
                  Upload <b>Feature graphic</b>
                </li>
                <li>
                  Upload <b>Phone screenshots</b>
                </li>
                <li>
                  Upload <b>7-inch tablet screenshots</b>
                </li>
                <li>
                  Upload <b>10-inch tablet screenshots</b>
                </li>
              </ol>
              Then click <b>Save</b> button
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/5+-+Graphics.png',
            '50%',
            '40%',
          )}
          {renderImageStep(
            <div>
              Under <b>Grow/Store presence</b> on the left menu, click <b>Store settings</b>
              <ol className="marginBottom-16">
                <li>
                  Select <b>Category</b> - <b>Lifestyle</b>
                </li>
                <li>
                  Enter <b>Email address</b> <b>{renderCopyToClipboardText(automationConfig.helpDeskEmail)}</b>
                </li>
                <li>
                  Enter <b>Website</b> <b>{renderCopyToClipboardText(automationConfig.websiteUrl)}</b>
                </li>
              </ol>
              Then click <b>Save</b> button
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/6+-+Store+Settings.png',
            '40%',
            '50%',
          )}
          {renderImageStep(
            <div>
              Under <b>Policy</b> on the left menu, click <b>App content</b>
              <ol className="marginBottom-16">
                <li>
                  For <b>Privacy policy</b>, click <b>Start</b>
                  <ol>
                    <li>
                      Enter <b>{renderCopyToClipboardText(automationConfig.privacyUrl)}</b>
                    </li>
                    <li>
                      Click <b>Save</b> button
                    </li>
                    <li>
                      Go back to <b>App content</b>
                    </li>
                  </ol>
                </li>
                <li>
                  For <b>Ads</b>, click <b>Start</b>
                  <ol>
                    <li>
                      Select <b>No, my app does not contain ads</b>
                    </li>
                    <li>
                      Click <b>Save</b> button
                    </li>
                    <li>
                      Go back to <b>App content</b>
                    </li>
                  </ol>
                </li>
                <li>
                  For <b>App access</b>, click <b>Start</b>
                  <ol>
                    <li>
                      Select <b>All or some functionality is restricted</b>
                    </li>
                    <li>
                      Click <b>+ Add new instructions</b>
                      <ol>
                        <li>
                          Enter <b>Name</b> (<b>Login Credentials</b>)
                        </li>
                        <li>
                          Enter <b>Username / phone number</b> <b>{renderCopyToClipboardText(automationConfig.googleTester)}</b>
                        </li>
                        <li>
                          Enter <b>Password</b> <b>{renderCopyToClipboardText(`${whiteLabelInfo.ClientCode}Google`)}</b>
                        </li>
                        <li>
                          Enter <b>Any other instructions</b> (
                          <b>
                            Use the app as if you might be a member of a community - explore What's On and invite friends to an event you
                            might be attending, check the News for announcements or stories about your neighbours.
                          </b>
                          )
                        </li>
                        <li>
                          Click <b>Apply</b> button
                        </li>
                      </ol>
                    </li>
                    <li>
                      Click <b>Save</b> button
                    </li>
                    <li>
                      Go back to <b>App content</b>
                    </li>
                  </ol>
                </li>
                <li>
                  For <b>Content ratings</b>, click <b>Start</b>
                  <ol>
                    <li>
                      Click <b>Start questionnaire</b> button
                    </li>
                    <li>
                      For <b>Category</b>,
                      <ol>
                        <li>
                          Enter <b>Email address</b> <b>{renderCopyToClipboardText(automationConfig.devAccountEmail)}</b>
                        </li>
                        <li>
                          Select <b>Category</b> (<b>Utility, Productivity, Communication, or Other</b>)
                        </li>
                        <li>
                          Click <b>Next</b> button
                        </li>
                      </ol>
                      For <b>Utility, Productivity, Communication, or Other</b>,
                      <ol>
                        <li>
                          Select <b>Violence</b> (<b>No</b>)
                        </li>
                        <li>
                          Select <b>Sexuality</b> (<b>No</b>)
                        </li>
                        <li>
                          Select <b>Language</b> (<b>No</b>)
                        </li>
                        <li>
                          Select <b>Controlled Substance</b> (<b>No</b>)
                        </li>
                        <li>
                          Select <b>Promotion Of Age-Restricted Products Or Activities</b> (<b>No</b>)
                        </li>
                        <li>
                          Answer <b>Yes</b> to{' '}
                          <b>
                            Does the app natively allow users to interact or exchange content with other users through voice communication,
                            text, or sharing images or audio?
                          </b>
                        </li>
                        <li>
                          Answer <b>No</b> to <b>Does the app share the user's current physical location with other users?</b>
                        </li>
                        <li>
                          Answer <b>No</b> to <b>Does the app allow users to purchase digital goods?</b>
                        </li>
                        <li>
                          Answer <b>No</b> to{' '}
                          <b>Does the app contain any swastikas, other Nazi symbols or propaganda deemed unconstitutional in Germany?</b>
                        </li>
                        <li>
                          Answer <b>No</b> to <b>Is the app a web browser or search engine?</b>
                        </li>
                        <li>
                          Click <b>Save</b>
                        </li>
                        <li>
                          Click <b>Next</b> button
                        </li>
                      </ol>
                    </li>
                    <li>
                      Click <b>Submit</b> button
                    </li>
                    <li>
                      Go back to <b>App content</b>
                    </li>
                  </ol>
                </li>
                <li>
                  For <b>Target audience and content</b>, click <b>Start</b>
                  <ol>
                    <li>
                      For <b>Target age</b>,
                      <ol>
                        <li>
                          Check <b>18 and over</b>
                        </li>
                        <li>
                          Click <b>Next</b> button
                        </li>
                      </ol>
                    </li>
                    <li>
                      For <b>Store presence</b>,
                      <ol>
                        <li>
                          Answer <b>No</b> to <b>Appeal to children</b>
                        </li>
                        <li>
                          Click <b>Next</b> button
                        </li>
                      </ol>
                    </li>
                    <li>
                      Click <b>Save</b> button
                    </li>
                    <li>
                      Go back to <b>App content</b>
                    </li>
                  </ol>
                </li>
                <li>
                  For <b>News apps</b>, click <b>Start</b>
                  <ol>
                    <li>
                      Select <b>News apps</b> (<b>No</b>)
                    </li>
                    <li>
                      Click <b>Save</b> button
                    </li>
                    <li>
                      Go back to <b>App content</b>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/7+-+App+Content.png',
            '50%',
            '50%',
          )}
          {renderImageStep(
            <div>
              Under <b>Release</b> on the left menu, click <b>Production</b>
              <ol className="marginBottom-16">
                <li>
                  Click <b>Countries / regions</b> tab
                </li>
                <li>
                  Click <b>Add countires / regions</b>
                </li>
                <li>
                  Select <b>Australia</b> from the list
                </li>
                <li>
                  Click <b>Add countries / regions</b> button
                </li>
              </ol>
              Then click <b>Create new release</b> button
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/8+-+Production.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Under <b>Play App Signing</b>,
              <ol className="marginBottom-16">
                <li>
                  Click <b>Continue</b> to enable app signing by Google
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/9+-+Create+Release.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Under <b>App bundles and APKs</b>
              <ol className="marginBottom-16">
                <li>
                  Click <b>Upload</b> and select the App Bundle file downloaded from previous steps
                </li>
                <li>Wait until the upload completes</li>
                <li>You should see the App Bundle file uploaded in the list</li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/10+-+App+Bundles.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Under <b>Release details</b>,
              <ol className="marginBottom-16">
                <li>
                  Enter <b>Release notes</b>
                  {renderEmbed('<en-AU>\nInitial release\n</en-')}
                </li>
                <li>
                  Click <b>Save</b>
                </li>
                <li>
                  Click <b>Review release</b> button
                </li>
              </ol>
              Then click <b>Start rollout to Production</b> button
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/3+-+Set+up+App/11+-+Release+Details.png',
            '40%',
            '60%',
          )}
          {renderTextStep('Close the Incognito window once completed')}
        </ol>
      </div>
    );
  };

  render() {
    if (!isTheBest(this.props.auth, true)) return null;

    const { stepIndex } = this.props;
    const step = stepIndex && stepIndex < this.steps.length ? stepIndex : 0;
    return (
      <div className="flex-1 automation">
        <StepProgressBar
          startingStep={step}
          progressClass="progressBar"
          primaryBtnClass="primaryBtn"
          secondaryBtnClass="secondaryBtn"
          submitBtnName="Done"
          onPrevious={() => this.onMoveStep(step - 1)}
          onNext={() => this.onMoveStep(step + 1)}
          onSubmit={() => (this.props.onDone ? this.props.onDone() : null)}
          steps={this.steps}
        />
        {this.steps[step].render()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, automation } = state;
  return {
    auth,
    activeWhiteLabel: automation.active,
  };
};

export default connect(mapStateToProps, { whiteLabelLoaded })(AppSetupAndroid);
