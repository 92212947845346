import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button } from '../../components';
import { automationActions } from '../../webapi';
import { whiteLabelLoaded } from '../../actions';
import { isTheBest, getApiError } from '../../session';
import {
  renderTitle,
  renderDescription,
  renderOpenLinkStep,
  renderTextStep,
  renderImageStep,
  renderEmbed,
  renderCopyToClipboardText,
} from './helper';
import { automationConfig } from '../../config';

const STEP_BUNDLE_ID = 0;

class AppSetupiOS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      message: {},
    };
    this.steps = [
      { label: 'Set up Bundle ID', name: STEP_BUNDLE_ID, render: this.renderStep1 },
      { label: 'Set up iOS App', name: '2', render: this.renderStep2 },
      { label: 'Build IPA', name: '3', render: this.renderStep3 },
      { label: 'Upload IPA', name: '4', render: this.renderStep4 },
      { label: 'Submit for Review', name: '5', render: this.renderStep5 },
    ];
  }

  canPerformSetup = () => {
    const { activeWhiteLabel } = this.props;
    const { loading } = this.state;
    return activeWhiteLabel.RowId && !loading;
  };

  canSetupBundleId = () => this.canPerformSetup();

  hasBundleId = () => {
    const { activeWhiteLabel } = this.props;
    return this.canPerformSetup() && !_.isEmpty(activeWhiteLabel.BundleIdKey) && !_.isEmpty(activeWhiteLabel.BundleId);
  };

  onMoveStep = (stepIndex) => {
    if (this.props.onMoveStep) this.props.onMoveStep(stepIndex);
  };

  onSetupAppIOS = (step) => {
    if (!this.canPerformSetup()) return;

    const { message } = this.state;
    const { activeWhiteLabel } = this.props;

    // const currentSection = activeWhiteLabel.Section;
    // const step = activeWhiteLabel.IOSStep || 0;
    message[step] = 'Processing...';
    this.setState({ loading: true }, async () => {
      try {
        const { data } = await automationActions.setupAppIOS(activeWhiteLabel.RowId);
        message[step] = '';
        this.props.whiteLabelLoaded(data);
        // const continuing = currentSection === data.Section;
        const continuing = false;
        this.setState({ loading: false, message }, continuing ? () => setTimeout(() => this.onSetupAppIOS(step + 1), 5000) : null);
      } catch (error) {
        message[step] = getApiError(error).message;
        // const waiting = message[step].includes('please wait');
        const waiting = false;
        this.setState({ loading: false, message }, waiting ? () => setTimeout(() => this.onSetupAppIOS(step), 10000) : null);
      }
    });
  };

  renderBundleId = () => {
    const { activeWhiteLabel } = this.props;
    const { message } = this.state;
    const exists = this.hasBundleId();
    const isValid = this.canSetupBundleId();
    return (
      <div className="flex flex-row flex-center marginTop-10">
        {exists ? (
          <div>
            <div>
              {`Bundle Id: ${activeWhiteLabel.BundleId}`}
              <FontAwesome className="marginLeft-10 text-teal" name={'check'} />
            </div>
            <div>
              {`Bundle Id Key: ${activeWhiteLabel.BundleIdKey}`}
              <FontAwesome className="marginLeft-10 text-teal" name={'check'} />
            </div>
          </div>
        ) : (
          <Button style={{ width: 90 }} inline buttonType="primary" onClick={() => this.onSetupAppIOS(STEP_BUNDLE_ID)} isActive={isValid}>
            Setup
          </Button>
        )}
        <div className="marginLeft-16">{message[STEP_BUNDLE_ID]}</div>
      </div>
    );
  };

  // renderStep1 = () => {
  //   return (
  //     <div>
  //       {renderTitle('Set up Bundle ID')}
  //       {renderDescription('In this step, we will set up the bundle ID of the iOS app.')}
  //       <ol>
  //         {renderTextStep(
  //           <div>
  //             Create a new Bundle Id
  //             {this.renderBundleId()}
  //           </div>,
  //         )}
  //       </ol>
  //     </div>
  //   );
  // };

  renderStep1 = () => {
    const { activeWhiteLabel } = this.props;
    return (
      <div>
        {renderTitle('Set up Bundle ID')}
        {renderDescription('In this step, we will set up the bundle ID of the iOS app.')}
        <ol>
          {renderOpenLinkStep('https://developer.apple.com/account/resources/identifiers/add/bundleId')}
          {renderImageStep(
            <div>
              Sign in to Apple Developer
              <ol className="marginBottom-16">
                <li>
                  Enter <b>Apple ID</b> and <b>Password</b>
                </li>
                <li>Resolve two factor authentication</li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Set+up+Bundle+ID/1+-+Sign+In.png',
            '50%',
            '20%',
          )}
          {renderImageStep(
            <div>
              Under <b>Register a new identifier</b>,
              <ol className="marginBottom-16">
                <li>
                  Select <b>App IDs</b>
                </li>
                <li>
                  Click <b>Continue</b> button
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Set+up+Bundle+ID/2+-+Register.png',
            '30%',
            '70%',
          )}
          {renderImageStep(
            <div>
              Under <b>Select a type</b>,
              <ol className="marginBottom-16">
                <li>
                  Select <b>App</b>
                </li>
                <li>
                  Click <b>Continue</b> button
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Set+up+Bundle+ID/3+-+Select+Type.png',
            '30%',
            '70%',
          )}
          {renderImageStep(
            <div>
              Under <b>Register an App ID</b>,
              <ol className="marginBottom-16">
                <li>
                  Enter <b>Description</b> <b>{renderCopyToClipboardText(activeWhiteLabel.ClientName)}</b>
                </li>
                <li>
                  Enter <b>Bundle ID</b> <b>{renderCopyToClipboardText(`com.pluss.${activeWhiteLabel.ClientCode}`)}</b> (OR the actual
                  bundle id saved during the configuration steps)
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Set+up+Bundle+ID/4+-+Bundle+ID.png',
            '30%',
            '70%',
          )}
          {renderImageStep(
            <div>
              Under <b>Capabilities</b>,
              <ol className="marginBottom-16">
                <li>
                  Select <b>Push Notifications</b>
                </li>
                <li>
                  Click <b>Continue</b> button
                </li>
                <li>
                  Click <b>Register</b> button
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Set+up+Bundle+ID/5+-+Capabilities.png',
            '30%',
            '70%',
          )}
        </ol>
      </div>
    );
  };

  renderStep2 = () => {
    const { activeWhiteLabel } = this.props;
    return (
      <div>
        {renderTitle('Set up new iOS App')}
        {renderDescription('In this step, we will set up a new iOS App.')}
        <ol>
          {renderOpenLinkStep('https://appstoreconnect.apple.com/')}
          {renderImageStep(
            <div>
              Under <b>App Store Connect</b>, click <b>My Apps</b>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Set+up+new+iOS+App/1+-+My+Apps.png',
            '40%',
            '40%',
          )}
          {renderImageStep(
            <div>
              Click the <b>+</b> button next to <b>Apps</b>, then click <b>New App</b>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Set+up+new+iOS+App/2+-+New+App.png',
            '40%',
            '40%',
          )}
          {renderImageStep(
            <div>
              Under <b>New App</b>,
              <ol className="marginBottom-16">
                <li>
                  Under <b>Platform</b>, tick <b>iOS</b>
                </li>
                <li>
                  Enter <b>Name</b> <b>{renderCopyToClipboardText(activeWhiteLabel.ClientName)}</b>
                </li>
                <li>
                  Select <b>Primary Language</b> - <b>English (Australia)</b>
                </li>
                <li>
                  Select <b>Bundle ID</b> - <b>{`${activeWhiteLabel.ClientName} - com.pluss.${activeWhiteLabel.ClientCode}`}</b>
                </li>
                <li>
                  Enter <b>SKU</b> (Same as the <b>Bundle ID</b>)
                </li>
                <li>
                  Under <b>User Access</b>, tick <b>Full Access</b>
                </li>
              </ol>
              Then click <b>Create</b> button
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Set+up+new+iOS+App/3+-+Create.png',
            '50%',
            '40%',
          )}
          {renderTextStep('Close the Incognito window')}
        </ol>
      </div>
    );
  };

  renderStep3 = () => {
    const { activeWhiteLabel } = this.props;
    return (
      <div>
        {renderTitle('Build IPA')}
        {renderDescription(
          'In this step, we will build an IPA file to upload. The following steps assume that you have Docker Desktop installed and have the plussdev/default image.',
        )}
        <ol>
          {renderTextStep(
            <div>
              For detailed instructions on how to set up the Docker Desktop for building the app, refer to{' '}
              <a href="https://docs.google.com/document/d/1bxv7Xvy_K4Xk16L0bolRzIsLj6EtXC8O6sHgXt5Bxr4/edit?usp=sharing" target="_blank">
                Building and Uploading Apps
              </a>
            </div>,
          )}
          {renderImageStep(
            <div>
              Ensure you are running a docker container from the <b>plussdev/default</b> image then click <b>Run</b> button without changing
              any settings.
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/2+-+Build+APK/Run+Docker+Image.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Click on the <b>Containers/Apps</b> menu on the left and then click the CLI button to open the command prompt
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+Android/2+-+Build+APK/Open+Command+Prompt.png',
            '40%',
            '60%',
          )}
          {renderTextStep(
            <div>
              Ensure you are in <b>minuss-expo</b> folder. If not, switch to the folder by typing,
              {renderEmbed('cd minuss-expo')}
            </div>,
          )}
          {renderTextStep(
            <div>
              Start building the ipa by typing,
              {renderEmbed(`npm run build ${activeWhiteLabel.ClientCode} ios`)}
            </div>,
          )}
          {renderImageStep(
            <div>
              When prompted to <b>Choose the build type</b>, choose <b>archive</b>
            </div>,
            'https://pluss60-dev-media.s3.ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Build+IPA/Choose+Build+Type.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Log in to Expo
              <ol className="marginBottom-16">
                <li>
                  When asked <b>Do you have access to the Apple account that will be used for submitting this app to the App Store?</b>,
                  enter <b>Y</b>
                </li>
                <li>
                  Enter <b>Apple ID</b>
                </li>
                <li>
                  Enter <b>Password</b> and validate Code for Two-factor Authentication
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Build+IPA/4+-+Log+In.png',
            '40%',
            '50%',
          )}
          {renderImageStep(
            <div>
              Select Team
              <ol className="marginBottom-16">
                <li>
                  Select <b>Team</b> - <b>Pluss Communities PTY LTD</b>
                </li>
                <li>
                  Select <b>Provider</b> - <b>Pluss Communities PTY LTD</b>
                </li>
                <li>
                  Ensure that <b>Bundle identifier: com.pluss.{activeWhiteLabel.ClientCode}</b> is registered
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Build+IPA/5+-+Team.png',
            '40%',
            '50%',
          )}
          {renderImageStep(
            <div>
              Select Certificates and Build
              <ol className="marginBottom-16">
                <li>
                  Select <b>Distribution Certificate</b> (
                  <b>Cert ID: HC6R36YF75, Serial number: 35FA5E74C5037D755830479F4449D4A8, Team ID: MD49LE9D3X</b>)
                </li>
                <li>
                  Select <b>Push Notification Key</b> (<b>Key ID: DU7AC24FB2, Team ID: MD49LE9D3X</b>)
                </li>
                <li>
                  If the key doesn't exist, upload from,
                  {renderEmbed('./certificates/plussAPNs.p8')}
                  Enter key <b>DU7AC24FB2</b>
                </li>
                <li>Wait until the build completes</li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Build+IPA/6+-+Complete.png',
            '40%',
            '50%',
          )}
          {renderTextStep(
            <div>
              Download IPA file
              <ol className="marginBottom-16">
                <li>
                  Right click on the url to the standalone app built (e.g.{' '}
                  <b>https://expo.io/artifacts/94e075f9-852d-48a6-bd17-e8228a9ca618</b>)
                </li>
                <li>Open the url to start downloading the IPA file</li>
              </ol>
            </div>,
          )}
          {renderTextStep('Close the terminal window')}
        </ol>
      </div>
    );
  };

  renderStep4 = () => {
    return (
      <div>
        {renderTitle('Upload IPA to iTunes Connect')}
        {renderDescription('In this step, we will upload the IPA file we have built in the previous step to iTunes Connect.')}
        <ol>
          {renderImageStep(
            <div>
              Ensure you have downloaded <b>Transporter</b> app from <b>Mac App Store</b> by navigating to{' '}
              <a href="https://apps.apple.com/us/app/transporter/id1450874784" target="_blank" rel="noopener noreferrer">
                https://apps.apple.com/us/app/transporter/id1450874784
              </a>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Upload+IPA/1+-+Transporter.png',
            '60%',
            '40%',
          )}
          {renderTextStep(
            <div>
              Sign in to <b>Transporter</b> app
            </div>,
          )}
          {renderImageStep(
            <div>
              Select IPA File
              <ol>
                <li>
                  Ensure <b>Pluss Communities PTY LTD</b> is selected as the provider
                </li>
                <li>
                  Add (click <b>+</b> or drag) the IPA file built in the previous step
                </li>
                <li>
                  Click <b>Deliver</b> button
                </li>
                <li>Wait until upload completes</li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Upload+IPA/2+-+Select+File.png',
            '40%',
            '50%',
          )}
          {renderTextStep(
            <div>
              Close <b>Transporter</b> app
            </div>,
          )}
        </ol>
      </div>
    );
  };

  renderStep5 = () => {
    const { activeWhiteLabel } = this.props;

    return (
      <div>
        {renderTitle('Submit version for review')}
        {renderDescription('In this step, we will submit the app to Apple for review.')}
        <ol>
          {renderOpenLinkStep('https://appstoreconnect.apple.com/apps')}
          {renderImageStep(
            <div>
              Sign in to Apple Developer
              <ol className="marginBottom-16">
                <li>
                  Enter <b>Apple ID</b> and <b>Password</b>
                </li>
                <li>Resolve two factor authentication</li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/1+-+Sign+In.png',
            '50%',
            '20%',
          )}
          {renderTextStep(
            <div>
              Click on the <b>{renderCopyToClipboardText(activeWhiteLabel.ClientName)}</b>
            </div>,
          )}
          {renderImageStep(
            <div>
              Click on <b>App Information</b> from the left menu
              <ol className="marginBottom-16">
                <li>
                  Enter <b>Name</b>
                </li>
                <li>
                  Enter <b>Subtitle</b>
                </li>
                <li>
                  Select <b>Primary Category</b> - <b>Lifestyle</b>
                </li>
                <li>
                  Select <b>Secondary Category</b> - <b>Social Networking</b>
                </li>
                <li>
                  Under <b>Content Rights</b>, click <b>Set Up Content Rights Information</b> and when it asks{' '}
                  <b>Does your app contain, show, or access third-party content?</b>, check{' '}
                  <b>No, it does not contain, show, or access third-party content</b> and click <b>Done</b>
                </li>
              </ol>
              Then click <b>Save</b> button
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/2+-+App+Information.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Click on <b>Pricing and Availability</b> from the left menu
              <ol className="marginBottom-16">
                <li>Select USD 0 (Free) on the Price Schedule</li>
              </ol>
              Then click <b>Save</b> button
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/3+-+Pricing.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Click on <b>App Privacy</b> from the left menu
              <ol className="marginBottom-16">
                <li>
                  Click <b>Edit</b> next to <b>Privacy Policy</b>
                </li>
                <li>
                  Enter <b>Privacy Policy URL</b> <b>{renderCopyToClipboardText(automationConfig.privacyUrl)}</b> and click <b>Save</b>{' '}
                  button
                </li>
                <li>
                  Click <b>Get Started</b> button at the bottom
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/4+-+App+Privacy.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Under <b>Data Collection</b>,
              <ol className="marginBottom-16">
                <li>
                  Check <b>Yes, we collect data from this app</b> and click <b>Next</b>
                </li>
                <li>
                  For <b>Contact Info</b>, check,
                  <ul>
                    <li>
                      <b>Name</b>
                    </li>
                    <li>
                      <b>Email Address</b>
                    </li>
                    <li>
                      <b>Phone Number</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>User Content</b>, check,
                  <ul>
                    <li>
                      <b>Photos or Videos</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Identifier</b>, check,
                  <ul>
                    <li>
                      <b>User ID</b>
                    </li>
                    <li>
                      <b>Device ID</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Usage Data</b>, check,
                  <ul>
                    <li>
                      <b>Other Usage Data</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Diagnostis</b>, check,
                  <ul>
                    <li>
                      <b>Crash Data</b>
                    </li>
                    <li>
                      <b>Performance Data</b>
                    </li>
                    <li>
                      <b>Other Diagnostic Data</b>
                    </li>
                  </ul>
                </li>
                <li>
                  Click <b>Save</b> button
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/5+-+Data+Collection.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              For each data type selected from the previous step,
              <ol className="marginBottom-16">
                <li>
                  For <b>Name</b>
                  <ul>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>Yes, names collected from this app are linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use names for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Email Address</b>
                  <ul>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>Yes, email addresses collected from this app are linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use email addresses for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Phone Number</b>
                  <ul>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>Yes, phone numbers collected from this app are linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use phone numbers for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Photos or Videos</b>
                  <ul>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>No, photos or videos collected from this app are not linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use photos or videos for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>User ID</b>
                  <ul>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>Yes, user IDs collected from this app are linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>Yes, we use user IDs for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Device ID</b>
                  <ul>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>Yes, device IDs collected from this app are linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>Yes, we use device IDs for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Other Usage Data</b>
                  <ul>
                    <li>
                      Check <b>Analytics</b>
                    </li>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>No, other usage data collected from this app is not linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>Yes, we use other usage data for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Crash Data</b>
                  <ul>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>No, crash data data collected from this app is not linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use crash data for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Performance Data</b>
                  <ul>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>No, performance data data collected from this app is not linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use performance data for tracking purposes</b>
                    </li>
                  </ul>
                </li>
                <li>
                  For <b>Other Diagnostic Data</b>
                  <ul>
                    <li>
                      Check <b>App Functionality</b>
                    </li>
                    <li>
                      Select <b>No, other diagnostic data data collected from this app is not linked to the user’s identity</b>
                    </li>
                    <li>
                      Select <b>No, we do not use other diagnostic data for tracking purposes</b>
                    </li>
                  </ul>
                </li>
              </ol>
              Then click <b>Publish</b> button and confirm publication
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/6+-+Data+Types.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Click on <b>Prepare for Submission</b> from the left menu
              <ol className="marginBottom-16">
                <li>
                  Under <b>Version Informaiton</b> section, upload the screenshots as required
                </li>
                <li>
                  Enter <b>Description</b>
                </li>
                <li>
                  Enter <b>Keywords</b>
                </li>
                <li>
                  Enter <b>Support URL</b>
                </li>
                <li>
                  Enter <b>Marketing URL</b>
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/7+-+Version+Information.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Under <b>Build</b> section,
              <ol className="marginBottom-16">
                <li>
                  Select the build uploaded from the previous step (If asked about encryption, answer <b>No</b>)
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/8+-+Build.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Under <b>General App Information</b> section,
              <ol className="marginBottom-16">
                <li>
                  Enter <b>Copyright</b> (<b>2021 Pluss Communities PTY LTD</b>)
                </li>
                <li>
                  Enter <b>Version</b> without the last version component (Revision number)
                </li>
                <li>
                  Click <b>Edit</b> button next to <b>Age Rating</b> and answer all questions with <b>No</b> - This should result in{' '}
                  <b>Ages 4+</b> rating
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/9+-+General+App+Information.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Under <b>App Review Information</b> section,
              <ol className="marginBottom-16">
                <li>
                  Under <b>Sign-in required</b>,
                  <ol>
                    <li>
                      Enter <b>User name</b> <b>{renderCopyToClipboardText(automationConfig.appleTester)}</b>
                    </li>
                    <li>
                      Enter <b>Password</b> <b>{renderCopyToClipboardText(`${activeWhiteLabel.ClientCode}Apple`)}</b>
                    </li>
                  </ol>
                </li>
                <li>
                  Under <b>Contact Information</b>,
                  <ol>
                    <li>
                      Enter <b>First name</b> (<b>Martin</b>)
                    </li>
                    <li>
                      Enter <b>Last name</b> (<b>Smee</b>)
                    </li>
                    <li>
                      Enter <b>Phone number</b> (<b>+61422515677</b>)
                    </li>
                    <li>
                      Enter <b>Email</b> (<b>marty@plusscommunities.com</b>)
                    </li>
                    <li>Attach any notes to help the reviewer navigating the app</li>
                  </ol>
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+iOS/Submit+for+Review/10+-+App+Review+Information.png',
            '40%',
            '60%',
          )}
          {renderTextStep(
            <div>
              Click <b>Submit for Review</b>
            </div>,
          )}
          {renderTextStep('Close the Incognito window')}
        </ol>
      </div>
    );
  };

  render() {
    if (!isTheBest(this.props.auth, true)) return null;

    const { stepIndex } = this.props;
    const step = stepIndex && stepIndex < this.steps.length ? stepIndex : 0;
    return (
      <div className="flex-1 automation">
        <StepProgressBar
          startingStep={step}
          progressClass="progressBar"
          primaryBtnClass="primaryBtn"
          secondaryBtnClass="secondaryBtn"
          submitBtnName="Done"
          onPrevious={() => this.onMoveStep(step - 1)}
          onNext={() => this.onMoveStep(step + 1)}
          onSubmit={() => (this.props.onDone ? this.props.onDone() : null)}
          steps={this.steps}
        />
        {this.steps[step].render()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, automation } = state;
  return {
    auth,
    activeWhiteLabel: automation.active,
  };
};

export default connect(mapStateToProps, { whiteLabelLoaded })(AppSetupiOS);
