import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import moment from 'moment';
import _ from 'lodash';
import { servicesLoaded, removeService } from '../../actions';
import { checkLoggedIn } from '../../session';
import { serviceActions, typeActions } from '../../webapi';
import { getSiteName } from '../../helper';

class ServicesList extends Component {
    state = {
        sortColumn: 'Title', // column to sort by
        sortDesc: false, // if true, sort descending rather than ascending
    }

    UNSAFE_componentWillMount() {
        checkLoggedIn(this, this.props.auth);
    }

    componentDidMount() {
        this.getSites();
    }

    isLocal(service) {
        return this.props.auth.site === service.Site;
    }

    isActive(service) {
        if (this.isLocal(service)) {
            return !service.Inactive;
        }
        return service.SitesOptIn && _.includes(service.SitesOptIn, this.props.auth.site)
    }

    activateService(service) {
        serviceActions.manageStatus(this.props.auth.site, service.RowId, 'activate')
            .then((res) => {
                service.Inactive = res.data.service.Inactive;
                service.SitesOptIn = res.data.service.SitesOptIn;
                this.props.updateServiceList();
            });
    }

    deactivateService(service) {
        if (window.confirm('Deactivating a service will hide it from the app until you activate it again. Are you sure you want to continue?')) {

            serviceActions.manageStatus(this.props.auth.site, service.RowId, 'deactivate')
            .then((res) => {
                service.Inactive = res.data.service.Inactive;
                service.SitesOptIn = res.data.service.SitesOptIn;
                this.props.updateServiceList();
            });
        }
    }

    getSites() {
        typeActions.getSites()
            .then((res) => {
                res.data.forEach(e => {
                    e.Title = e.siteName;
                    e.Key = e.Id;
                });
                this.setState({
                    sites: res.data
                });
            }).catch((error) => {

            });
    }

    sortByCol(col) {
        if (this.state.sortColumn === col) {
            this.setState({
                sortDesc: !this.state.sortDesc
            });
        } else {
            this.setState({
                sortColumn: col,
                sortDesc: false
            });
        }
    }

    removeService(event) {
        if (window.confirm(`Are you sure you want to delete ${event.Title}?`)) {
            this.props.removeService(event.RowId);
            serviceActions.addService(this.props.auth.site, { ...event, Deleted: true })
                .then((res) => {
                    // action
                }).catch((res) => {
                    alert("Something went wrong with the request. Please try again.");
                });
        }
    }

    renderStatus(service) {
        if (this.isActive(service)) {
            return (<div className='tagWrapper tagWrapper--active' onClick={this.deactivateService.bind(this, service)}>
                <p className='tagWrapper__content tagWrapper__text'>Active</p>
                <p className='tagWrapper__hoverContent tagWrapper__text'>Deactivate</p>
            </div>);
        }
        return (<div className='tagWrapper tagWrapper--inactive' onClick={this.activateService.bind(this, service)}>
            <p className='tagWrapper__content tagWrapper__text'>Inactive</p>
            <p className='tagWrapper__hoverContent tagWrapper__text'>Activate</p>
        </div>);
    }

    renderList(source) {
        return (source.map((ev, index) => {
            if (ev != null) {
                return (
                    <tr key={index}>
                        <td className="table-TitleColumn">
                            {(_.isUndefined(ev.Internal) || ev.Internal) &&
                                <Link to={`/services/service/${ev.RowId}`}>
                                    <span>{ev.Title}</span>
                                </Link>
                            }
                            {!_.isUndefined(ev.Internal) && !ev.Internal &&
                                <Link to={`/sponsors/sponsor/${ev.RowId}`}>
                                    <span>{ev.Title}</span>
                                </Link>
                            }
                        </td>
                        <td>{getSiteName(ev.Site, this.state.sites)} Staff</td>
                        <td>{moment.unix(ev.Changed).local().format('D MMM YY')}</td>
                        <td>{this.renderStatus(ev)}</td>
                        <td className='text-brandingColour'>
                            {ev.Sponsored &&
                                'Sponsored'
                            }
                        </td>

                        {this.isLocal(ev) ? (<td className='table-options'>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {(_.isUndefined(ev.Internal) || ev.Internal) &&
                                    <Link to={`/services/analytics/${ev.RowId}`}>
                                        <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 12, cursor: 'pointer' }} name='bar-chart' />
                                    </Link>
                                }
                                {(_.isUndefined(ev.Internal) || ev.Internal) &&
                                    <Link to={`/services/service/${ev.RowId}`}>
                                        <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 12, cursor: 'pointer' }} name='pencil' />
                                    </Link>
                                }
                                {!_.isUndefined(ev.Internal) && !ev.Internal &&
                                    <Link to={`/sponsors/sponsor/${ev.RowId}`}>
                                        <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 12, cursor: 'pointer' }} name='pencil' />
                                    </Link>
                                }
                                <a onClick={() => { this.removeService(ev) }}>
                                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8, cursor: 'pointer' }} name='minus-circle' />
                                </a>
                            </div>
                        </td>) : (<td className='table-options'>
                            
                        </td>)}
                    </tr>
                );
            }
            return null;
        })
        )
    }

    renderSort(col) {
        if (col !== this.state.sortColumn) {
            return null;
        }
        return (
            <FontAwesome style={{ marginLeft: 5 }} name={this.state.sortDesc ? 'chevron-up' : 'chevron-down'} />
        );
    }

    sortIsActive(col) {
        if (col !== this.state.sortColumn) {
            return '';
        }
        return ' table--columnActive';
    }

    renderView(source) {
        return (
            <Table className="plussTable" striped bordered condensed hover style={{ minWidth: '100%' }}>
                <thead>
                    <tr>
                        <th
                            className={`${this.sortIsActive('Title')}`}
                            style={{ cursor: 'pointer' }}
                            onClick={() => { this.sortByCol('Title'); }}
                        >
                            Service title{this.renderSort('Title')}
                        </th>
                        <th
                            className={`${this.sortIsActive('Site')}`}
                            style={{ cursor: 'pointer', width: 150 }}
                            onClick={() => { this.sortByCol('Site'); }}
                        >
                            Added by{this.renderSort('Site')}
                        </th>
                        <th
                            className={`${this.sortIsActive('Changed')}`}
                            style={{ cursor: 'pointer', width: 150 }}
                            onClick={() => { this.sortByCol('Changed'); }}
                        >
                            Last updated{this.renderSort('Changed')}
                        </th>
                        <th
                            className={`${this.sortIsActive('Status')}`}
                            style={{ cursor: 'pointer', minWidth: 100 }}
                            onClick={() => { this.sortByCol('Status'); }}
                        >
                            Status{this.renderSort('Status')}
                        </th>
                        <th style={{ minWidth: 80 }} />
                        <th style={{ width: 50 }} />
                    </tr>
                </thead>
                <tbody>
                    {/* Render Current Visitors here! */}
                    {this.renderList(source)}
                </tbody>
            </Table>
        );
    }

    renderEmpty() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 32 }}>
                <div className='emptyState' />
                <div className='marginTop-32' style={{ maxWidth: 500, textAlign: 'center' }}>
                    <span className='fontRegular fontSize-13'>
                        <span className='fontHeavy text-brandingColour'>Services </span>
                        allow you to create Community Partners that can offer bookable actions or sponsored posts to your users.
                        </span>
                </div>
                <div className='marginTop-8 fontRegular fontSize-13' style={{ maxWidth: 500, textAlign: 'center' }}>
                    {`Examples might be; Food Delivery, Flower Delivery, Financial Advice Sponsored by <Community Partner>`}
                </div>
            </div>
        )
    }

    renderContent() {
        let source = _.sortBy(this.props.source, (event) => {
            if (this.state.sortColumn !== 'Status') {
                return event[this.state.sortColumn];
            }
            return this.isActive(event);
        });

        if (this.state.sortDesc) {
            source.reverse();
        }
        // source = []
        if (_.isEmpty(source)) {
            return this.renderEmpty();
        }
        return this.renderView(source);
    }

    render() {
        return (
            <div style={{ minWidth: '100%' }}>
                {this.renderContent()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { services } = state.services;
    const { auth } = state;
    return {
        services,
        auth,
        site: auth.site
    };
};

export default connect(mapStateToProps, { servicesLoaded, removeService })(ServicesList);