import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { P60Icon } from './';

class SideNavItem extends Component {
  constructor(props) {
    super(props);
    this.state = { url: window.location.pathname };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isUndefined(nextProps.url) && !_.isEmpty(nextProps.url)) {
      this.setState({ url: nextProps.url });
    }
  }

  goTo() {
    if (!_.isUndefined(this.props.onUrlChange)) {
      this.props.onUrlChange();
    }
    this.props.history.push(this.props.Link);
  }

  getClassNames() {
    let result = '';
    if (this.props.className) {
      result += ` ${this.props.className}`;
    }
    if (this.state.url === this.props.Link) {
      result += ' sideNav-item--active';
    }
    return result;
  }

  renderIcon() {
    if (this.props.isFontAwesome) {
      return <FontAwesome className="sideNav-item-icon" name={this.props.Icon} />;
    }
    return <P60Icon className="sideNav-item-icon" icon={this.props.Icon} />;
  }

  render() {
    return (
      <div
        className={`sideNav-item${this.getClassNames()}`}
        style={{ ...styles.sideNavItem, ...this.props.style }}
        onClick={this.goTo.bind(this)}
      >
        {!this.props.noSideDot && (
          <div style={{ ...styles.section, marginRight: 15 }}>
            <div className="sideNav-item-dot" style={styles.sideNavDot} />
          </div>
        )}
        <div style={{ ...styles.section, width: 18, marginRight: 16 }}>{this.renderIcon()}</div>
        <div className="sideNav-item-text" style={styles.section}>
          {this.props.Text}
        </div>
        {!_.isNil(this.props.count) && this.props.count > 0 && (
          <div className="sideNav-item-extra">
            <div className="count">{this.props.count > 99 ? 99 : this.props.count}</div>
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  sideNavItem: {
    width: '100%',
    paddingLeft: 16,
    paddingRight: 24,
    paddingTop: 8,
    paddingBottom: 8,
    display: 'flex',
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 22,
  },
  sideNavDot: {
    width: 5,
    height: 5,
    borderRadius: 5,
  },
};

let exportObj = connect(null, {})(withRouter(SideNavItem));

export { exportObj as SideNavItem };
