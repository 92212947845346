import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import _ from 'lodash';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import {
  Button,
  GenericInput,
  OverlayPage,
  OverlayPageContents,
  OverlayPageSection,
  OverlayPageBottomButtons,
  P60Icon,
  SuccessPopup,
  DropdownInput,
  CheckBox,
  SVGIcon,
  RadioButton,
} from '../../components';
import { formActions } from '../../webapi';
import { checkLoggedIn, validateAccess } from '../../session';
import { safeReadParams } from '../../helper';
import { updateForm } from '../../actions';
import { COLOUR_BRANDING_ACTION } from '../../js';
import { Text } from '../../components/text';

const DEFAULT_FIELD = { type: 'text', label: '', mandatory: true, values: [''] };

class AddForm extends Component {
  constructor(props) {
    super(props);

    this.formTypes = [
      {
        Title: 'Sign In',
        Key: 'signIn',
      },
    ];

    this.formIcons = [
      {
        Title: 'Users',
        Key: 'users',
      },
      {
        Title: 'Staff',
        Key: 'staff-card',
      },
      {
        Title: 'Contractors',
        Key: 'contractor',
      },
    ];

    this.fieldTypes = [
      {
        Title: 'Text Input',
        Key: 'text',
      },
      {
        Title: 'Email Input',
        Key: 'email',
      },
      {
        Title: 'Phone Input',
        Key: 'phone',
      },
      {
        Title: 'Yes/No Question',
        Key: 'yn',
      },
      {
        Title: 'Multiple Choice',
        Key: 'multichoice',
      },
      {
        Title: 'Checkboxes',
        Key: 'checkbox',
      },
      {
        Title: 'Title',
        Key: 'staticTitle',
      },
      {
        Title: 'Paragraph Text',
        Key: 'staticText',
      },
      {
        Title: 'Signature',
        Key: 'signature',
      },
    ];

    this.initialState = {
      rowId: safeReadParams(this.props, 'formId'),
      id: '',
      formName: '',
      formType: 'signIn',
      formIcon: 'users',
      pages: [[{ ...DEFAULT_FIELD }]],
      currentPage: 0,
      showWarnings: false,
      success: false,
      submitting: false,
    };

    this.state = { ...this.initialState };
  }

  componentDidMount() {
    checkLoggedIn(this, this.props.auth);

    setTimeout(() => {
      if (!validateAccess(this.props.auth.site, 'forms', this.props.auth)) {
        this.onBack();
      } else {
        const rowId = safeReadParams(this.props, 'formId');
        if (rowId) this.loadForm(rowId);
      }
    }, 500);
  }

  componentDidUpdate(prevProps) {
    const prevId = safeReadParams(prevProps, 'formId');
    const newId = safeReadParams(this.props, 'formId');

    if (prevId !== newId) {
      if (newId) this.loadForm(newId);
    }
  }

  isNew = () => !this.state.rowId;

  convertFieldsToPages = (fields) => {
    return _.sortBy(_.values(_.groupBy(fields, 'page')), (items) => {
      return items[0].page;
    });
  };

  loadForm(rowId) {
    if (!this.props.forms || this.props.forms.length < 1) return;
    const form = _.find(this.props.forms, (form) => {
      return form != null && form.RowId === rowId;
    });
    if (_.isNil(form)) return;

    const newState = {
      id: form.Id,
      formType: form.Type,
      formName: form.Name,
      formIcon: form.Icon,
      pages: this.convertFieldsToPages(form.Fields),
      currentPage: 0,
      showWarnings: false,
      success: false,
      submitting: false,
    };
    if (form.Options) {
      newState.requireSignature = !!form.Options.RequireSignOutSignature;
    }
    this.setState(newState);
  }

  validateLoading = () => !this.state.submitting;

  validateForm() {
    if (!this.validateLoading()) return false;

    const { formType, formName, formIcon, pages } = this.state;
    // Validate form
    if (_.isEmpty(this.getFormTypeTitle(formType))) return false;
    if (_.isEmpty(formName)) return false;
    if (_.isEmpty(this.getFormIconTitle(formIcon))) return false;
    // Validate fields
    let fieldsValid = [];
    pages.map((page) => {
      fieldsValid.push(
        ...page.map((field) => {
          const { type, label, values } = field;
          return (
            !_.isEmpty(type) &&
            !_.isEmpty(label) &&
            (type !== 'multichoice' || type !== 'checkbox' || (values && values.length > 1 && values.every((value) => !_.isEmpty(value))))
          );
        }),
      );
      return null;
    });
    if (!fieldsValid.every((valid) => valid)) return false;

    return true;
  }

  getFormTypeTitle = (type) => {
    const formType = this.formTypes.find((f) => f.Key === type);
    return formType ? formType.Title : '';
  };

  getFormIconTitle = (type) => {
    const formIcon = this.formIcons.find((f) => f.Key === type);
    return formIcon ? formIcon.Title : '';
  };

  getFieldTypeTitle = (type) => {
    const fieldType = this.fieldTypes.find((f) => f.Key === type);
    return fieldType ? fieldType.Title : '';
  };

  onBack = () => {
    window.history.back();
  };

  prepareForSave = () => {
    const { pages, id, formType, formName, formIcon, requireSignature } = this.state;

    let Fields = [];
    // NOTE: Checkboxes are optional by default
    pages.map((page, pageIndex) => {
      Fields.push(
        ...page.map((field) => {
          return {
            ...field,
            page: pageIndex,
            values: field.type === 'multichoice' || field.type === 'checkbox' ? field.values : undefined,
          };
        }),
      );
      return null;
    });

    const options = {
      RequireSignOutSignature: requireSignature,
    };

    return {
      Id: id,
      Type: formType,
      Name: formName,
      Icon: formIcon,
      Site: this.props.auth.site,
      Fields,
      Options: options,
    };
  };

  onPreviewForm = () => {
    const form = this.prepareForSave();

    window.sessionStorage.setItem('signinpreview', JSON.stringify(form));
    window.open(`${window.location.origin}/signinpreview`, '_blank');
  };

  onSaveForm = () => {
    if (!this.validateForm()) return;

    this.setState({ submitting: true }, async () => {
      const form = this.prepareForSave();

      try {
        const res = await formActions.saveForm(form, this.props.auth.site);
        // console.log('saveForm', res.data);
        this.props.updateForm(res.data.entry);
        this.setState({ success: true, submitting: false });
      } catch (error) {
        console.log('saveForm error', error);
        this.setState({ success: false, submitting: false }, this.onBack);
      }
    });
  };

  onAddPage = (atEnd = true) => {
    const pages = [...this.state.pages];
    const newPage = [{ ...DEFAULT_FIELD }];
    let currentPage = this.state.currentPage;
    if (atEnd || currentPage === pages.length - 1) {
      pages.push(newPage);
    } else {
      currentPage += 1;
      pages.splice(currentPage, 0, newPage);
    }

    this.setState({ pages, currentPage });
  };

  onChangePage = (increment) => {
    const { currentPage, pages } = this.state;
    const newPage = currentPage + increment;
    if (newPage < 0 || newPage > pages.length - 1) return;

    this.setState({ currentPage: newPage });
  };

  onSwitchPage = (currentPage) => {
    if (currentPage > this.state.pages.length - 1) return;
    this.setState({ currentPage });
  };

  onMovePagePrev = (pageIndex) => {
    if (pageIndex === 0) return;
    const pages = [...this.state.pages];
    const item = pages.splice(pageIndex, 1)[0];
    const currentPage = pageIndex - 1;
    pages.splice(currentPage, 0, item);

    this.setState({ pages, currentPage });
  };

  onMovePageNext = (pageIndex) => {
    const pages = [...this.state.pages];
    if (pageIndex > pages.length - 1) return;
    const item = pages.splice(pageIndex, 1)[0];
    const currentPage = pageIndex + 1;
    pages.splice(currentPage, 0, item);

    this.setState({ pages, currentPage });
  };

  onRemovePage(pageIndex, event) {
    if (window.confirm(`Are you sure you want to delete the selected page?`)) {
      const pages = [...this.state.pages];
      if (pages.length < 2) return;

      pages.splice(pageIndex, 1);
      const currentPage = (() => {
        if (pageIndex === pages.length - 1) return pageIndex;
        if (pageIndex > pages.length - 1) return pageIndex - 1;
        return pageIndex + 1;
      })();

      this.setState({ pages, currentPage });
    }
    event.stopPropagation();
  }

  onRemoveField(pageIndex, fieldIndex) {
    const pages = [...this.state.pages];
    if (pages[pageIndex].length < 2) return;

    pages[pageIndex].splice(fieldIndex, 1);
    this.setState({ pages });
  }

  onAddNewField = (pageIndex) => {
    const pages = [...this.state.pages];
    pages[pageIndex].push({ ...DEFAULT_FIELD });
    this.setState({ pages });
  };

  onFormTypeChanged = (formType) => {
    this.setState({ formType });
  };

  onFormIconChanged = (formIcon) => {
    this.setState({ formIcon });
  };

  onFormNameChanged = (event) => {
    this.setState({ formName: event.target.value });
  };

  onFieldTypeChanged = (pageIndex, fieldIndex, key) => {
    const pages = [...this.state.pages];
    pages[pageIndex][fieldIndex].type = key;
    if (pages[pageIndex][fieldIndex].type === 'checkbox') {
      pages[pageIndex][fieldIndex].mandatory = false;
    }

    this.setState({ pages });
  };

  onFieldLabelChanged = (pageIndex, fieldIndex, event) => {
    const pages = [...this.state.pages];
    pages[pageIndex][fieldIndex].label = event.target.value;

    this.setState({ pages });
  };

  onFieldPlaceHolderChanged = (pageIndex, fieldIndex, event) => {
    const pages = [...this.state.pages];
    pages[pageIndex][fieldIndex].placeHolder = event.target.value;

    this.setState({ pages });
  };

  onFieldMandatoryChanged = (pageIndex, fieldIndex) => {
    const pages = [...this.state.pages];
    pages[pageIndex][fieldIndex].mandatory = !pages[pageIndex][fieldIndex].mandatory;

    this.setState({ pages });
  };

  onFieldOptionChanged = (pageIndex, fieldIndex, optionIndex, event) => {
    const pages = [...this.state.pages];
    const prevValue = pages[pageIndex][fieldIndex].values[optionIndex];
    pages[pageIndex][fieldIndex].values[optionIndex] = event.target.value;
    // Change corresponding validation if exists
    const validations = pages[pageIndex][fieldIndex].validation;
    let validation = validations ? validations.find((val) => val.value === prevValue) : null;
    if (validation) validation.value = event.target.value;

    this.setState({ pages });
  };

  onFieldFailChanged = (pageIndex, fieldIndex, option) => {
    const pages = [...this.state.pages];
    if (!pages[pageIndex][fieldIndex].validation) pages[pageIndex][fieldIndex].validation = [];

    const validations = pages[pageIndex][fieldIndex].validation;
    let validation = validations.find((val) => val.value === option);
    if (!validation) {
      const trueValue = option === 'Yes' ? true : false;
      validation = validations.find((val) => val.value === trueValue);
    }
    // console.log('onFieldFailChanged', validations, validation, option);
    if (!validation) {
      pages[pageIndex][fieldIndex].validation.push({
        type: 'invalidValue',
        value: option,
        action: 'stop',
        message: '',
      });
    } else {
      pages[pageIndex][fieldIndex].validation = validations.filter((val) => val.value !== validation.value);
    }

    this.setState({ pages });
  };

  onFieldFailMessageChanged = (pageIndex, fieldIndex, option, event) => {
    const pages = [...this.state.pages];

    const validations = pages[pageIndex][fieldIndex].validation;
    let validation = validations.find((val) => val.value === option);
    if (!validation) {
      const trueValue = option === 'Yes' ? true : false;
      validation = validations.find((val) => val.value === trueValue);
    }
    // console.log('onFieldFailMessageChanged', validations, validation, option);
    if (validation) {
      validation.action = validation.action || 'stop';
      validation.message = event.target.value;
      this.setState({ pages });
    }
  };

  onAddNewOption = (pageIndex, fieldIndex) => {
    const pages = [...this.state.pages];
    pages[pageIndex][fieldIndex].values.push('');
    this.setState({ pages });
  };

  onRemoveOption = (pageIndex, fieldIndex, optionIndex) => {
    const pages = [...this.state.pages];
    pages[pageIndex][fieldIndex].values.splice(optionIndex, 1);
    this.setState({ pages });
  };

  onMoveFieldPrev = (pageIndex, fieldIndex) => {
    if (fieldIndex === 0) return;
    const pages = [...this.state.pages];
    const item = pages[pageIndex].splice(fieldIndex, 1)[0];
    pages[pageIndex].splice(fieldIndex - 1, 0, item);

    this.setState({ pages });
  };

  onMoveFieldNext = (pageIndex, fieldIndex) => {
    const pages = [...this.state.pages];
    if (fieldIndex > pages[pageIndex].length - 1) return;
    const item = pages[pageIndex].splice(fieldIndex, 1)[0];
    pages[pageIndex].splice(fieldIndex + 1, 0, item);

    this.setState({ pages });
  };

  renderSuccess() {
    if (!this.state.success) return null;

    return (
      <SuccessPopup
        text={`Form has been ${this.isNew() ? 'added' : 'edited'}`}
        buttons={[
          {
            type: 'outlined',
            onClick: this.onBack,
            text: 'Go to home',
          },
        ]}
      />
    );
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div>
        <Button inline buttonType="tertiary" onClick={this.onBack} isActive style={{ marginRight: 8 }}>
          Cancel
        </Button>
        <Button inline buttonType="secondary" onClick={this.onPreviewForm} isActive={this.validateForm()} style={{ marginRight: 16 }}>
          Preview
        </Button>
        <Button inline buttonType="primary" onClick={this.onSaveForm} isActive={this.validateForm()}>
          Save
        </Button>
      </div>
    );
  }

  renderFieldText(field, pageIndex, fieldIndex) {
    return (
      <div className="fieldInner">
        <GenericInput
          id={`fieldLabel${fieldIndex}`}
          className={'textInput'}
          placeholder={'Type your question here (required)'}
          value={field.label}
          onChange={(e) => this.onFieldLabelChanged(pageIndex, fieldIndex, e)}
        />
        <GenericInput
          id={`fieldPlaceHolder${fieldIndex}`}
          className={'placeHolderInput'}
          placeholder={'Insert answer placeholder (optional)'}
          value={field.placeHolder}
          onChange={(e) => this.onFieldPlaceHolderChanged(pageIndex, fieldIndex, e)}
        />
        <CheckBox
          id={`fieldOptional${fieldIndex}`}
          label="Set this question as Optional"
          isActive={!field.mandatory}
          onChange={(e) => this.onFieldMandatoryChanged(pageIndex, fieldIndex, e)}
        />
      </div>
    );
  }

  renderFieldYesNo(field, pageIndex, fieldIndex) {
    const validations = this.state.pages[pageIndex][fieldIndex].validation || [];

    return (
      <div className="fieldInner">
        <GenericInput
          id={`fieldLabel${fieldIndex}`}
          className={'textInput'}
          placeholder={'Type your question here (required)'}
          value={field.label}
          onChange={(e) => this.onFieldLabelChanged(pageIndex, fieldIndex, e)}
        />
        <Table className="optionsContainer">
          <thead className="headings">
            <tr>
              <th className="icon" />
              <th className="options">Options</th>
              <th className="fail">Fail?</th>
              <th>Fail Message</th>
            </tr>
          </thead>
          <tbody>
            {['Yes', 'No'].map((value, optionIndex) => {
              const trueValue = value === 'Yes' ? true : false;
              const validation = validations.find((val) => val.value === value || val.value === trueValue);
              return (
                <tr key={optionIndex} className="option">
                  <td>
                    <RadioButton single disabled isActive={false} />
                  </td>
                  <td>
                    <GenericInput id={`fieldOption${optionIndex}`} value={value} disabled />
                  </td>
                  <td>
                    <CheckBox
                      id={`fieldOptionFail${optionIndex}`}
                      isActive={validation}
                      onChange={() => this.onFieldFailChanged(pageIndex, fieldIndex, trueValue)}
                    />
                  </td>
                  <td>
                    {validation ? (
                      <GenericInput
                        id={`fieldOptionFailMessage${optionIndex}`}
                        placeholder={'Insert fail message here'}
                        value={
                          validation &&
                          (validation.message || (_.isNil(validation.message) && field.failedValidation && field.failedValidation.message))
                        }
                        onChange={(e) => this.onFieldFailMessageChanged(pageIndex, fieldIndex, trueValue, e)}
                        type="textarea"
                      />
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }

  renderFieldMultiple(field, pageIndex, fieldIndex) {
    const { pages } = this.state;
    const validations = pages[pageIndex][fieldIndex].validation || [];
    return (
      <div className="fieldInner">
        <GenericInput
          id={`fieldLabel${fieldIndex}`}
          className={'textInput'}
          placeholder={'Type your question here (required)'}
          value={field.label}
          onChange={(e) => this.onFieldLabelChanged(pageIndex, fieldIndex, e)}
        />
        <Table className="optionsContainer">
          <thead className="headings">
            <tr>
              <th className="icon" />
              <th className="options-wide">Options</th>
              <th className="fail">Fail?</th>
              <th>Fail Message</th>
              <th className="remove" />
            </tr>
          </thead>
          <tbody>
            {field.values &&
              field.values.map((value, optionIndex) => {
                const validation = validations.find((val) => val.value === value);
                return (
                  <tr key={optionIndex} className="option">
                    <td>
                      <RadioButton single disabled isActive={false} />
                    </td>
                    <td>
                      <GenericInput
                        id={`fieldOption${optionIndex}`}
                        placeholder={'Enter option'}
                        value={value}
                        onChange={(e) => this.onFieldOptionChanged(pageIndex, fieldIndex, optionIndex, e)}
                      />
                    </td>
                    <td>
                      <CheckBox
                        id={`fieldOptionFail${optionIndex}`}
                        isActive={validation}
                        onChange={(e) => this.onFieldFailChanged(pageIndex, fieldIndex, value)}
                      />
                    </td>
                    <td>
                      {validation ? (
                        <GenericInput
                          id={`fieldOptionFailMessage${optionIndex}`}
                          placeholder={'Enter fail message'}
                          value={validation && validation.message}
                          onChange={(e) => this.onFieldFailMessageChanged(pageIndex, fieldIndex, value, e)}
                          type="textarea"
                        />
                      ) : null}
                    </td>
                    <td>
                      {pages[pageIndex][fieldIndex].values.length > 1 ? (
                        <div onClick={() => this.onRemoveOption(pageIndex, fieldIndex, optionIndex)}>
                          <FontAwesome name="minus-circle" className="cornerCancelButton_icon" />
                        </div>
                      ) : null}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <div className="clearfix addoption optionAdd" onClick={() => this.onAddNewOption(pageIndex, fieldIndex)}>
          <P60Icon className="addoption_plus" icon="add-circle" />
          <div className="fillSpace">
            <p className="addoption_text">Add Another Option</p>
          </div>
        </div>
      </div>
    );
  }

  renderFieldCheckbox(field, pageIndex, fieldIndex) {
    const { pages } = this.state;
    return (
      <div className="fieldInner">
        <GenericInput
          id={`fieldLabel${fieldIndex}`}
          className={'textInput'}
          placeholder={'Type your question here (required)'}
          value={field.label}
          onChange={(e) => this.onFieldLabelChanged(pageIndex, fieldIndex, e)}
        />
        <Table className="optionsContainer">
          <thead className="headings">
            <tr>
              <th className="icon" />
              <th>Options</th>
              <th className="remove" />
            </tr>
          </thead>
          <tbody>
            {field.values &&
              field.values.map((value, optionIndex) => {
                return (
                  <tr key={optionIndex} className="option">
                    <td>
                      <RadioButton single disabled isActive={false} isSquare />
                    </td>
                    <td>
                      <GenericInput
                        id={`fieldOption${optionIndex}`}
                        placeholder={'Enter option'}
                        value={value}
                        onChange={(e) => this.onFieldOptionChanged(pageIndex, fieldIndex, optionIndex, e)}
                      />
                    </td>
                    <td>
                      {pages[pageIndex][fieldIndex].values.length > 1 ? (
                        <div onClick={() => this.onRemoveOption(pageIndex, fieldIndex, optionIndex)}>
                          <FontAwesome name="minus-circle" className="cornerCancelButton_icon" />
                        </div>
                      ) : null}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <div className="clearfix addoption optionAdd marginBottom-10" onClick={() => this.onAddNewOption(pageIndex, fieldIndex)}>
          <P60Icon className="addoption_plus" icon="add-circle" />
          <div className="fillSpace">
            <p className="addoption_text">Add Another Option</p>
          </div>
        </div>
        <CheckBox
          id={`fieldOptional${fieldIndex}`}
          label="Set this question as Optional"
          isActive={!field.mandatory}
          onChange={(e) => this.onFieldMandatoryChanged(pageIndex, fieldIndex, e)}
        />
      </div>
    );
  }

  renderFieldStaticTitle(field, pageIndex, fieldIndex) {
    return (
      <div className="fieldInner">
        <GenericInput
          id={`fieldStatic${fieldIndex}`}
          placeholder="Insert title here (required)"
          value={field.label}
          onChange={(e) => this.onFieldLabelChanged(pageIndex, fieldIndex, e)}
          large
        />
      </div>
    );
  }

  renderFieldStaticText(field, pageIndex, fieldIndex) {
    return (
      <div className="fieldInner">
        <GenericInput
          id={`fieldStatic${fieldIndex}`}
          placeholder="Insert your paragraph text here (required)"
          value={field.label}
          onChange={(e) => this.onFieldLabelChanged(pageIndex, fieldIndex, e)}
          type="textarea"
        />
      </div>
    );
  }

  renderFieldSignature(field, pageIndex, fieldIndex) {
    return (
      <div className="fieldInner">
        <GenericInput
          id={`fieldSignature${fieldIndex}`}
          placeholder="Enter a label here (required)"
          value={field.label}
          onChange={(e) => this.onFieldLabelChanged(pageIndex, fieldIndex, e)}
        />
      </div>
    );
  }

  renderField(field, pageIndex, fieldIndex) {
    const { pages } = this.state;

    const renderFieldContent = () => {
      switch (field.type) {
        case 'text':
        case 'email':
        case 'phone':
          return this.renderFieldText(field, pageIndex, fieldIndex);
        case 'yn':
          return this.renderFieldYesNo(field, pageIndex, fieldIndex);
        case 'multichoice':
          return this.renderFieldMultiple(field, pageIndex, fieldIndex);
        case 'checkbox':
          return this.renderFieldCheckbox(field, pageIndex, fieldIndex);
        case 'staticTitle':
          return this.renderFieldStaticTitle(field, pageIndex, fieldIndex);
        case 'staticText':
          return this.renderFieldStaticText(field, pageIndex, fieldIndex);
        case 'signature':
          return this.renderFieldSignature(field, pageIndex, fieldIndex);
        default:
          return null;
      }
    };

    return (
      <div className="fieldContainer" key={fieldIndex}>
        <div className="fieldNumberContainer">
          <p className="fieldNumber">{fieldIndex + 1}</p>
        </div>
        <div className="field">
          <div className="fieldHeader">
            <div className="group">
              <div className="line" />
              <div className="fieldType">{this.getFieldTypeTitle(field.type)}</div>
            </div>
            <div className="group">
              <DropdownInput
                id={`fieldType${fieldIndex}`}
                placeholder="Type"
                value="Change Field Type"
                options={this.fieldTypes}
                onSelect={(key) => this.onFieldTypeChanged(pageIndex, fieldIndex, key)}
              />
              {pages[pageIndex].length > 1 && (
                <div className="delete" onClick={() => this.onRemoveField(pageIndex, fieldIndex)}>
                  Delete
                </div>
              )}
            </div>
          </div>
          {renderFieldContent()}
        </div>
        {pages[pageIndex].length > 1 && (
          <div className="switchField">
            {fieldIndex > 0 ? (
              <div className="circle" onClick={() => this.onMoveFieldPrev(pageIndex, fieldIndex)}>
                <FontAwesome name="angle-up" className="icon" />
              </div>
            ) : null}
            {fieldIndex < pages[pageIndex].length - 1 ? (
              <div className="circle" onClick={() => this.onMoveFieldNext(pageIndex, fieldIndex)}>
                <FontAwesome name="angle-down" className="icon" />
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }

  renderCurrentPage() {
    const { pages, currentPage } = this.state;

    return (
      <div className="fields">
        {pages[currentPage].map((field, fieldIndex) => {
          return this.renderField(field, currentPage, fieldIndex);
        })}
        <div className="clearfix addoption addField" onClick={() => this.onAddNewField(currentPage)}>
          <P60Icon className="addoption_plus" icon="add-circle" />
          <div className="fillSpace">
            <p className="addoption_text">Add New Field</p>
          </div>
        </div>
      </div>
    );
  }

  renderForm() {
    const { success, formType, showWarnings, formName, formIcon, currentPage, pages } = this.state;
    if (success) return null;

    return (
      <div className="addForm">
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          <Text type="formTitleLarge" className="marginBottom-24">
            {this.isNew() ? 'New' : 'Edit'} Form
          </Text>
          <DropdownInput
            id="formType"
            label="Form Type"
            placeholder="Form type"
            value={this.getFormTypeTitle(formType)}
            options={this.formTypes}
            onSelect={(key) => this.onFormTypeChanged(key)}
            isValid={() => !_.isEmpty(formType)}
            isRequired
            style={styles.formType}
          />
          <GenericInput
            id="formName"
            type="text"
            label="Form name"
            alwaysShowLabel
            placeholder="Enter a name"
            showError={() => showWarnings && _.isEmpty(formName)}
            isValid={() => !_.isEmpty(formName)}
            value={formName}
            onChange={this.onFormNameChanged}
            isRequired
          />
          <div className="formIconSection">
            <DropdownInput
              id="formIcon"
              label="Form Icon"
              placeholder="Form icon"
              value={this.getFormIconTitle(formIcon)}
              options={this.formIcons}
              onSelect={(key) => this.onFormIconChanged(key)}
              isValid={() => !_.isEmpty(formIcon)}
              isRequired
              style={styles.formIconDropDown}
            />
            <SVGIcon colour={COLOUR_BRANDING_ACTION} icon={formIcon} style={styles.formIconPreview} />
          </div>
          <CheckBox
            // className="marginTop-16"
            label="Require signature when signing out"
            isActive={this.state.requireSignature}
            onChange={() => {
              this.setState({ requireSignature: !this.state.requireSignature });
            }}
          />
        </div>
        <div className="padding-60 paddingLeft-20">
          <div className="paddingLeft-40 clearfix">
            <Text type="formTitleSmall" className="marginBottom-16">
              Form Editor
            </Text>
          </div>
          <div className="paddingLeft-40 pageNavSection">
            <div className={`changePage ${currentPage === 0 ? 'disabled' : ''}`} onClick={() => this.onChangePage(-1)}>
              <FontAwesome name="angle-left" className="icon" />
              <p className="text">Prev Page</p>
            </div>
            <div className="newTopBarTitle pages">{`Page ${currentPage + 1} of ${pages.length} `}</div>
            <div className={`changePage ${currentPage === pages.length - 1 ? 'disabled' : ''}`} onClick={() => this.onChangePage(1)}>
              <p className="text">Next Page</p>
              <FontAwesome name="angle-right" className="icon" />
            </div>
          </div>
          {this.renderCurrentPage()}
        </div>
      </div>
    );
  }

  renderPages() {
    const { pages, currentPage } = this.state;

    return (
      <div className="formPages">
        {pages.map((page, pageIndex) => {
          const selected = currentPage === pageIndex;

          return (
            <div key={pageIndex} className={`page ${selected ? 'selected' : ''}`} onClick={() => this.onSwitchPage(pageIndex)}>
              {pages.length > 1 ? (
                <div className="clearfix addoption removePage" onClick={(e) => this.onRemovePage(pageIndex, e)}>
                  <P60Icon className="addoption_plus" icon="remove-circle" />
                </div>
              ) : null}
              <div className="title">{`Page ${pageIndex + 1}`}</div>
              <div className="fields">{`${page.length} Field${page.length > 1 ? 's' : ''}`}</div>
              {selected ? (
                <div className="currentPage">
                  <div className="icon" />
                  <div className="text">Current Page</div>
                </div>
              ) : null}
            </div>
          );
        })}
        <div className="page add" onClick={this.onAddPage}>
          <div className="clearfix addoption">
            <P60Icon className="addoption_plus" icon="add-circle" />
          </div>
          <div className="text">Add New Page</div>
        </div>
      </div>
    );
  }

  renderSideForm() {
    return (
      <OverlayPageSection className="pageSectionWrapper--newPopupSide">
        <div className="padding-60 paddingVertical-40">
          <Text type="formTitleSmall" className="marginBottom-16">
            Page Layout
          </Text>
          {this.renderPages()}
        </div>
      </OverlayPageSection>
    );
  }

  render() {
    return (
      <OverlayPage>
        <OverlayPageContents id="FormContainer" noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--newPopup">
            {this.renderForm()}
            {this.renderSuccess()}
          </OverlayPageSection>
          {this.renderSideForm()}
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderSubmit()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  }
}

const styles = {
  formType: {
    marginTop: '30px',
    width: '30%',
  },
  formIconDropDown: {
    width: '30%',
  },
  formIconPreview: {
    width: '20px',
    marginLeft: '30px',
    marginBottom: '10px',
  },
};

const mapStateToProps = (state) => {
  const { auth, forms } = state;
  return {
    auth,
    forms: forms.list,
  };
};

export default connect(mapStateToProps, { updateForm })(AddForm);
