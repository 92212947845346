import React, { Component } from 'react';
import { Table, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import { validateAccess } from '../../session';
import { eventActions } from '../../webapi';
import { eventsLoaded, removeEvent } from '../../actions';
import { InputGroup, FilterMenu } from '../../components';
import { COLOUR_BRANDING_ACTION, COLOUR_BRANDING_OFF, COLOUR_BRANDING_MAIN, getEventTimes } from '../../js';
import { isPaidItem } from '../../helper';
import EventCalendar from './EventCalendar';
import { Text } from '../../components/text';

class ListEvents extends Component {
  state = {
    events: [],
    upcomingEvents: [],
    pastEvents: [],
    sortColumn: 'StartTime', // column to sort by
    sortDesc: false, // if true, sort descending rather than ascending
    now: moment.utc(),
    onlyFuture: true,
    search: '',
  };

  UNSAFE_componentWillMount() {
    this.updateProps(this.props);
    if (Object.keys(this.props.events).length > 0) {
      var maxElem = _.maxBy(this.props.events, (event) => {
        if (!event) {
          return 0;
        }
        return event.Changed;
      });
      this.loadEvents(maxElem.Changed + 1);
    } else {
      this.loadEvents();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps);
  }

  updateProps(props) {
    const events = [];

    props.events.forEach((ev) => {
      if (ev != null && !ev.Deleted) {
        events.push(ev);
      }
    });

    const upcomingEvents = _.filter(events, (ev) => {
      if (ev.IsEveryday) {
        return true;
      }
      if (ev.RepeatedTimes) {
        if (
          _.some(ev.RepeatedTimes, (rep) => {
            return moment.utc(rep.Time).isSameOrAfter(this.state.now);
          })
        ) {
          return true;
        }
        return false;
      }
      return (
        moment.utc(ev.StartTime).isSameOrAfter(this.state.now) ||
        (ev.EndTime != null && ev.EndTime !== '' && moment.utc(ev.EndTime).isSameOrAfter(this.state.now))
      );
    });

    const pastEvents = _.reject(events, (item) =>
      _.find(upcomingEvents, (t) => {
        return t.Id === item.Id;
      }),
    );

    upcomingEvents.forEach((e) => {
      if (e.RepeatedTimes) {
        e.Upcomings = [];
        e.RepeatedTimes.forEach((rep) => {
          if (moment.utc(rep.Time).isSameOrAfter(this.state.now)) {
            e.Upcomings.push(rep);
          }
        });
        e.Upcomings = _.sortBy(e.Upcomings, (repetition) => {
          return moment(repetition.Time).valueOf();
        });
      }
    });

    this.setState({ events, upcomingEvents, pastEvents });
  }

  handleCheckChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.checked;
    this.setState(stateChange);
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  sortByCol(col) {
    if (this.state.sortColumn === col) {
      this.setState({
        sortDesc: !this.state.sortDesc,
      });
    } else {
      this.setState({
        sortColumn: col,
        sortDesc: false,
      });
    }
  }

  hasAttendance = (event) => {
    return (
      event.RepeatedTimes &&
      event.RepeatedTimes.some((t) => {
        return t.AttendanceCount && t.AttendanceCount > 0;
      })
    );
  };

  removeEvent(event) {
    if (isPaidItem(this.props.paymentEnabled, event.Tickets) && this.hasAttendance(event)) {
      window.alert(`There are tickets already purchased for ${event.Title}. Please refund the tickets first.`);
      return;
    }

    if (window.confirm(`Are you sure you want to delete ${event.Title}?`)) {
      this.props.removeEvent(event.RowId);
      eventActions
        .removeEvent(this.props.auth.site, event.RowId)
        .then((res) => {
          // action
        })
        .catch((res) => {
          alert('Something went wrong with the request. Please try again.');
        });
    }
  }

  loadEvents(time) {
    this.setState({
      loading: true,
    });

    eventActions
      .getEvents(this.props.auth.site, time, true)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
          this.props.eventsLoaded(res.data);
        }
      })
      .catch((res) => {
        this.setState({ loading: false });
        alert('Something went wrong with the request. Please try again.');
      });
  }

  getTime(event) {
    const isAllDay = (event.RepeatedTimes.length > 0 && event.RepeatedTimes[0].AllDay) || event.AllDay;

    if (event.IsEveryday) {
      return 'Facility / Everyday event';
    }
    if (!_.isEmpty(event.Upcomings)) {
      let secondLine = null;
      const result = moment
        .utc(event.Upcomings[0].Time)
        .local()
        .format(isAllDay ? 'D MMM YY' : 'D MMM YY - h:mma');
      if (event.Upcomings.length === 2) {
        secondLine = '+ 1 more time slot';
      } else if (event.Upcomings.length > 2) {
        secondLine = `+ ${event.Upcomings.length - 1} more time slots`;
      }
      if (secondLine) {
        return (
          <span>
            {result}
            <br />
            {secondLine}
          </span>
        );
      }
      return result;
    }
    if (!_.isEmpty(event.RepeatedTimes) && _.isEmpty(event.Upcomings)) {
      return 'No upcoming dates';
    }
    return moment
      .utc(event.StartTime)
      .local()
      .format(isAllDay ? 'D MMM YY' : 'D MMM YY - h:mma');
  }

  renderFull(reps) {
    if (!reps) {
      return null;
    }
    const filledEvents = _.filter(reps, (rep) => {
      return (
        moment.utc(rep.Time).isSameOrAfter(this.state.now) &&
        rep.TicketCount &&
        rep.AttendanceCount &&
        rep.AttendanceCount >= rep.TicketCount
      );
    });
    if (filledEvents.length === 0) {
      return null;
    }
    return (
      <span
        style={{
          backgroundColor: COLOUR_BRANDING_OFF,
          marginLeft: 15,
          paddingTop: 3,
          paddingBottom: 3,
          paddingLeft: 8,
          paddingRight: 8,
          borderRadius: 3,
          color: COLOUR_BRANDING_MAIN,
          fontSize: 11,
          fontWeight: 'bold',
          whiteSpace: 'nowrap',
          height: 'fit-content',
          alignSelf: 'center',
        }}
      >
        {filledEvents.length} full event{filledEvents.length > 1 ? 's' : ''}
      </span>
    );
  }

  renderEvents(source) {
    if (!_.isEmpty(this.state.search)) {
      source = _.filter(source, (ev) => {
        return ev.Title.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1;
      });
    }

    return source.map((ev) => {
      if (ev != null) {
        return (
          <tr key={ev.RowId}>
            <td className="table-TitleColumn">
              <Link to={`/events/event/${ev.RowId}`}>
                <span>
                  {isPaidItem(this.props.paymentEnabled, ev.Tickets) && (
                    <FontAwesome style={{ color: COLOUR_BRANDING_ACTION }} name="usd fa-fw" />
                  )}
                  {ev.Title}
                </span>
              </Link>
              {this.renderFull(ev.RepeatedTimes)}
            </td>
            <td>{this.getTime(ev)}</td>
            <td className="table-options">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {validateAccess(this.props.auth.site, 'events', this.props.auth) && (
                  <Link to={`/events/analytics/${ev.RowId}`}>
                    <FontAwesome
                      style={{
                        fontSize: 20,
                        padding: 5,
                        marginLeft: 12,
                        cursor: 'pointer',
                      }}
                      name="bar-chart"
                    />
                  </Link>
                )}
                {validateAccess(this.props.auth.site, 'events', this.props.auth) && (
                  <Link to={`/events/event/${ev.RowId}`}>
                    <FontAwesome
                      style={{
                        fontSize: 20,
                        padding: 5,
                        marginLeft: 12,
                        cursor: 'pointer',
                      }}
                      name="pencil"
                    />
                  </Link>
                )}
                {validateAccess(this.props.auth.site, 'events', this.props.auth) && (
                  <a
                    onClick={() => {
                      this.removeEvent(ev);
                    }}
                  >
                    <FontAwesome
                      style={{
                        fontSize: 20,
                        padding: 5,
                        marginLeft: 8,
                        cursor: 'pointer',
                      }}
                      name="minus-circle"
                    />
                  </a>
                )}
              </div>
            </td>
          </tr>
        );
      }
      return null;
    });
  }

  renderSort(col) {
    if (col !== this.state.sortColumn) {
      return null;
    }
    return <FontAwesome style={{ marginLeft: 5 }} name={this.state.sortDesc ? 'chevron-up' : 'chevron-down'} />;
  }

  sortIsActive(col) {
    if (col !== this.state.sortColumn) {
      return '';
    }
    return ' table--columnActive';
  }

  renderView(source) {
    return (
      <Table className="plussTable" striped bordered condensed hover style={{ minWidth: '100%' }}>
        <thead>
          <tr>
            <th
              className={`${this.sortIsActive('Title')}`}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.sortByCol('Title');
              }}
            >
              Event{this.renderSort('Title')}
            </th>
            <th
              className={`${this.sortIsActive('StartTime')}`}
              style={{ cursor: 'pointer', width: 200 }}
              onClick={() => {
                this.sortByCol('StartTime');
              }}
            >
              Date{this.renderSort('StartTime')}
            </th>
            <th style={{ width: 70 }} />
          </tr>
        </thead>
        <tbody>{this.renderEvents(source)}</tbody>
      </Table>
    );
  }

  renderEmpty() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 32,
        }}
      >
        <div className="emptyState" />
        <div className="marginTop-32" style={{ maxWidth: 500, textAlign: 'center' }}>
          <Text type="h3">There are no events</Text>
        </div>
      </div>
    );
  }

  renderCalendar() {
    return (
      <EventCalendar
        eventList={getEventTimes(this.state.events)}
        selectDate={this.props.selectDate}
        selectedDate={this.props.selectedDate}
      />
    );
  }

  renderContent() {
    if (this.props.viewType === 'calendar') {
      return this.renderCalendar();
    }
    let source = this.state.events;
    if (this.props.filterOption === 'upcoming') {
      source = this.state.upcomingEvents;
    }
    if (this.props.filterOption === 'past') {
      source = this.state.pastEvents;
    }

    source = _.sortBy(source, (event) => {
      if (this.state.sortColumn !== 'StartTime') {
        return event[this.state.sortColumn];
      }
      if (!_.isEmpty(event.RepeatedTimes)) {
        return event.RepeatedTimes[0].Time;
      }
      return event.StartTime;
    });
    if (this.state.sortDesc) {
      source.reverse();
    }
    // source = []
    if (_.isEmpty(source)) {
      return this.renderEmpty();
    }
    return this.renderView(source);
  }

  renderSearch() {
    if (this.props.viewType === 'calendar') {
      return null;
    }
    return (
      <Row className="show-grid">
        <Col xs={6}>
          <InputGroup
            id={`search`}
            type="text"
            label="Search"
            placeholder="Search by event's title."
            value={this.state.search}
            onChange={(e) => this.handleChange(e)}
            autoComplete={false}
          />
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <div style={{ minWidth: '100%' }}>
        <FilterMenu
          filteredBy={this.state.sortColumn}
          sortDesc={this.state.sortDesc}
          alphaSortKey="Title"
          alphaSort={this.sortByCol.bind(this, 'Title')}
          timeSortKey="StartTime"
          timeSort={this.sortByCol.bind(this, 'StartTime')}
          viewType={this.props.viewType}
          updateView={this.props.updateView.bind(this)}
          showCalendar={this.props.filterOption === 'all'}
        />
        {this.renderSearch()}
        {this.renderContent()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { events } = state.events;
  const { auth } = state;
  return {
    events,
    auth: auth,
    paymentEnabled: auth.user && auth.user.paymentInfo && auth.user.paymentInfo.enabled,
  };
};

export default connect(mapStateToProps, { eventsLoaded, removeEvent })(ListEvents);
