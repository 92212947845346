import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import { Header, AddButton } from '../../components';
import { validateAccess } from '../../session';
import { alertsLoaded } from '../../actions';
import { alertActions, profileActions } from '../../webapi';
import { COLOUR_BRANDING_OFF } from '../../js';
import AlertsList from './AlertsList';

class AlertsHub extends Component {
  state = {
    alerts: [],

    selectedSection: 'active',
    location: '',
    tags: [],

    loadingAll: false,
    loadingSubmissions: false,

    submissionEntries: [],

    allList: [],
    isActive: [],
    now: moment.utc().format('YYYY-MM-DD'),
    onlyFuture: true,
    search: '',
  };

  UNSAFE_componentWillMount() {
    this.updateProps(this.props);
  }

  componentDidMount() {
    this.getData();
    this.getTags();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps);
  }

  getData() {
    this.setState({
      loadingAll: true,
    });
    alertActions
      .getAlerts(this.props.auth.site)
      .then((res) => {
        this.setState({
          loadingAll: false,
        });
        if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
          this.props.alertsLoaded(res.data);
        }
      })
      .catch((res2) => {
        this.setState({ loadingAll: false });
        console.log('fail from getAlerts');
        console.log(res2);
      });
  }

  getTags() {
    profileActions.getUserTagsBySite(this.props.auth.site).then((res) => {
      this.setState({
        tags: res.data,
      });
    });
  }

  updateProps(props) {
    const all = [];
    const isActive = [];

    props.alerts.forEach((rep) => {
      if (_.isUndefined(rep.Deleted) || !rep.Deleted) {
        all.push(rep);
        if (moment.utc(rep.Expiry).isSameOrAfter(this.state.now)) {
          isActive.push(rep);
        }
      }
    });

    this.setState({
      allList: all,
      isActive: isActive,
    });
  }

  addNew() {
    if (validateAccess(this.props.auth.site, 'alerts', this.props.auth)) {
      this.props.history.push(`/alerts/addalert`);
    }
  }

  canAddNew(isClass) {
    return isClass ? '' : true;
  }

  getSideBarSectionColour(id) {
    return this.state.selectedSection === id ? { backgroundColor: '#fff' } : {};
  }

  renderStats(gweg, loading) {
    if (loading) {
      return <FontAwesome style={styles.spinner} name="spinner fa-pulse fa-fw" />;
    }
    return gweg;
  }

  renderLeftBar() {
    if (!validateAccess(this.props.auth.site, 'alerts', this.props.auth, true)) {
      return null;
    }
    return (
      <div className="hub-sideContent">
        {/* Top Add Button */}
        <div className="hub-sideContent-topButton" />
        <div style={{ paddingLeft: 15, width: '100%' }}>
          {/* Title */}
          <div className="fontMedium fontSize-36 text-dark" style={styles.sideBarTitleSection}>
            Alerts
          </div>
          {/* Content */}
          {/* All Articles */}
          <div
            onClick={() => {
              this.setState({ selectedSection: 'all' });
            }}
            className="sideBarSection"
            style={this.getSideBarSectionColour('all')}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats(this.state.allList.length, this.state.loadingAll)}
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">All Alerts</div>
          </div>
          <div
            onClick={() => {
              this.setState({ selectedSection: 'active' });
            }}
            className="sideBarSection"
            style={this.getSideBarSectionColour('active')}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats(this.state.isActive.length, this.state.loadingAll)}
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">Active Alerts</div>
          </div>
        </div>
      </div>
    );
  }

  renderRight() {
    return <AlertsList alerts={this.state.allList} showInActive={this.state.selectedSection === 'all'} tags={this.state.tags} />;
  }

  render() {
    return (
      <div className="hub-wrapperContainer">
        {this.renderLeftBar()}
        <div className="hub-headerContentWrapper">
          <Header>{this.canAddNew() && <AddButton onClick={this.addNew.bind(this)} text="NEW ALERT" />}</Header>
          <div className="hub-contentWrapper">{this.renderRight()}</div>
        </div>
      </div>
    );
  }
}

const styles = {
  sideBarTitleSection: {
    lineHeight: '50px',
    marginTop: 30,
    marginBottom: 30,
    paddingLeft: 24,
  },
  sideBarSection: {
    weight: '100%',
    minWidth: 200,
    padding: 32,
    paddingLeft: 24,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  spinner: {
    fontSize: 32,
    color: COLOUR_BRANDING_OFF,
  },
};

const mapStateToProps = (state) => {
  const { auth, alerts } = state;
  return {
    alerts: alerts.alerts,
    auth,
  };
};

export default connect(mapStateToProps, { alertsLoaded })(AlertsHub);
