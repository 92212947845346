import React, { Component } from 'react';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { Header } from '../../components';
import { automationActions } from '../../webapi';
import { whiteLabelLoaded } from '../../actions';
import { getApiError } from '../../session';
import AWSAccount from './AWSAccount';
import AWSSetup from './AWSSetup';
import Repository from './Repository';
import WebSetup from './WebSetup';
import AppSetupiOS from './AppSetupiOS';
import AppSetupAndroid from './AppSetupAndroid';
import Deployment from './Deployment';
import Extensions from './Extensions';

class AutomationHub extends Component {
  constructor(props) {
    super(props);
    const { activeWhiteLabel } = props;
    this.state = {
      sectionIndex: activeWhiteLabel.Section,
      stepIndex: activeWhiteLabel.Step,
    };
    this.sections = [
      {
        key: 'AWSAccount',
        label: '1 - Create Account',
        render: (stepIndex) => <AWSAccount onDone={this.onDone} onMoveStep={this.onMoveStep} stepIndex={stepIndex} />,
      },
      {
        key: 'AWSSetup',
        label: '2 - Set up AWS',
        render: (stepIndex) => <AWSSetup onDone={this.onDone} onMoveStep={this.onMoveStep} stepIndex={stepIndex} />,
      },
      {
        key: 'Configure',
        label: '3 - Configure Repository',
        render: (stepIndex) => <Repository onDone={this.onDone} />,
      },
      {
        key: 'WebSetup',
        label: '4 - Set up Web',
        render: (stepIndex) => <WebSetup onDone={this.onDone} onMoveStep={this.onMoveStep} stepIndex={stepIndex} />,
      },
      {
        key: 'AppSetupiOS',
        label: '5 - Set up iOS',
        render: (stepIndex) => <AppSetupiOS onDone={this.onDone} onMoveStep={this.onMoveStep} stepIndex={stepIndex} />,
      },
      {
        key: 'AppSetupAndroid',
        label: '6 - Set up Android',
        render: (stepIndex) => <AppSetupAndroid onDone={this.onDone} onMoveStep={this.onMoveStep} stepIndex={stepIndex} />,
      },
      {
        key: 'Deploy',
        label: '7 - Deploy',
        render: (stepIndex) => <Deployment onDone={this.onDone} onMoveStep={this.onMoveStep} stepIndex={stepIndex} />,
      },
      {
        key: 'Extend',
        label: '8 - Extend',
        render: (stepIndex) => <Extensions onDone={this.onDone} onMoveStep={this.onMoveStep} stepIndex={stepIndex} />,
      },
    ];
  }

  onSaveWhiteLabel = async ({ sectionIndex, stepIndex } = { sectionIndex: null, stepIndex: null }) => {
    const { activeWhiteLabel } = this.props;
    if (!activeWhiteLabel || !activeWhiteLabel.RowId) return;

    if (sectionIndex !== null) activeWhiteLabel.Section = sectionIndex;
    if (stepIndex !== null) activeWhiteLabel.Step = stepIndex;
    try {
      const { data } = await automationActions.updateWhiteLabel(activeWhiteLabel);
      this.props.whiteLabelLoaded(data);
    } catch (error) {
      console.log('onSaveWhiteLabel error', getApiError(error).message);
    }
  };

  onSelectSection = (newIndex) => {
    const { sectionIndex, stepIndex } = this.state;
    const newStepIndex = sectionIndex !== newIndex ? 0 : stepIndex;
    this.setState({ sectionIndex: newIndex, stepIndex: newStepIndex }, () =>
      this.onSaveWhiteLabel({ sectionIndex: this.state.sectionIndex, stepIndex: this.state.stepIndex }),
    );
  };

  onDone = () => {
    const { sectionIndex } = this.state;
    if (sectionIndex < this.sections.length - 1) this.onSelectSection(sectionIndex + 1);
  };

  onMoveStep = (stepIndex) => {
    this.setState({ stepIndex }, () => this.onSaveWhiteLabel({ stepIndex: this.state.stepIndex }));
  };

  getSideBarSectionColour(id) {
    return this.state.sectionIndex === id ? 'fontHeavy' : 'fontLight ';
  }

  renderStep = (section, index) => {
    return (
      <div
        key={section.key}
        onClick={() => this.onSelectSection(index)}
        className={`${this.getSideBarSectionColour(index)} fontSize-16 pointer text-dark`}
        style={{ height: '20px', margin: 24 }}
      >
        {section.label}
      </div>
    );
  };

  renderLeftBar() {
    return (
      <div className="hub-sideContent" style={{ minWidth: 250 }}>
        {/* Top Add Button */}
        <div className={`hub-sideContent-topButton hub-sideContent-topButton--hide`}>
          <span className="hub-sideContent-topButton-text"> </span>
          <FontAwesome className="hub-sideContent-topButton-icon" name={'plus'} />
        </div>
        <div style={{ paddingLeft: 15, width: '100%' }}>
          {/* Title */}
          <div className="fontMedium fontSize-36 text-dark" style={styles.sideBarTitleSection}>
            Automation
          </div>
          {this.sections.map(this.renderStep)}
        </div>
      </div>
    );
  }

  renderRight() {
    const { sectionIndex, stepIndex } = this.state;
    if (sectionIndex < this.sections.length) return this.sections[sectionIndex].render(stepIndex);
    return null;
  }

  render() {
    return (
      <div className="hub-wrapperContainer">
        <style>{`#tidio-chat { display: none}`}</style>
        {this.renderLeftBar()}
        <div className="hub-headerContentWrapper">
          <Header />
          <div className="hub-contentWrapper">{this.renderRight()}</div>
        </div>
      </div>
    );
  }
}

const styles = {
  sideBarTitleSection: {
    lineHeight: '50px',
    marginTop: 30,
    marginBottom: 30,
    paddingLeft: 24,
    paddingRight: 32,
    whiteSpace: 'nowrap',
  },
};

const mapStateToProps = (state) => {
  const { auth, automation } = state;
  console.log('automation.active', automation.active);
  return {
    auth,
    activeWhiteLabel: automation.active,
  };
};

export default connect(mapStateToProps, { whiteLabelLoaded })(AutomationHub);
