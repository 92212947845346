import { getUrl } from './helper';
import { authedFunction } from '../session';

export const feedbackActions = {
  getFeedbackEmail: site => {
    let url = getUrl('feedback', 'getfbemail');
    return authedFunction({
      method: 'POST',
      url,
      data: {
        site,
      },
    });
  },
  setFeedbackEmail: (Site, Value) => {
    let url = getUrl('feedback', 'setfbemail');
    return authedFunction({
      method: 'POST',
      url,
      data: {
        Site,
        Value,
      },
    });
  },
  getFeedback: site => {
    let url = getUrl('feedback', 'getfeedback');
    return authedFunction({
      method: 'POST',
      url,
      data: {
        site,
      },
    });
  },
  deleteFeedback: (site, Id) => {
    let url = getUrl('feedback', 'deletefeedback');
    return authedFunction({
      method: 'POST',
      url,
      data: {
        site,
        Id,
      },
    });
  },
  addOrEditPoll: data => {
    let url = getUrl('feedback', 'polls/add');
    if (data.Id != null) {
      url = getUrl('feedback', 'polls/edit');
    }
    const request = {
      method: 'POST',
      url,
      data,
    };
    return authedFunction(request);
  },
  deletePoll: (site, id) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('feedback', 'polls/remove'),
      data: {
        site,
        id,
      },
    });
  },
  getPolls: site => {
    return authedFunction({
      method: 'GET',
      url: getUrl('feedback', 'polls/get', { site }),
    });
  },
  getPollResults: id => {
    return authedFunction({
      method: 'GET',
      url: getUrl('feedback', 'polls/results/' + id),
    });
  },
  getPollAudience: id => {
    return authedFunction({
      method: 'GET',
      url: getUrl('feedback', 'polls/audience/' + id),
    });
  },
};
