import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import _ from 'lodash';
import { connect } from 'react-redux';
import { typeActions } from '../../../webapi';
import { Button, CheckBox, RadioButton, GenericInput, SVGIcon } from '../../../components';
import { requiresUserTermsAndConditions, CONST_STRINGS } from '../../../config';
import { hexToRGBAstring } from '../../../js';
import { PageTitle } from '../../../components/text';
import { extensionPermissions, extensionHiddenSections } from '../../../config/features';

class PermissionType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      deleting: false,
      newType: {},
      permissions: [
        ...extensionPermissions,
        {
          displayName: 'Permission and User Type Management',
          key: 'permissionManagement',
        },
        {
          displayName: 'User Management',
          key: 'userManagement',
        },
        {
          displayName: 'Disable User',
          key: 'disableUser',
        },
        {
          displayName: 'Event Creation and Management',
          key: 'events',
        },
        {
          displayName: 'Event Submission (Approval Required)',
          key: 'eventSubmit',
        },
        {
          displayName: 'Event Attendance',
          key: 'eventAttendance',
        },
        {
          displayName: 'Event Payment',
          key: 'eventPayment',
        },
        {
          displayName: CONST_STRINGS.FACILITIES_MANAGEMENT,
          key: 'facilities',
        },
        {
          displayName: 'Services & Sponsors Management',
          key: 'services',
        },
        {
          displayName: 'Contacts',
          key: 'importantContacts',
        },
        {
          displayName: 'Sign Ins (Visitors and Contractors)',
          key: 'visitors',
        },
        {
          displayName: 'Keys & Cards',
          key: 'keysCards',
        },
        {
          displayName: 'Maps',
          key: 'maps',
        },
        {
          displayName: 'Feedback',
          key: 'feedback',
        },
        {
          displayName: 'Terms and Conditions (User App Use)',
          key: 'terms',
        },
        {
          displayName: 'Send Alert Notifications to Users',
          key: 'alerts',
        },
        {
          displayName: CONST_STRINGS.PERMISSION_FAQS,
          key: 'infoPages',
        },
        {
          displayName: 'Survey Creation and View Results',
          key: 'polls',
        },
        {
          displayName: 'Offers',
          key: 'offers',
        },
        // {
        //   displayName: 'Welcome Information',
        //   key: 'welcomePages',
        // },
        {
          displayName: 'Share Invite Codes to Sign Up to the App',
          key: 'inviteCode',
        },
        {
          displayName: 'Manage Image Folders',
          key: 'manageImageLibrary',
        },
        {
          displayName: 'Add to Image Folders',
          key: 'addToImageLibrary',
        },
        {
          displayName: 'Add to App Gallery',
          key: 'addToPublishingImageLibrary',
        },
        {
          displayName: 'Feature Picker',
          key: 'featurePicker',
        },
        {
          displayName: 'Activate Devices',
          key: 'activateDevices',
        },
        {
          displayName: 'Restaurant Manager',
          key: 'food',
        },
        {
          displayName: 'Manage Forms',
          key: 'forms',
        },
        {
          displayName: 'Manage Payments',
          key: 'managePayments',
        },
        {
          displayName: 'Refund Payments',
          key: 'refundPayments',
        },
        {
          displayName: 'View Payments',
          key: 'viewPayments',
        },
        {
          displayName: 'User Tag Management',
          key: 'userTagManagement',
        },
        {
          displayName: 'User Tag Assignment',
          key: 'userTagAssignment',
        },
      ],
      sectionsToHide: [
        ...extensionHiddenSections,
        {
          displayName: 'People Page',
          key: 'people',
        },
        {
          displayName: 'My Events Page',
          key: 'myEvents',
        },
        {
          displayName: 'Maps',
          key: 'maps',
        },
        {
          displayName: 'Contacts',
          key: 'importantContacts',
        },
        {
          displayName: 'Registering for Events',
          key: 'eventRegistration',
        },
        {
          displayName: 'Hub',
          key: 'marketplace',
        },
        {
          displayName: 'Call other users from People tab',
          key: 'phonePeople',
        },
        {
          displayName: 'Post comment',
          key: 'addComment',
        },
        {
          displayName: 'View comments',
          key: 'viewComment',
        },
      ],
      settings: [
        { key: 'profileBirthday', text: 'Allow adding a birthday from their profile' },
        { key: 'profileECs', text: 'Allow adding Emergency Contacts from their profile' },
      ],
      hqTypes: [...extensionPermissions.filter((p) => p.hq).map((p) => p.key), 'userManagement', 'services'],
    };
    this.colors = ['#ee6060', '#a761a7', '#5674a8', '#ffc323', '#82d6e5'];
  }

  UNSAFE_componentWillMount() {
    const typeCopy = _.cloneDeep(this.props.userType);
    if (_.isUndefined(typeCopy.permissions)) {
      typeCopy.permissions = [];
    }
    if (_.isUndefined(typeCopy.hidden)) {
      typeCopy.hidden = [];
    }
    if (_.isUndefined(typeCopy.settings)) {
      typeCopy.settings = {};
    }
    this.setState({ oldType: _.cloneDeep(typeCopy), newType: _.cloneDeep(typeCopy) });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const typeCopy = _.cloneDeep(nextProps.userType);
    if (_.isUndefined(typeCopy.permissions)) {
      typeCopy.permissions = [];
    }
    if (_.isUndefined(typeCopy.hidden)) {
      typeCopy.hidden = [];
    }
    if (_.isUndefined(typeCopy.settings)) {
      typeCopy.settings = {};
    }
    this.setState({ oldType: _.cloneDeep(typeCopy) });
  }

  getUserTypeOptions() {
    return [
      {
        Label: 'Primary user',
        Value: 'resident',
        onChange: () => this.setCategory('resident'),
      },
      {
        Label: 'Staff',
        Value: 'staff',
        onChange: () => this.setCategory('staff'),
      },
      {
        Label: 'Linked user',
        Value: 'family',
        onChange: () => this.setCategory('family'),
      },
    ];
  }

  handleNameChange(value) {
    this.setState({
      newType: {
        ...this.state.newType,
        displayName: value,
      },
    });
  }

  isSubmitting = () => {
    const { saving, deleting } = this.state;
    return saving || deleting;
  };

  handleCheckChange(permissionValue) {
    const { newType } = this.state;
    if (this.isSubmitting()) return;

    if (
      _.findIndex(newType.permissions, function (o) {
        return o === permissionValue;
      }) > -1
    ) {
      // permission found --> remove it
      let newArray = newType.permissions;
      _.pull(newArray, permissionValue);
      this.setState({
        newType: {
          ...newType,
          permissions: newArray,
        },
      });
    } else {
      //permission not found --> add it
      let newArray = newType.permissions;
      if (!Array.isArray(newArray)) {
        newArray = [];
      }
      newArray.push(permissionValue);
      this.setState({
        newType: {
          ...newType,
          permissions: newArray,
        },
      });
    }
  }

  handleHiddenChange(permissionValue) {
    const { newType } = this.state;
    if (this.isSubmitting()) return;

    if (
      _.findIndex(newType.hidden, function (o) {
        return o === permissionValue;
      }) > -1
    ) {
      // permission found --> remove it
      let newArray = newType.hidden;
      _.pull(newArray, permissionValue);
      this.setState({
        newType: {
          ...newType,
          hidden: newArray,
        },
      });
    } else {
      //permission not found --> add it
      let newArray = newType.hidden;
      if (!Array.isArray(newArray)) {
        newArray = [];
      }
      newArray.push(permissionValue);
      this.setState({
        newType: {
          ...newType,
          hidden: newArray,
        },
      });
    }
  }

  handleSettingsChange(permissionValue) {
    const { newType } = this.state;
    if (this.isSubmitting()) return;

    const newSettings = _.cloneDeep(newType.settings);

    newSettings[permissionValue] = !newSettings[permissionValue];
    this.setState({
      newType: {
        ...newType,
        settings: newSettings,
      },
    });
  }

  handleSubmit() {
    const { newType } = this.state;
    if (this.isSubmitting()) return;
    if (!this.isChanged()) return;

    this.setState({ saving: true });
    typeActions
      .setTypePermissions(newType, this.props.auth.user.uid)
      .then((res) => {
        this.props.updateUserType();
        setTimeout(() => {
          this.setState(
            {
              saving: false,
            },
            this.props.selectView(),
          );
        }, 1000);
      })
      .catch((error) => {});
  }

  confirmDeleteType() {
    if (this.isSubmitting()) return;

    if (!window.confirm('Are you sure you want to delete that user type?')) return;
    return this.handleDelete();
  }

  handleDelete() {
    if (this.isSubmitting()) return;

    this.setState({ deleting: true });
    typeActions
      .deleteUserType(this.props.auth.site, this.state.newType.typeName)
      .then((res) => {
        if (res.data.success) {
          this.props.updateUserType();
        } else if (res.data.existingUsers) {
          this.props.showExistingUsersOnDelete();
          this.setState({
            deleting: false,
          });
        }
      })
      .catch((error) => {});
  }

  isChanged() {
    const { oldType, newType } = this.state;
    if (this.isSubmitting()) return false;

    if (this.props.selectedView.isNew) return true;
    if (oldType.displayName !== newType.displayName) return true;
    if (oldType.category !== newType.category) return true;
    if (!_.isEqual(oldType.hidden.sort(), newType.hidden.sort())) return true;
    if (
      _.some(Object.keys(newType.settings), (key) => {
        return !oldType.settings || oldType.settings[key] !== newType.settings[key];
      })
    )
      return true;

    return !_.isEqual(oldType.permissions.sort(), newType.permissions.sort());
  }

  onCloseEdit = () => {
    if (this.isChanged() && !window.confirm('You have unsaved changes. Are you sure you want to continue?')) return;
    this.props.selectView();
  };

  setCategory(val) {
    this.setState({
      newType: {
        ...this.state.newType,
        category: val,
      },
    });
  }

  getIconColor = () => {
    const colorIndex = (this.props.index + 1) % this.colors.length;
    return this.colors[colorIndex === 0 ? this.colors.length - 1 : colorIndex - 1];
  };

  isMyType = () => {
    if (!this.props.auth.user || !this.props.auth.user.Roles) {
      return false;
    }
    return _.some(this.props.auth.user.Roles, (r) => {
      return r.site === this.state.newType.site && r.type === this.state.newType.typeName;
    });
  };

  renderPermissions() {
    const isMyType = this.isMyType();
    const renderList = () => {
      let source = this.state.permissions;
      return source.map((ev) => {
        if (ev.key === 'terms' && !requiresUserTermsAndConditions) return null;
        if (this.props.auth.site === 'hq' && !_.includes(this.state.hqTypes, ev.key)) return null;

        const isDisabled = ev.key === 'permissionManagement' && isMyType;
        return (
          <tr key={`${this.state.newType.Id}_${ev.key}`}>
            <td style={{ paddingRight: 20 }}>
              <CheckBox
                id={`${ev.key}`}
                isActive={
                  _.findIndex(this.state.newType.permissions, function (o) {
                    return o === ev.key;
                  }) > -1
                }
                onChange={() => this.handleCheckChange(ev.key)}
                style={{ justifyContent: 'center', marginBottom: 0 }}
                disabled={isDisabled}
              />
            </td>
            <td className="text">
              {ev.displayName}
              {isDisabled ? <span className="text-italic"> (unable to remove this permission from yourself)</span> : ''}
            </td>
          </tr>
        );
      });
    };

    return (
      <div>
        <p className="subHeadingText">PERMISSIONS</p>
        <Table className="plussTable" striped bordered condensed hover>
          <thead>
            <tr>
              <th style={{ width: 100, paddingRight: 20, textAlign: 'center' }}>Allowed</th>
              <th>Permission</th>
            </tr>
          </thead>
          <tbody>{renderList()}</tbody>
        </Table>
      </div>
    );
  }

  renderHiddenSections() {
    const renderList = () => {
      return this.state.sectionsToHide.map((ev) => {
        return (
          <tr key={`${this.state.newType.Id}_hidden_${ev.key}`}>
            <td style={{ paddingRight: 20 }}>
              <CheckBox
                id={`${ev.key}`}
                isActive={
                  _.findIndex(this.state.newType.hidden, function (o) {
                    return o === ev.key;
                  }) > -1
                }
                onChange={() => this.handleHiddenChange(ev.key)}
                style={{ justifyContent: 'center', marginBottom: 0 }}
              />
            </td>
            <td className="text">{ev.displayName}</td>
          </tr>
        );
      });
    };

    return (
      <div>
        <p className="subHeadingText">HIDDEN SECTIONS</p>
        <Table className="plussTable" striped bordered condensed hover>
          <thead>
            <tr>
              <th style={{ width: 100, paddingRight: 20, textAlign: 'center' }}>Is Hidden</th>
              <th>Section</th>
            </tr>
          </thead>
          <tbody>{renderList()}</tbody>
        </Table>
      </div>
    );
  }

  renderSettings() {
    const renderList = () => {
      return this.state.settings.map((ev) => {
        return (
          <tr key={`${this.state.newType.Id}_settings_${ev.key}`}>
            <td style={{ width: 100, paddingRight: 20 }}>
              <CheckBox
                id={`${ev.key}`}
                isActive={!!this.state.newType.settings[ev.key]}
                onChange={() => this.handleSettingsChange(ev.key)}
                style={{ justifyContent: 'center', marginBottom: 0 }}
              />
            </td>
            <td className="text">{ev.text}</td>
          </tr>
        );
      });
    };

    return (
      <div>
        <p className="subHeadingText">SETTINGS</p>
        <Table className="plussTable" striped bordered condensed hover>
          <tbody>{renderList()}</tbody>
        </Table>
      </div>
    );
  }

  renderSelectType() {
    return (
      <div className="selectUserType">
        <p className="subHeadingText">SELECT TYPE</p>
        <RadioButton
          isActive={this.state.newType.category}
          className="userTypeOptions"
          rowStyle={{ flexDirection: 'row' }}
          options={this.getUserTypeOptions()}
        />
      </div>
    );
  }

  renderEdit() {
    const { selectedView, userType } = this.props;
    if (!selectedView || selectedView.Id !== userType.Id) return null;

    return (
      <div className="editContainer">
        <PageTitle style={{ marginBottom: 20 }}>User Type Details</PageTitle>
        {this.renderNameInput()}
        {this.renderPermissionsEdit()}
        {this.renderEditButtons()}
      </div>
    );
  }

  renderNameInput() {
    const iconColor = this.getIconColor();

    return (
      <div className="nameInputSection">
        <div className="iconContainer" style={{ backgroundColor: hexToRGBAstring(iconColor, 0.4) }}>
          <SVGIcon colour={iconColor} icon="userType" width="43px" height="34px" />
        </div>
        <GenericInput
          type="text"
          placeholder={this.state.oldType.displayName}
          value={this.state.newType.displayName}
          onChange={(e) => this.handleNameChange(e.target.value)}
          inputClass="userTypeNameInput"
          maxLength={100}
          style={{ marginBottom: 0 }}
        />
      </div>
    );
  }

  renderPermissionsEdit() {
    return (
      <div>
        {this.renderSelectType()}
        {this.renderPermissions()}
        {this.renderSettings()}
        {this.renderHiddenSections()}
      </div>
    );
  }

  renderEditButtons() {
    const { saving, deleting } = this.state;

    return (
      <div className="buttonsSection">
        <Button buttonType="primary" onClick={() => this.handleSubmit()} isActive={this.isChanged()}>
          {saving ? 'Saving...' : 'Save'}
        </Button>
        <Button
          buttonType="outlined"
          onClick={() => this.confirmDeleteType()}
          isActive={!this.isSubmitting()}
          buttonStyle={{ marginRight: 16 }}
        >
          {deleting ? 'Deleting...' : 'Delete User Type'}
        </Button>
        <Button buttonType="tertiary" onClick={this.onCloseEdit} isActive={!this.isSubmitting()}>
          Cancel
        </Button>
      </div>
    );
  }

  render() {
    const { newType } = this.state;
    const { selectedView, selectView } = this.props;
    const iconColor = this.getIconColor();

    return (
      <div className={`userType ${selectedView && selectedView.Id === newType.Id ? 'selected' : ''}`}>
        <div className="iconContainer" style={{ backgroundColor: hexToRGBAstring(iconColor, 0.4) }}>
          <SVGIcon colour={iconColor} icon="userType" width="43px" height="34px" />
        </div>
        <div className="userTypeName">{newType.displayName}</div>
        <Button inline buttonType="primaryAction" onClick={() => selectView(newType)} isActive>
          Settings
        </Button>
        {this.renderEdit()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(PermissionType);
