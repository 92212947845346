import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import { Header, AddButton } from '../../components';
import { validateAccess } from '../../session';
import { usersLoaded, setAuth } from '../../actions';
import { userActions, typeActions } from '../../webapi';
import { COLOUR_BRANDING_OFF } from '../../js';
import Users from './Users';
import UserAnalytics from './UserAnalytics';
import { isKiosk } from '../../helper';

class UsersHub extends Component {
  state = {
    selectedSection: 'all',
    location: '',

    loadingAll: false,
    loadingSubmissions: false,

    submissionEntries: [],

    allList: [],
    residents: [],
    staff: [],
    family: [],
    kiosk: [],
    now: moment.utc(),
    onlyFuture: true,
    search: '',

    showDropDown: false,
  };

  UNSAFE_componentWillMount() {
    this.updateProps(this.props);
  }

  componentDidMount() {
    this.getData();
    this.getUserTypes();
    // this.getSubmissions()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth.site && this.props.auth.site !== nextProps.auth.site) {
      this.getData();
    }
    this.updateProps(nextProps);
  }

  updateProps(props) {
    const residentTypes = [];
    const staffTypes = [];
    const familyTypes = [];
    if (!_.isEmpty(this.props.auth.userTypes) && Array.isArray(this.props.auth.userTypes)) {
      this.props.auth.userTypes.forEach((ev) => {
        if (!_.isUndefined(ev.category) && ev.category === 'resident') {
          residentTypes.push({ typeName: ev.typeName, site: ev.site });
        }
        if (!_.isUndefined(ev.category) && ev.category === 'staff') {
          staffTypes.push({ typeName: ev.typeName, site: ev.site });
        }
        if (!_.isUndefined(ev.category) && ev.category === 'family') {
          familyTypes.push({ typeName: ev.typeName, site: ev.site });
        }
      });
    }

    const allUsers = _.filter(props.users, (ev) => {
      if (!ev) {
        return false;
      }
      return ev.site !== 'plussSpace' || this.props.auth.site === 'plussSpace';
    });

    const residents = _.filter(allUsers, (ev) => {
      if (!ev) {
        return false;
      }
      return _.some(residentTypes, (t) => {
        return t.site === ev.site && t.typeName === ev.type;
      });
    });
    const staff = _.filter(allUsers, (ev) => {
      if (!ev) {
        return false;
      }
      return _.some(staffTypes, (t) => {
        return t.site === ev.site && t.typeName === ev.type;
      });
    });
    const family = _.filter(allUsers, (ev) => {
      if (!ev) {
        return false;
      }
      return _.some(familyTypes, (t) => {
        return t.site === ev.site && t.typeName === ev.type;
      });
    });
    const kiosk = _.filter(allUsers, (ev) => {
      if (!ev) {
        return false;
      }
      return isKiosk(ev.type);
    });

    this.setState({
      allList: allUsers,
      residents,
      staff,
      family,
      kiosk,
    });
  }

  getData() {
    this.setState({
      loadingAll: true,
    });

    userActions
      .fetchUsers(this.props.auth.site)
      .then((res) => {
        this.setState({
          loadingAll: false,
        });
        if (res.userFetchFail) {
          return;
        }
        if (
          res.data != null &&
          !_.isEmpty(res.data.results.Items) &&
          (res.data.results.Items[0].site === this.props.auth.site || this.props.auth.site === 'hq')
        ) {
          this.buildData(res.data.results.Items);
        }
      })
      .catch((error) => {
        this.setState({ loadingAll: false });
      });
  }

  buildData(users) {
    if (!_.isEmpty(users)) {
      this.props.usersLoaded([...users]);
    }
  }

  addNew(action) {
    if (validateAccess(this.props.auth.site, 'userManagement', this.props.auth)) {
      if (action === 'single') {
        this.props.history.push(`/usershub/adduser`);
      }
      if (action === 'kiosk') {
        this.props.history.push(`/usershub/addkiosk`);
      }
      if (action === 'bulk') {
        this.props.history.push(`/usershub/bulkadduser`);
      }
      if (action === 'uploads') {
        this.props.history.push(`/usershub/bulkuploads`);
      }
    }
  }

  canAddNew(isClass) {
    if (validateAccess(this.props.auth.site, 'userManagement', this.props.auth)) {
      return isClass ? '' : true;
    }
    return isClass ? ' hub-sideContent-topButton--hide' : false;
  }

  getSideBarSectionColour(id) {
    return this.state.selectedSection === id ? { backgroundColor: '#fff' } : {};
  }

  renderStats(gweg, loading) {
    if (loading) {
      return <FontAwesome style={styles.spinner} name="spinner fa-pulse fa-fw" />;
    }
    return gweg;
  }

  getUserTypes() {
    typeActions
      .getUserTypes(this.props.auth.site === 'hq' ? 'all' : this.props.auth.site)
      .then((res) => {
        this.props.setAuth({ userTypes: res.data });
      })
      .catch((error) => {});
  }

  getTypeTitle(user) {
    const typeObject = _.find(this.props.auth.userTypes, (t) => {
      return t.typeName === user.type;
    });
    if (typeObject) {
      return typeObject.displayName;
    }
    return '';
  }

  getUsers() {
    switch (this.state.selectedSection) {
      case 'all':
        return this.state.allList;
      case 'Residents':
        return this.state.residents;
      case 'Staff':
        return this.state.staff;
      case 'Family':
        return this.state.family;
      case 'Kiosk':
        return this.state.kiosk;
      default:
        break;
    }
    return [];
  }

  renderLeftBar() {
    return (
      <div className="hub-sideContent">
        {/* Top Add Button */}
        <div className="hub-sideContent-topButton" />
        <div style={{ paddingLeft: 15, width: '100%' }}>
          {/* Title */}
          <div className="fontMedium fontSize-36 text-dark" style={styles.sideBarTitleSection}>
            Users
          </div>
          {/* Content */}
          {/* Analytics */}
          <div
            onClick={() => {
              this.setState({ selectedSection: 'Analytics' });
            }}
            className="sideBarSection"
            style={this.getSideBarSectionColour('Analytics')}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              <FontAwesome name="line-chart" className="sideBarSection__icon" />
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">Analytics</div>
          </div>
          {/* All USERS */}
          <div
            onClick={() => {
              this.setState({ selectedSection: 'all' });
            }}
            className="sideBarSection"
            style={this.getSideBarSectionColour('all')}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats(this.state.allList.length, this.state.loadingAll)}
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">Users</div>
          </div>
          {/* RESIDENTS */}
          <div
            onClick={() => {
              this.setState({ selectedSection: 'Residents' });
            }}
            className="sideBarSection"
            style={this.getSideBarSectionColour('Residents')}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats(
                (this.state.residents || []).length,
                this.state.loadingAll || (this.props.auth.userTypes || []).length === 0,
              )}
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">Primary users</div>
          </div>
          {/* STAFF */}
          <div
            onClick={() => {
              this.setState({ selectedSection: 'Staff' });
            }}
            className="sideBarSection"
            style={this.getSideBarSectionColour('Staff')}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats((this.state.staff || []).length, this.state.loadingAll || (this.props.auth.userTypes || []).length === 0)}
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">Staff</div>
          </div>
          {/* FAMILY */}
          <div
            onClick={() => {
              this.setState({ selectedSection: 'Family' });
            }}
            className="sideBarSection"
            style={this.getSideBarSectionColour('Family')}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats((this.state.family || []).length, this.state.loadingAll || (this.props.auth.userTypes || []).length === 0)}
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">Linked users</div>
          </div>
          {/* KIOSK */}
          <div
            onClick={() => {
              this.setState({ selectedSection: 'Kiosk' });
            }}
            className="sideBarSection"
            style={this.getSideBarSectionColour('Kiosk')}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats((this.state.kiosk || []).length, this.state.loadingAll || (this.props.auth.userTypes || []).length === 0)}
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">Kiosk</div>
          </div>
        </div>
      </div>
    );
  }

  renderRight() {
    if (this.state.selectedSection === 'Analytics') {
      return <UserAnalytics userTypes={this.props.auth.userTypes} />;
    }
    return <Users userTypes={this.props.auth.userTypes} filter={this.state.selectedSection} users={this.getUsers()} />;
  }

  render() {
    return (
      <div className="hub-wrapperContainer">
        {this.renderLeftBar()}
        <div className="hub-headerContentWrapper">
          <Header>
            <div className="topHeader-addButtonWithDropdown" style={{ position: 'relative' }}>
              <AddButton text="NEW USER" />
              <div
                className="hub-sideContent-topButton-dropdown"
                style={{
                  backgroundColor: 'white',
                  position: 'absolute',
                  top: 30,
                  minWidth: 100,
                  boxShadow: '0px 0px 16px rgba(106, 163, 216, 0.5)',
                }}
              >
                <div
                  onClick={() => {
                    this.addNew('single');
                  }}
                  className="fontRegular fontSize-16 subtleHover"
                  style={{ padding: 8 }}
                >
                  New user
                </div>
                {this.props.auth.site !== 'hq' && (
                  <div
                    onClick={() => {
                      this.addNew('kiosk');
                    }}
                    className="fontRegular fontSize-16 subtleHover"
                    style={{ padding: 8 }}
                  >
                    New kiosk login
                  </div>
                )}
                {this.props.auth.site !== 'hq' && (
                  <div
                    onClick={() => {
                      this.addNew('bulk');
                    }}
                    className="fontRegular fontSize-16 subtleHover"
                    style={{ padding: 8 }}
                  >
                    Bulk upload
                  </div>
                )}
                {this.props.auth.site !== 'hq' && (
                  <div
                    onClick={() => {
                      this.addNew('uploads');
                    }}
                    className="fontRegular fontSize-16 subtleHover"
                    style={{ padding: 8 }}
                  >
                    Check uploads
                  </div>
                )}
              </div>
            </div>
          </Header>
          <div className="hub-contentWrapper">{this.renderRight()}</div>
        </div>
      </div>
    );
  }
}

const styles = {
  sideBarTitleSection: {
    lineHeight: '40px',
    marginTop: 30,
    marginBottom: 30,
    paddingLeft: 24,
    paddingRight: 24,
  },
  sideBarSection: {
    weight: '100%',
    minWidth: 200,
    padding: 32,
    paddingLeft: 24,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  spinner: {
    fontSize: 32,
    color: COLOUR_BRANDING_OFF,
  },
};

const mapStateToProps = (state) => {
  const { users } = state.users;
  const { auth } = state;
  return {
    users,
    auth,
  };
};

export default connect(mapStateToProps, { usersLoaded, setAuth })(UsersHub);
