import React, { Component } from 'react';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { Header } from '../../components';
import { validateAccess, isFeatureEnabled } from '../../session';
import ImportantContacts from '../ImportantContacts/ImportantContacts';
import Maps from '../Maps/Maps';
import { COLOUR_BRANDING_OFF } from '../../js';
import { CONST_STRINGS } from '../../config';
import InfoPages from './InfoPages';
import WelcomePages from './WelcomePages';
import EditAppTerms from '../Terms/EditAppTerms';

class InfoHub extends Component {
  state = {
    selectedSection: '',
  };

  getSideBarSectionColour(id) {
    return this.state.selectedSection === id ? 'fontHeavy' : 'fontLight ';
  }

  renderStats(gweg, loading) {
    if (loading) {
      return <FontAwesome style={styles.spinner} name="spinner fa-pulse fa-fw" />;
    }
    return gweg;
  }

  renderLeftBar() {
    return (
      <div className="hub-sideContent" style={{ minWidth: 250 }}>
        {/* Top Add Button */}
        <div className={`hub-sideContent-topButton hub-sideContent-topButton--hide`}>
          <span className="hub-sideContent-topButton-text"> </span>
          <FontAwesome className="hub-sideContent-topButton-icon" name={'plus'} />
        </div>
        <div style={{ paddingLeft: 15, width: '100%' }}>
          {/* Title */}
          <div className="fontMedium fontSize-36 text-dark" style={styles.sideBarTitleSection}>
            Information
          </div>
          {isFeatureEnabled(this.props.auth.features, 'importantContacts') &&
            validateAccess(this.props.auth.site, 'importantContacts', this.props.auth) && (
              <div
                onClick={() => {
                  this.setState({ selectedSection: 'Contacts' });
                }}
                className={`${this.getSideBarSectionColour('Contacts')} fontSize-16 pointer text-dark`}
                style={{ height: 'auto', margin: 24 }}
              >
                Contacts
              </div>
            )}
          {isFeatureEnabled(this.props.auth.features, 'maps') && validateAccess(this.props.auth.site, 'maps', this.props.auth) && (
            <div
              onClick={() => {
                this.setState({ selectedSection: 'Maps' });
              }}
              className={`${this.getSideBarSectionColour('Maps')} fontSize-16 pointer text-dark`}
              style={{ height: 'auto', margin: 24 }}
            >
              Maps
            </div>
          )}
          {isFeatureEnabled(this.props.auth.features, 'infoPages') && validateAccess(this.props.auth.site, 'infoPages', this.props.auth) && (
            <div
              onClick={() => {
                this.setState({ selectedSection: 'InfoPages' });
              }}
              className={`${this.getSideBarSectionColour('InfoPages')} fontSize-16 pointer text-dark`}
              style={{ height: 'auto', margin: 24 }}
            >
              {CONST_STRINGS.FAQS}
            </div>
          )}
          {/* {isFeatureEnabled(this.props.auth.features, 'news') && validateAccess(this.props.auth.site, 'welcomePages', this.props.auth) && (
            <div
              onClick={() => {
                this.setState({ selectedSection: 'WelcomePages' });
              }}
              className={`${this.getSideBarSectionColour('WelcomePages')} fontSize-16 pointer text-dark`}
              style={{ height: 'auto', margin: 24 }}
            >
              Welcome
              <br />
              Information
            </div>
          )} */}
          {validateAccess(this.props.auth.site, 'terms', this.props.auth) && (
            <div
              onClick={() => {
                this.setState({ selectedSection: 'Terms' });
              }}
              className={`${this.getSideBarSectionColour('Terms')} fontSize-16 pointer text-dark`}
              style={{ height: 'auto', margin: 24 }}
            >
              Terms & conditions
            </div>
          )}
        </div>
      </div>
    );
  }

  renderRight() {
    if (this.state.selectedSection === 'Contacts' && validateAccess(this.props.auth.site, 'importantContacts', this.props.auth)) {
      return <ImportantContacts />;
    }
    if (this.state.selectedSection === 'Maps' && validateAccess(this.props.auth.site, 'maps', this.props.auth)) {
      return <Maps />;
    }
    if (this.state.selectedSection === 'InfoPages' && validateAccess(this.props.auth.site, 'infoPages', this.props.auth)) {
      return <InfoPages />;
    }
    // if (this.state.selectedSection === 'WelcomePages' && validateAccess(this.props.auth.site, 'welcomePages', this.props.auth)) {
    //   return <WelcomePages />;
    // }
    if (this.state.selectedSection === 'Terms' && validateAccess(this.props.auth.site, 'terms', this.props.auth)) {
      return <EditAppTerms />;
    }
    return null;
  }

  render() {
    return (
      <div className="hub-wrapperContainer">
        {this.renderLeftBar()}
        <div className="hub-headerContentWrapper">
          <Header />
          <div className="hub-contentWrapper" style={this.state.selectedSection === 'Feedback' ? { padding: 0 } : {}}>
            {this.renderRight()}
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  sideBarTitleSection: {
    lineHeight: '50px',
    marginTop: 30,
    marginBottom: 30,
    paddingLeft: 24,
    paddingRight: 32,
  },
  sideBarSection: {
    weight: '100%',
    minWidth: 200,
    padding: 32,
    paddingLeft: 24,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  spinner: {
    fontSize: 32,
    color: COLOUR_BRANDING_OFF,
  },
};

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(InfoHub);
