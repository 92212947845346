import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { CheckBox, GenericInput, Button } from '../../components';
import { isEmail } from '../../helper';
import { authActions, typeActions } from '../../webapi';
import { setAuthUser, setRemember } from '../../actions';
import { checkLogInScreen } from '../../session';
import Forgot from './Forgot';

class Login extends Component {
  initialState = {
    authKey: '',
    submitting: false,

    email: '',
    password: '',

    forgot: '',
    remember: false,

    showForgot: false,
    showWarnings: false,
    loadingBackground: true,
  };

  state = { ...this.initialState };

  UNSAFE_componentWillMount() {
    checkLogInScreen(this, this.props.auth);
    if (!_.isUndefined(this.props.auth.chooken) && !_.isEmpty(this.props.auth.chooken)) {
      this.setState({
        email: this.props.auth.chooken.chicken,
        password: this.props.auth.chooken.rooster,
        remember: true,
      });
    }
    this.getBackground();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth) {
      checkLogInScreen(this, nextProps.auth);
    }
  }

  getBackground() {
    typeActions
      .getSubdomain(window.location.host.split('.')[0])
      .then((res) => {
        this.setState({
          loadingBackground: false,
          backgroundImage: res.data.Background,
        });
      })
      .catch((res) => {
        this.setState({
          loadingBackground: false,
        });
      });
  }

  handleEnter(event) {
    if (!_.isUndefined(event.charCode) && event.charCode === 13) {
      this.handleSubmit();
    }
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;

    this.setState(stateChange);
  }

  handleSubmit() {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    const email = this.state.email;
    const password = this.state.password;
    this.setState({ showWarnings: false, submitting: true, gooch: null });

    Auth.signIn(email.toLowerCase(), password)
      .then((result) => {
        const userId = result.username;

        authActions
          .checkUserAuth(userId)
          .then((res) => {
            authActions
              .getUserSignin()
              .then((userRes) => {
                console.log(userRes);
                if (res.data.type != null) {
                  this.props.setAuthUser(res.data.type, { ...userRes.data, uid: userRes.data.Id }, res.data.site);
                  this.props.setRemember(this.state.remember ? { chicken: email.toLowerCase(), rooster: password } : null);
                } else {
                  this.props.setAuthUser(null, null);
                  this.setState({ submitting: false, gooch: 'Non-Authorized User' });
                }
              })
              .catch((err) => {
                console.log('catch in getUserSignIn hit');
                this.setState({ submitting: false, gooch: null });
              });
          })
          .catch((err) => {
            console.log('catch in checkAuth hit');
            this.setState({ submitting: false, gooch: null });
          });
      })
      .catch((err) => {
        console.log('FAILED');
        this.setState({ submitting: false, gooch: 'Failed to log in' });
      });
  }

  toggleForgot(gweg, goo) {
    if (this.state.submitting) {
      return;
    }
    this.setState({ showForgot: gweg, showChangeSuccess: goo });
  }
  validateForgotForm() {
    return !_.isEmpty(this.state.forgot);
  }
  validateForm() {
    return isEmail(this.state.email) && !_.isEmpty(this.state.password);
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Logging in...</Button>;
    }
    return (
      <Button buttonType="primary" onClick={this.handleSubmit.bind(this)} isActive={this.validateForm()}>
        Login
      </Button>
    );
  }

  renderForm() {
    if (this.state.showForgot) {
      return <Forgot goBack={this.toggleForgot.bind(this)} />;
    }

    return (
      <div>
        <GenericInput
          id="email"
          isRequired
          label="Email"
          placeholder="Enter your email"
          value={this.state.email}
          onChange={(e) => this.handleChange(e)}
          isValid={() => {
            return isEmail(this.state.email);
          }}
          showError={() => {
            return this.state.showWarnings && !isEmail(this.state.email);
          }}
          disabled={this.state.submitting}
        />
        <GenericInput
          id="password"
          isRequired
          label="Password"
          type="password"
          placeholder="Enter your password"
          value={this.state.password}
          onChange={(e) => this.handleChange(e)}
          onEnter={(e) => this.handleEnter(e)}
          isValid={() => {
            return this.state.password.length > 0;
          }}
          showError={() => {
            return this.state.showWarnings && this.state.password.length === 0;
          }}
          disabled={this.state.submitting}
        />
        <CheckBox
          label={'Remember me'}
          isActive={this.state.remember}
          onChange={() => {
            this.setState({ remember: !this.state.remember });
          }}
        />
        <div
          className={'inputGroup-label text-plussBlue pointer'}
          style={{ marginBottom: 16, marginTop: 32 }}
          onClick={() => this.toggleForgot(true)}
        >
          Forgot password
        </div>
        {this.renderSubmit()}
      </div>
    );
  }

  render() {
    return (
      <div className={'relative pageContainer'}>
        <div
          className={`backgroundImage ${
            !this.state.loadingBackground && _.isEmpty(this.state.backgroundImage) ? 'backgroundImage-login' : ''
          }`}
          style={!_.isEmpty(this.state.backgroundImage) ? { backgroundImage: `url(${this.state.backgroundImage})` } : null}
        />
        <div className={'poweredByPluss'} />
        <div className={'loginStripe'}>
          <div className={'textAlign-right fontSize-16 text-loginGrey'}>
            <span className={'fontHeavy'}>community</span>
            <span className={'fontRegular '}>manager</span>
          </div>
          {!this.state.showForgot && (
            <div>
              <div className={'marginTop-55 merri-bold fontSize-26 text-plussBlue'}>
                {this.state.showForgot ? 'Reset your password' : 'Sign In'}
              </div>
              <div className={'marginTop-16 fontRegular fontSize-16'}>
                {this.state.showForgot
                  ? 'Enter your email address below and we will send you a link to reset your password.'
                  : 'Sign in to manage your Pluss Communities, events, users, maintenance requests and more.'}
              </div>
              <div className={'text-plussRed fontSize-16 sf-semibold'} style={styles.errorContainer}>
                {this.state.gooch}
              </div>
              {!_.isUndefined(this.state.showChangeSuccess) && this.state.showChangeSuccess && (
                <div className={'text-plussRed fontSize-16 sf-semibold'} style={styles.errorContainer}>
                  Password successfully changed. Please log in.
                </div>
              )}
            </div>
          )}
          {/* Input Fields */}
          {this.renderForm()}
        </div>
      </div>
    );
  }
}

const styles = {
  errorContainer: {
    minHeight: 32,
    padding: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, { setAuthUser, setRemember })(withRouter(Login));
