import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import { Header, AddButton } from '../../components';
import { validateAccess } from '../../session';
import { servicesLoaded } from '../../actions';
import { serviceActions } from '../../webapi';
import { COLOUR_BRANDING_OFF } from '../../js';
import SponsorsList from './SponsorsList';

class SponsorsHub extends Component {
  state = {
    selectedSection: 'all',
    location: '',

    loadingAll: false,
    loadingSubmissions: false,

    submissionEntries: [],

    allList: [],
    isActive: [],
    InApp: [],
    External: [],
    now: moment.utc(),
    onlyFuture: true,
    search: '',
  };

  UNSAFE_componentWillMount() {
    this.updateProps(this.props);
  }

  componentDidMount() {
    this.getData();
    // this.getSubmissions()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps);
  }

  updateProps(props) {
    const isActive = [];
    const InApp = [];
    const External = [];

    props.services.forEach((rep) => {
      if (_.isUndefined(rep.Deleted) || !rep.Deleted) {
        isActive.push(rep);
        if (_.isUndefined(rep.Internal) || rep.Internal) {
          if (rep.Sponsored) {
            InApp.push(rep);
          }
        } else {
          External.push(rep);
        }
      }
    });

    this.setState({
      allList: props.services,
      isActive: [...InApp, ...External],
      InApp,
      External,
      submissionEntries: props.submissions,
    });
  }

  getData() {
    this.setState({
      loadingAll: true,
    });
    serviceActions
      .getServices(this.props.auth.site)
      .then((res) => {
        this.setState({
          loadingAll: false,
        });
        if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
          this.props.servicesLoaded(res.data);
        }
      })
      .catch((res2) => {
        this.setState({ loadingAll: false });
        console.log('fail from getServices');
        console.log(res2);
      });
  }

  addNew() {
    this.props.history.push(`/sponsors/sponsor`);
  }

  canAddNew(isClass) {
    return isClass ? '' : true;
  }

  getSideBarSectionColour(id) {
    return this.state.selectedSection === id ? { backgroundColor: '#fff' } : {};
  }

  renderStats(gweg, loading) {
    if (loading) {
      return <FontAwesome style={styles.spinner} name="spinner fa-pulse fa-fw" />;
    }
    return gweg;
  }

  renderLeftBar() {
    if (
      validateAccess(this.props.auth.site, 'newsletterSubmit', this.props.auth) &&
      !validateAccess(this.props.auth.site, 'newsletter', this.props.auth)
    ) {
      return null;
    }
    return (
      <div className="hub-sideContent" style={{ minWidth: 200 }}>
        {/* Top Add Button */}
        <div className="hub-sideContent-topButton" />
        <div style={{ paddingLeft: 15, width: '100%' }}>
          {/* Title */}
          <div className="fontMedium fontSize-36 text-dark" style={styles.sideBarTitleSection}>
            Sponsors
          </div>
          {/* Content */}
          {/* All Articles */}
          <div
            onClick={() => {
              this.setState({ selectedSection: 'all' });
            }}
            className="sideBarSection"
            style={this.getSideBarSectionColour('all')}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats(this.state.isActive.length, this.state.loadingAll)}
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">All Sponsors</div>
          </div>
          <div
            onClick={() => {
              this.setState({ selectedSection: 'ext' });
            }}
            className="sideBarSection"
            style={this.getSideBarSectionColour('ext')}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats(this.state.External.length, this.state.loadingAll)}
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">Sponsors Only</div>
          </div>
          <div
            onClick={() => {
              this.setState({ selectedSection: 'sponsored' });
            }}
            className="sideBarSection"
            style={this.getSideBarSectionColour('sponsored')}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats(this.state.InApp.length, this.state.loadingAll)}
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">Sponsored Services</div>
          </div>
        </div>
      </div>
    );
  }

  renderRight() {
    return <SponsorsList filter={this.state.selectedSection} />;
  }

  render() {
    return (
      <div className="hub-wrapperContainer">
        {this.renderLeftBar()}
        <div className="hub-headerContentWrapper">
          <Header>{this.canAddNew() && <AddButton onClick={this.addNew.bind(this)} text="NEW SPONSOR" />}</Header>
          <div className="hub-contentWrapper">{this.renderRight()}</div>
        </div>
      </div>
    );
  }
}

const styles = {
  sideBarTitleSection: {
    lineHeight: '50px',
    marginTop: 30,
    marginBottom: 30,
    paddingLeft: 24,
  },
  sideBarSection: {
    weight: '100%',
    minWidth: 200,
    padding: 32,
    paddingLeft: 24,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  spinner: {
    fontSize: 32,
    color: COLOUR_BRANDING_OFF,
  },
};

const mapStateToProps = (state) => {
  const { services, submissions } = state.services;
  const { auth } = state;
  return {
    services,
    submissions,
    auth,
  };
};

export default connect(mapStateToProps, { servicesLoaded })(SponsorsHub);
