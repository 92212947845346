import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button, OverlayPage, OverlayPageContents, OverlayPageSection, OverlayPageBottomButtons, UserListing } from '../../components';
import { feedbackActions } from '../../webapi';
import { checkLoggedIn, validateAccess } from '../../session';
import { safeReadParams, toParagraphed, getPluralS, getPercentage } from '../../helper';
import FontAwesome from 'react-fontawesome';
import { pollsLoaded } from '../../actions';
import { ProfilePic } from '../../components/ProfilePic';

class PollResults extends Component {
  initialState = {
    pollId: safeReadParams(this.props, 'pollId'),
    poll: {},
  };

  state = { ...this.initialState };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
    this.setStatePoll(this.props.polls, safeReadParams(this.props, 'pollId'));
  }

  componentDidMount() {
    setTimeout(() => {
      if (!validateAccess(this.props.auth.site, 'polls', this.props.auth) && !this.state.poll.ShowResults) {
        window.history.back();
      }
    }, 500);
  }

  setStatePoll(polls, pollId) {
    const poll = _.find(polls, (p) => {
      return p != null && p.Id === pollId;
    });
    if (poll) {
      this.setState({
        poll,
        pollLoaded: true,
      });
      this.loadResults(pollId);
      this.loadAudience(pollId);
    } else if (!this.state.loadedPolls) {
      this.loadPolls();
    } else {
      //TODO show failure to find poll
    }
  }

  loadPolls() {
    this.setState({
      loadedPolls: true,
    });
    feedbackActions.getPolls(this.props.auth.site).then((res) => {
      if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
        this.props.pollsLoaded(res.data);
      }
      this.setStatePoll(res.data, this.state.pollId);
    });
  }

  loadAudience(pollId) {
    feedbackActions.getPollAudience(pollId).then((res) => {
      console.log(res.data);
      if (res.data.Anonymous) {
        this.setState({
          audienceAnonymous: true,
        });
      } else {
        this.setState({
          audience: res.data.Audience,
        });
      }
    });
  }

  loadResults(pollId) {
    feedbackActions.getPollResults(pollId).then((res) => {
      this.setState({
        results: res.data,
      });
      console.log(res.data);
    });
  }

  getParagraphResults(qid) {
    if (!this.state.results || !this.state.results[qid]) {
      return [];
    }
    return this.state.results[qid];
  }

  getResponseCount(qid, oid) {
    if (!this.state.results || !this.state.results[qid] || !this.state.results[qid][oid]) {
      return 0;
    }
    return this.state.results[qid][oid];
  }

  getPercentage(count) {
    return getPercentage(count, this.state.poll.ResponseCount);
  }

  renderBack() {
    return (
      <div>
        <Button
          inline
          buttonType="primary"
          onClick={() => {
            window.history.back();
          }}
          isActive
        >
          Back to Surveys
        </Button>
      </div>
    );
  }

  renderMultiChoiceOption(q, o) {
    const count = this.getResponseCount(q.Id, o.Id);
    const percentage = this.getPercentage(count);
    return (
      <div className="multichoiceresult" key={o.Id}>
        <div className="multichoiceresult_responses">
          {count} response{getPluralS(count)}
        </div>
        <div className="multichoiceresult_percentage">{percentage}</div>
        <div className="multichoiceresult_bar">
          <div className="multichoiceresult_innerBar" style={{ width: percentage }}></div>
          <p className="multichoiceresult_innerText">{o.Text}</p>
        </div>
      </div>
    );
  }

  renderMultiChoiceResults(question) {
    return question.Options.map((o) => {
      return this.renderMultiChoiceOption(question, o);
    });
  }

  renderParagraphResult(response, index) {
    return (
      <div className="paragraphresult" key={index}>
        <p className="paragraphresult_text">{toParagraphed(response.Answer)}</p>
        <div className="paragraphresult_bottom">
          <ProfilePic className="paragraphresult_pic" size={24} image={response.User.profilePic}></ProfilePic>
          <div className="paragraphresult_name">{response.User.displayName}</div>
        </div>
      </div>
    );
  }

  renderParagraphResults(question) {
    return (
      <div className="paragraphresults">
        {this.getParagraphResults(question.Id).map((r, i) => {
          return this.renderParagraphResult(r, i);
        })}
      </div>
    );
  }

  renderScaleResult(q, o) {
    const count = this.getResponseCount(q.Id, o.Value);
    const percentage = this.getPercentage(count);
    return (
      <div key={o.Value} className="scaleresult">
        <p className="scaleresult_percentage">{percentage}</p>
        <p className="scaleresult_count">
          {count} response{getPluralS(count)}
        </p>
        <p className="scaleresult_number">{o.Value}</p>
        <p className="scaleresult_text">{o.Text}</p>
      </div>
    );
  }

  renderScaleResults(question) {
    return (
      <div className="scaleresults">
        {question.Options.map((o, i) => {
          return this.renderScaleResult(question, o, i);
        })}
      </div>
    );
  }

  renderCheckboxResults(question) {
    return question.Options.map((o) => {
      return this.renderMultiChoiceOption(question, o);
    });
  }

  renderQuestion(question, index) {
    let content = null;

    switch (question.Type) {
      case 'multichoice':
        content = this.renderMultiChoiceResults(question);
        break;
      case 'paragraph':
        content = this.renderParagraphResults(question);
        break;
      case 'scale':
        content = this.renderScaleResults(question);
        break;
      case 'checkbox':
        content = this.renderCheckboxResults(question);
        break;
      default:
        break;
    }
    return (
      <div className="questionContainer" key={index} style={{ marginTop: index === 0 ? 0 : 32 }}>
        <div className="questionNumber_container">
          <p className="questionNumber">{index + 1}</p>
        </div>
        <div className={`question question-results ${question.Type === 'paragraph' ? 'question-paragraph' : ''}`}>
          <p className="question_title">{question.Text}</p>
          {question.Description && <p className="question_description">{question.Description}</p>}
          {content}
        </div>
      </div>
    );
  }

  renderQuestions() {
    return _.map(this.state.poll.Questions, (q, i) => {
      return this.renderQuestion(q, i);
    });
  }

  renderMain() {
    return (
      <div>
        <div className="padding-60 paddingBottom-40 bottomDivideBorder">
          <div className="newTopBar clearfix">
            <p className="newTopBarTitle text-sectionTitle">SURVEY RESULTS</p>
          </div>
          <p className="pollTitle">{this.state.poll.Title}</p>
        </div>
        <div className="padding-60 paddingLeft-20">
          <div className="newTopBar clearfix paddingLeft-40">
            <p className="newTopBarTitle text-sectionTitle">SURVEY ANSWERS</p>
          </div>
          {this.renderQuestions()}
        </div>
      </div>
    );
  }

  renderOverview() {
    if (!this.state.pollLoaded) {
      return null;
    }
    return (
      <div className="padding-32 paddingVertical-40 bottomDivideBorder">
        <p className="newTopBarTitle text-sectionTitle">OVERVIEW</p>
        <div className="polloverview">
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="users" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.state.poll.AudienceCount}</p>
              <p className="polloverview_text">Participant{getPluralS(this.state.poll.AudienceCount)}</p>
            </div>
          </div>
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="pencil" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.state.poll.ResponseCount}</p>
              <p className="polloverview_text">Response{getPluralS(this.state.poll.ResponseCount)}</p>
            </div>
          </div>
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="line-chart" />
            <div className="polloverview_right">
              <p className="polloverview_count">{getPercentage(this.state.poll.ResponseCount, this.state.poll.AudienceCount)}</p>
              <p className="polloverview_text">Response Rate</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAudience() {
    if (!this.state.audience) {
      if (this.state.audienceAnonymous) {
        return (
          <div className="padding-32 paddingVertical-40 bottomDivideBorder">
            <p className="newTopBarTitle text-sectionTitle">PARTICIPANTS</p>
            <div className="pollaudience_anonymous">
              <FontAwesome className="pollaudience_anonymousIcon" name="eye-slash" />
              <p className="pollaudience_text">
                This survey is anynomous.
                <br />
                You are not able to see who has responded.
              </p>
            </div>
          </div>
        );
      }
      return null;
    }
    const responded = [];
    const notResponded = [];
    this.state.audience.forEach((u) => {
      if (_.includes(this.state.poll.ResponseIds, u.id)) {
        responded.push(u);
      } else {
        notResponded.push(u);
      }
    });
    return (
      <div className="padding-32 paddingVertical-40 bottomDivideBorder">
        <p className="newTopBarTitle text-sectionTitle">PARTICIPANTS</p>
        <div className="pollaudience">
          <div className="pollaudience_left">
            <p className="pollaudience_title">Responded</p>
            <div className="pollaudience_bottom">
              {_.map(_.sortBy(responded, 'displayName'), (user) => {
                return <UserListing user={user} key={user.id} size={30} />;
              })}
            </div>
          </div>
          <div className="pollaudience_right">
            <p className="pollaudience_title">Not Responded</p>
            <div className="pollaudience_bottom">
              {_.map(_.sortBy(notResponded, 'displayName'), (user) => {
                return <UserListing user={user} key={user.id} size={30} />;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSideForm() {
    return (
      <OverlayPageSection className="pageSectionWrapper--newPopupSideLarge">
        {this.renderOverview()}
        {this.renderAudience()}
      </OverlayPageSection>
    );
  }

  render() {
    return (
      <OverlayPage>
        <OverlayPageContents id="PollContainer" noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--fixedPopupSize">{this.renderMain()}</OverlayPageSection>
          {this.renderSideForm()}
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderBack()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, { pollsLoaded })(PollResults);
