import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import { Header, AddButton, Button, GenericInput, WarningPopup, ImageInput, Popup } from '../../components';
import { validateAccess } from '../../session';
import { imageLibraryUpdate, imageFolderUpdated, imageFolderRemoved } from '../../actions';
import { fileActions } from '../../webapi';
import { COLOUR_BRANDING_OFF, COLOUR_TANGERINE } from '../../js';
import { get1400 } from '../../helper';

const NEW_FOLDER_ID = 'new';

class ImageLibraryHub extends Component {
  state = {
    selectedFolder: null,
    editingFolder: null,
    deletingFolder: null,
    deletingImage: null,
    uploadingImage: false,
    imagesUploaded: [],
    searchText: '',
    showPublished: false,
  };

  componentDidMount() {
    this.getImageLibrary();
  }

  getImageLibrary() {
    this.props.imageLibraryUpdate(this.props.auth.site, true);
  }

  canManageLibrary = () => {
    return validateAccess(this.props.auth.site, 'manageImageLibrary', this.props.auth);
  };

  canAddImage = () => {
    return validateAccess(this.props.auth.site, 'addToImageLibrary', this.props.auth);
  };

  canAddPublishingImage = () => {
    return validateAccess(this.props.auth.site, 'addToPublishingImageLibrary', this.props.auth);
  };

  getImageStyle(displayValue) {
    if (_.isEmpty(displayValue)) return null;

    const image = get1400(displayValue);
    return { backgroundImage: `url(${image})` };
  }

  onSelectFolder = (selectedFolder) => {
    this.setState({ selectedFolder });
  };

  onSelectRoot = () => {
    this.getImageLibrary();
    this.setState({ selectedFolder: null });
  };

  onNewFolder = () => {
    const newFolder = { RowId: NEW_FOLDER_ID, Name: '', Images: [] };
    this.props.imageFolderUpdated(newFolder);
    this.onStartEditingFolder(null, newFolder);
  };

  onStartEditingFolder = (event, editingFolder) => {
    if (event) event.stopPropagation();

    this.setState({ editingFolder }, () => {
      const input = document.getElementById('addFolderInput');
      if (input) input.focus();
    });
  };

  isNewFolder = (folder) => _.isNil(folder.RowId) || folder.RowId === NEW_FOLDER_ID;

  onCancelEditingFolder = () => {
    const { editingFolder } = this.state;
    if (editingFolder && this.isNewFolder(editingFolder)) {
      this.props.imageFolderRemoved(editingFolder);
    }
    this.setState({ editingFolder: null });
  };

  onEndEditingFolder = () => {
    const updatedFolder = this.state.editingFolder;
    if (_.isEmpty(updatedFolder.Name)) return;

    this.setState({ editingFolder: null }, async () => {
      try {
        this.props.imageFolderUpdated(updatedFolder);
        if (this.isNewFolder(updatedFolder)) {
          await fileActions.addMediaFolder(this.props.auth.site, updatedFolder.Name);
        } else {
          await fileActions.updateMediaFolder(updatedFolder.RowId, this.props.auth.site, updatedFolder.Name);
        }
        this.getImageLibrary();
      } catch (error) {
        console.log('onEndEditingFolder error', error);
      }
    });
  };

  onChangeFolderName = (event) => {
    const editingFolder = { ...this.state.editingFolder };
    editingFolder.Name = event.target.value;
    this.setState({ editingFolder });
  };

  onDeleteFolder = (event, deletingFolder) => {
    if (event) event.stopPropagation();
    this.setState({ deletingFolder });
  };

  onConfirmDeleteFolder = () => {
    const deletedFolder = this.state.deletingFolder;
    this.setState({ deletingFolder: null, editingFolder: null }, async () => {
      try {
        this.props.imageFolderRemoved(deletedFolder);
        await fileActions.deleteMediaFolder(deletedFolder.RowId, this.props.auth.site);
        this.getImageLibrary();
      } catch (error) {
        console.log('deleteMediaFolder error', error);
      }
    });
  };

  onCancelDeleteFolder = () => {
    this.setState({ deletingFolder: null });
  };

  onDeleteImage = (event, deletingImage) => {
    if (event) event.stopPropagation();
    this.setState({ deletingImage });
  };

  onConfirmDeleteImage = () => {
    const deletingImage = { ...this.state.deletingImage };
    const updatedFolder = { ...this.state.selectedFolder };
    updatedFolder.Images = updatedFolder.Images.filter((image) => image.uri !== this.state.deletingImage.uri);
    this.setState({ deletingImage: null, selectedFolder: updatedFolder }, async () => {
      try {
        this.props.imageFolderUpdated(updatedFolder);
        await fileActions.deleteImagesFromFolder(updatedFolder.RowId, this.props.auth.site, [deletingImage]);
        this.getImageLibrary();
      } catch (error) {
        console.log('onConfirmDeleteImage error', error);
      }
    });
  };

  onCancelDeleteImage = () => {
    this.setState({ deletingImage: null });
  };

  onUploadImage = () => {
    this.setState({ uploadingImage: true });
  };

  onSaveUploadImage = () => {
    const { selectedFolder, imagesUploaded } = this.state;
    if (!selectedFolder || imagesUploaded.length < 1) return;

    const updatedFolder = { ...selectedFolder };
    updatedFolder.Images = [...updatedFolder.Images, ...imagesUploaded];
    this.setState({ uploadingImage: false, selectedFolder: updatedFolder }, async () => {
      try {
        this.props.imageFolderUpdated(updatedFolder);
        await fileActions.addImagesToFolder(updatedFolder.RowId, this.props.auth.site, imagesUploaded);
        this.getImageLibrary();
      } catch (error) {
        console.log('onSaveUploadImage error', error);
      }
    });
  };

  onImagesUploaded = () => {
    const { user } = this.props.auth;
    const imagesUploaded = this.refs.imageInput
      .getWrappedInstance()
      .getValue()
      .map((image) => {
        return {
          uri: image,
          date: moment().valueOf(),
          user: {
            Id: user.Id,
            displayName: user.displayName,
            profilePic: user.profilePic,
          },
        };
      });
    this.setState({ imagesUploaded });
  };

  onCancelUploadImage = () => {
    this.setState({ uploadingImage: false });
  };

  onSearchChange = (event) => {
    const searchText = event.target.value;
    this.setState({ searchText });
  };

  onChangePublishing = (isPublishing) => {
    const publishingFolder = this.props.imageLibrary.find((folder) => folder.Public);
    if (!publishingFolder) return;

    const updatedFolder = { ...publishingFolder };
    updatedFolder.Published = isPublishing;
    try {
      this.props.imageFolderUpdated(updatedFolder);
      setTimeout(async () => {
        this.setState({ showPublished: isPublishing });
        await fileActions.updateMediaFolder(updatedFolder.RowId, this.props.auth.site, null, isPublishing);
        this.getImageLibrary();
      }, 500);
    } catch (error) {
      console.log('onChangePublishing error', error);
    }
  };

  onConfirmPublished = () => {
    this.setState({ showPublished: false }, () => {
      this.props.history.push('/featurepicker');
    });
  };

  renderStats(stat, loading) {
    if (loading) return <FontAwesome style={styles.spinner} name="spinner fa-pulse fa-fw" />;
    return stat;
  }

  renderLeftBar() {
    const totalImages = this.props.imageLibrary.reduce((a, b) => a + (b.Images ? b.Images.length : 0), 0);
    return (
      <div className="hub-sideContent">
        {/* Top Add Button */}
        <div className="hub-sideContent-topButton" />
        <div style={{ paddingLeft: 15, width: '100%' }}>
          {/* Title */}
          <div className="fontMedium fontSize-36 text-dark" style={styles.sideBarTitleSection}>
            Gallery
          </div>
          {/* Content */}
          {/* All Articles */}
          <div onClick={this.onSelectRoot} className="sideBarSection">
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats(totalImages, this.props.loading)}
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">All Images</div>
          </div>
        </div>
      </div>
    );
  }

  renderPublicFolder(folder, index) {
    const imageCount = folder.Images ? folder.Images.length : 0;
    const countText = `${imageCount} Image${imageCount > 0 ? 's' : ''}`;

    return (
      <div key={index} className="imageLibraryPublicFolderContainer" onClick={this.onSelectFolder.bind(this, folder)}>
        <div className="leftSection">
          <div className="folderIcon"></div>
        </div>
        <div className="rightSection">
          <div className="folderTitle">{folder.Name}</div>
          <div className="folderInfo">
            {`${countText}`}
            <div className="publishingState">&nbsp;{`• ${folder.Published ? 'Published in App' : 'Not Published in App'}`}</div>
          </div>
        </div>
      </div>
    );
  }

  renderFolder(folder, index) {
    const { editingFolder } = this.state;
    const isEditing = !_.isNil(editingFolder) && editingFolder.RowId === folder.RowId;
    const imageCount = folder.Images ? folder.Images.length : 0;
    const countText = `${imageCount} Image${imageCount > 0 ? 's' : ''}`;

    const editButton = (() => {
      if (!this.canManageLibrary() || this.isNewFolder(folder)) return null;
      return (
        <div
          className="editFolderContainer"
          onClick={(event) => (isEditing ? this.onDeleteFolder(event, folder) : this.onStartEditingFolder(event, folder))}
        >
          <FontAwesome
            name={isEditing ? 'trash' : 'pencil'}
            className={'editFolderIcon'}
            style={isEditing ? { color: COLOUR_TANGERINE } : undefined}
          />
        </div>
      );
    })();

    const folderInfo = (() => {
      if (isEditing) {
        return (
          <GenericInput
            id="addFolderInput"
            type="text"
            placeholder="Enter folder name here"
            value={editingFolder.Name}
            onChange={this.onChangeFolderName}
            inputStyle={styles.addFolderInputText}
          />
        );
      }
      return (
        <div>
          <div className="folderTitle">{folder.Name}</div>
          <div className="imageCount">{countText}</div>
        </div>
      );
    })();

    return (
      <div
        key={index}
        className="imageLibraryFolderContainer"
        style={{ zIndex: isEditing ? 25 : 0 }}
        onClick={isEditing ? null : this.onSelectFolder.bind(this, folder)}
      >
        <div className="topSection">
          {editButton}
          <div className="folderIcon"></div>
        </div>
        <div className="bottomSection">{folderInfo}</div>
        {isEditing && (
          <Button
            inline
            buttonType="primary"
            onClick={this.onEndEditingFolder}
            isActive={!_.isEmpty(editingFolder.Name)}
            className="doneButton"
          >
            Done
          </Button>
        )}
      </div>
    );
  }

  renderImage(image, index, allowDelete = true) {
    const deleteButton = (() => {
      const { selectedFolder } = this.state;
      if (
        !allowDelete ||
        (selectedFolder && !selectedFolder.Public && !this.canAddImage()) ||
        (selectedFolder && selectedFolder.Public && !this.canAddPublishingImage())
      )
        return null;

      return (
        <div className="deleteImageContainer" onClick={(event) => this.onDeleteImage(event, image)}>
          <FontAwesome name="trash" className="deleteImageIcon" />
        </div>
      );
    })();

    const imageDate = moment(image.date);
    const dateText = imageDate.format('MMMM D, YYYY');
    const timeText = imageDate.format('hh:mm A');
    return (
      <div key={index} className="imageLibraryImageContainer">
        <div className="topSection" style={{ ...this.getImageStyle(image.uri) }}>
          {deleteButton}
        </div>
        <div className="bottomSection">
          <div className="imageDate">{`Uploaded ${dateText} • ${timeText}`}</div>
          <div className="imageBy">{`By ${image.user.displayName}`}</div>
        </div>
      </div>
    );
  }

  renderPublishedImages() {
    const publishingFolder = this.props.imageLibrary.find((folder) => folder.Public);
    if (!publishingFolder) return null;

    const publishingImages = _.take(_.orderBy(publishingFolder.Images, 'date', 'desc'), 20);
    if (publishingImages.length === 0) return null;

    return (
      <div className="imageLibraryPublishedImages">
        <div className="title">LAST PUBLISHED IMAGES</div>
        <div className="publishedImages">
          {publishingImages.map((image, index) => {
            return this.renderImage(image, index, false);
          })}
        </div>
      </div>
    );
  }

  renderRight() {
    const { selectedFolder } = this.state;

    const title = (() => {
      if (selectedFolder) {
        return (
          <div className="libraryTitle">
            <span onClick={this.onSelectRoot} style={{ opacity: 0.5, cursor: 'pointer' }}>
              Folders
            </span>
            <FontAwesome name="chevron-left" className="folderSeparator" />
            <span>{selectedFolder.Name}</span>
          </div>
        );
      }
      return <div className="libraryTitle">Folders</div>;
    })();

    const search = (() => {
      if (selectedFolder) return null;

      return (
        <div className="searchFolder">
          <FontAwesome name="search" className="searchIcon" />
          <GenericInput
            id="searchFolderInput"
            type="text"
            placeholder="Search folder"
            onChange={this.onSearchChange}
            style={styles.searchContainer}
            textWrapperStyle={styles.searchInputWrapper}
          />
        </div>
      );
    })();

    const content = (() => {
      if (selectedFolder) {
        return selectedFolder.Images.map((image, index) => {
          return this.renderImage(image, index);
        });
      }

      const { searchText } = this.state;
      const { imageLibrary } = this.props;
      const folders = _.isEmpty(searchText)
        ? imageLibrary
        : imageLibrary.filter((folder) => folder.Name.toLowerCase().includes(searchText.toLowerCase()));
      return folders.map((folder, index) => {
        return folder.Public ? this.renderPublicFolder(folder, index) : this.renderFolder(folder, index);
      });
    })();

    return (
      <div className="imageLibrary">
        <div className="imageLibraryTitleSection">
          {title}
          {search}
        </div>
        {_.isNil(selectedFolder) && this.renderPublishedImages()}
        <div className="imageLibraryContents">{content}</div>
      </div>
    );
  }

  renderEditingOverlay() {
    return <div className="hub-editingOverlay" onClick={this.onCancelEditingFolder} />;
  }

  renderDeleteFolderConfirm() {
    return (
      <WarningPopup
        text={`Are you sure you want to delete ${this.state.deletingFolder.Name}?`}
        smallText
        buttons={[
          {
            type: 'primary',
            onClick: this.onConfirmDeleteFolder,
            text: 'Delete',
          },
          {
            type: 'outlined',
            onClick: this.onCancelDeleteFolder,
            text: 'Close',
          },
        ]}
      />
    );
  }

  renderDeleteImageConfirm() {
    return (
      <WarningPopup
        text={`Are you sure you want to delete the selected image?`}
        smallText
        buttons={[
          {
            type: 'primary',
            onClick: this.onConfirmDeleteImage,
            text: 'Delete',
          },
          {
            type: 'outlined',
            onClick: this.onCancelDeleteImage,
            text: 'Close',
          },
        ]}
      />
    );
  }

  renderImageUploader() {
    return (
      <Popup
        title="Upload Image"
        buttons={[
          {
            type: 'primary',
            onClick: this.onSaveUploadImage,
            isActive: this.state.imagesUploaded.length > 0,
            text: 'Save',
          },
          {
            type: 'tertiary',
            onClick: this.onCancelUploadImage,
            isActive: true,
            text: 'Cancel',
          },
        ]}
        minWidth="80vw"
        minHeight="80vh"
        hasPadding
      >
        <ImageInput ref="imageInput" multiple noMenu refreshCallback={this.onImagesUploaded} grid />
      </Popup>
    );
  }

  renderPublishedPopup() {
    return (
      <div className="imageLibraryPopupBackground">
        <div className="publishedPopup">
          <div className="topSection">
            <FontAwesome name="lock" className="publishedIcon" />
            <div className="publishedText">To activate this folder, you need to enable Image Gallery feature in your site’s app.</div>
          </div>
          <Button inline buttonType="primary" onClick={this.onConfirmPublished} isActive>
            Go to Feature Picker Page
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { editingFolder, deletingFolder, deletingImage, uploadingImage, showPublished } = this.state;

    const addButton = (() => {
      const { selectedFolder } = this.state;
      if (selectedFolder) {
        if ((!selectedFolder.Public && this.canAddImage()) || (selectedFolder.Public && this.canAddPublishingImage()))
          return <AddButton onClick={this.onUploadImage} text="UPLOAD IMAGE" />;
      } else {
        if (this.canManageLibrary()) return <AddButton onClick={this.onNewFolder} text="NEW FOLDER" />;
      }
      return null;
    })();

    return (
      <div className="hub-wrapperContainer">
        {this.renderLeftBar()}
        <div className="hub-headerContentWrapper">
          <Header>{addButton}</Header>
          <div className="hub-contentWrapper">{this.renderRight()}</div>
          {!_.isNil(editingFolder) && this.renderEditingOverlay()}
          {!_.isNil(deletingFolder) && this.renderDeleteFolderConfirm()}
          {!_.isNil(deletingImage) && this.renderDeleteImageConfirm()}
          {uploadingImage && this.renderImageUploader()}
          {showPublished && this.renderPublishedPopup()}
        </div>
      </div>
    );
  }
}

const styles = {
  sideBarTitleSection: {
    lineHeight: '50px',
    marginTop: 30,
    marginBottom: 30,
    paddingLeft: 24,
  },
  sideBarSection: {
    weight: '100%',
    minWidth: 200,
    padding: 32,
    paddingLeft: 24,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  spinner: {
    fontSize: 32,
    color: COLOUR_BRANDING_OFF,
  },
  addFolderInputText: {
    paddingBottom: '0px',
  },
  searchContainer: {
    marginLeft: '10px',
    marginBottom: 0,
  },
  searchInputWrapper: {
    marginBottom: 0,
  },
};

const mapStateToProps = (state) => {
  const { auth, media } = state;
  return {
    auth,
    imageLibrary: media.imageLibrary.filter((folder) => folder.RowId),
    loading: media.loading,
  };
};

export default connect(mapStateToProps, { imageLibraryUpdate, imageFolderUpdated, imageFolderRemoved })(ImageLibraryHub);
