import { getUrl } from './helper';
import { authedFunction, getRefreshTokenAWS } from '../session';
import { baseClient, baseStage, baseAPIUrl } from '../config';

export const deviceActions = {
  activateDevice: async (code, site) => {
    const refreshToken = await getRefreshTokenAWS();
    return authedFunction({
      method: 'POST',
      url: getUrl('devices', 'activateDevice'),
      data: {
        code,
        site,
        apiUrl: baseAPIUrl,
        client: baseClient,
        stage: baseStage,
        refreshToken,
      },
    });
  },
  getDevices: (site) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('devices', 'getDevices', { site }),
    });
  },
  changeView: (rowId, deviceId, site, view) => {
    // console.log('changeView', 'rowId', rowId, 'deviceId', deviceId, 'site', site, 'view', view);
    return authedFunction({
      method: 'POST',
      url: getUrl('devices', 'changeView'),
      data: {
        rowId,
        deviceId,
        site,
        view,
      },
    });
  },
  deleteDevice: (deviceId, site) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('devices', 'deleteDevice'),
      data: {
        deviceId,
        site,
      },
    });
  },
};
