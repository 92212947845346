import { SET_AUTH, CHANGE_SITE, UPDATE_PROFILE, LOGOUT } from './types';
import { getEnabledFeatures } from '../session';
import { authActions, typeActions } from '../webapi';

export const refreshAuthUser = (site) => {
  return (dispatch) => {
    const promises = [];
    promises.push(authActions.getUserSignin());
    promises.push(typeActions.getSite(site));
    promises.push(typeActions.getInterfaces(site));

    Promise.all(promises).then((res) => {
      const userRes = res[0].data;
      const siteSettingsRes = res[1].data;
      const interfaces = res[2].data;
      console.log(siteSettingsRes);
      dispatch({
        type: SET_AUTH,
        payload: {
          user: { ...userRes, uid: userRes.Id },
          features: getEnabledFeatures(siteSettingsRes, interfaces),
          hidden: [...(siteSettingsRes.Hidden || [])],
          siteSettings: { ...(siteSettingsRes.Settings || {}) },
          siteBranding: { ...(siteSettingsRes.Branding || {}) },
        },
      });
    });
  };
};

export const setAuth = (stuff) => {
  return {
    type: SET_AUTH,
    payload: stuff,
  };
};

export const setAuthUser = (auth, user, site) => {
  return (dispatch) => {
    if (!auth && !user && !site) {
      dispatch({
        type: LOGOUT,
        payload: null,
      });
    }
    dispatch({
      type: SET_AUTH,
      payload: {
        auth,
        user,
        site,
        originalSite: site,
      },
    });
  };
};

export const setRemember = (chooken) => {
  return {
    type: SET_AUTH,
    payload: {
      chooken,
    },
  };
};

export const setAuthLocation = (site) => {
  console.log('setting auth location');
  console.log(site);
  return {
    type: CHANGE_SITE,
    payload: {
      site,
    },
  };
};

export const updateProfile = (changes) => {
  return {
    type: UPDATE_PROFILE,
    payload: changes,
  };
};
