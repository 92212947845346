import _ from 'lodash';
import { IMAGE_LIBRARY_DASHBOARD_LOADING, IMAGE_LIBRARY_LOADED, IMAGE_FOLDER_UPDATED, IMAGE_FOLDER_REMOVED } from '../actions/types';

const INITIAL_STATE = {
  imageLibrary: [],
  loading: false,
};

const sorted = (library) => {
  return _.sortBy(library, ['Public', 'Name']);
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IMAGE_LIBRARY_DASHBOARD_LOADING:
      return { ...state, loading: true };
    case IMAGE_LIBRARY_LOADED:
      return { ...state, imageLibrary: sorted(action.payload), loading: false };
    case IMAGE_FOLDER_UPDATED:
      const updatedLibrary = [...state.imageLibrary];
      const updateIndex = updatedLibrary.findIndex((item) => item.RowId === action.payload.RowId);
      if (updateIndex > -1) {
        updatedLibrary[updateIndex] = action.payload;
      } else {
        updatedLibrary.push(action.payload);
      }
      return { ...state, imageLibrary: sorted(updatedLibrary) };
    case IMAGE_FOLDER_REMOVED:
      const removedLibrary = [...state.imageLibrary];
      const removeIndex = removedLibrary.findIndex((item) => item.RowId === action.payload.RowId);
      if (removeIndex > -1) removedLibrary.splice(removeIndex, 1);
      return { ...state, imageLibrary: sorted(removedLibrary) };
    default:
      return state;
  }
};
