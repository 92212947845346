import _ from 'lodash';
import Cookies from 'js-cookie';
import {
    ALERT_REMOVED,
    ALERTS_LOADED,
    ALERTS_UPDATED,
    ALERTS_DASHBOARD_LOADING
} from './types';
import { alertActions } from '../webapi';

export const alertsUpdate = (site, isdashboard) => {
    return (dispatch) => {
        if (isdashboard) {
            dispatch({
                type: ALERTS_DASHBOARD_LOADING
            });
        }
        alertActions.getAlerts(site).then(
            (res) => {
                const currentSite = Cookies.get('site');
                if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === currentSite) {
                    dispatch({
                        type: ALERTS_UPDATED,
                        payload: res.data
                    });
                } else {
                    dispatch({
                        type: ALERTS_UPDATED,
                        payload: []
                    });
                }
            }
        );
    }
};

export const alertsLoaded = (events) => {
    return {
        type: ALERTS_LOADED,
        payload: events
    };
};

export const removeAlert = (id) => {
    return {
        type: ALERT_REMOVED,
        payload: id
    };
};