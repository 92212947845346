import React, { Component } from 'react';
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';
import _ from 'lodash';
import { connect } from 'react-redux';
import { GenericInput, DropdownInput, Button } from '../../components';
import { isTheBest, getApiError } from '../../session';
import { automationActions } from '../../webapi';
import { renderTitle, renderDescription, renderTextStep } from './helper';

const SERVERLESS_FILE = '/serverless.yml';
const TYPE_ASSETS = 'assets';

class Repository extends Component {
  constructor(props) {
    super(props);
    const { activeWhiteLabel } = props;
    this.state = {
      stepIndex: 0,
      branch: activeWhiteLabel.ClientCode,
      configs: [],
      branchStates: {
        'minuss-aws': {
          creating: false,
          deleting: false,
          message: '',
        },
        'minuss-admin': {
          creating: false,
          deleting: false,
          message: '',
        },
        'minuss-expo': {
          creating: false,
          deleting: false,
          message: '',
        },
      },
      loadingConfigMessage: '',
      loading: false,
    };
    this.steps = [
      { label: 'Create New Environment', name: '1', render: this.renderStep1 },
      { label: 'Configure AWS', name: '2', render: this.renderStep2, validator: this.canMoveNext },
      { label: 'Configure Web', name: '3', render: this.renderStep3, validator: this.canMoveNext },
      { label: 'Configure App', name: '4', render: this.renderStep4, validator: this.canMoveNext },
    ];
    this.repositories = [
      {
        Title: 'Minuss-AWS',
        Key: 'minuss-aws',
      },
      {
        Title: 'Minuss-Admin',
        Key: 'minuss-admin',
      },
      {
        Title: 'Minuss-Expo',
        Key: 'minuss-expo',
      },
    ];
  }

  canMoveNext = () => {
    return !this.state.loading;
  };

  onMoveStep = (stepIndex) => {
    this.setState({ stepIndex }, this.onClearConfigs);
  };

  onBranchNameChanged = (event) => {
    this.setState({ branch: event.target.value });
  };

  onCreateBranch = async (repo) => {
    const { branch, branchStates } = this.state;
    const branchInfo = branchStates[repo];
    branchInfo.creating = true;
    branchInfo.message = `Creating ${branch} in ${repo}...`;
    this.setState({ branchStates }, async () => {
      try {
        await automationActions.createLiveEnvironment(repo, branch);
        branchInfo.creating = false;
        branchInfo.message = `${branch} in ${repo} created successfully`;
        this.setState({ branchStates });
      } catch (error) {
        const message = getApiError(error).message;
        branchInfo.creating = false;
        branchInfo.message = `Error: ${message}`;
        this.setState({ branchStates });
      }
    });
  };

  onCreateAllBranches = async () => {
    const repos = this.repositories.map((r) => r.Key);
    for (let index = 0; index < this.repositories.length; index++) {
      await this.onCreateBranch(repos[index]);
    }
  };

  onDeleteBranch = (repo) => {
    const { branch, branchStates } = this.state;
    if (!window.confirm(`Are you sure you want to delete ${branch} in ${repo}?`)) return;

    const branchInfo = branchStates[repo];
    branchInfo.deleting = true;
    branchInfo.message = `Deleting ${branch} in ${repo}...`;
    this.setState({ branchStates }, async () => {
      try {
        await automationActions.deleteLiveEnvironment(repo, branch);
        branchInfo.deleting = false;
        branchInfo.message = `${branch} in ${repo} deleted successfully`;
        this.setState({ branchStates });
      } catch (error) {
        const message = getApiError(error).message;
        branchInfo.deleting = false;
        branchInfo.message = `Error: ${message}`;
        this.setState({ branchStates });
      }
    });
  };

  onClearConfigs = () => {
    this.setState({ configs: [] });
  };

  onChangeEnvironmentConfig = (entityKey, key, value) => {
    const { configs } = this.state;
    const entity = configs.find((c) => c.key === entityKey);
    if (entity) {
      const config = entity.values.find((c) => c.key === key);
      if (config) {
        config.value = value;
        config.modified = true;
        this.setState({ configs });
      }
    }
  };

  onUseDefaults = () => {
    const { configs } = this.state;
    if (!configs) return;

    configs.forEach((type) => {
      type.values.forEach((config) => {
        if (config.default) this.onChangeEnvironmentConfig(type.key, config.key, config.default);
      });
    });
  };

  onSaveEnvironmentConfig = (repo) => {
    const { branch, configs } = this.state;
    this.setState({ loading: true, loadingConfigMessage: 'Updating environment configs...' }, async () => {
      try {
        // Get all modified configurations
        const modifiedConfigs = configs
          .map((c) => {
            return {
              ...c,
              values: c.values.filter((v) => v.modified),
            };
          })
          .filter((c) => c.values && c.values.length > 0);
        // console.log('modifiedConfigs', modifiedConfigs);

        // Save non-serverless configurations
        const normalConfigs = modifiedConfigs.filter((c) => !c.key.endsWith(SERVERLESS_FILE) && c.type !== TYPE_ASSETS);
        if (normalConfigs && normalConfigs.length > 0) {
          // console.log('saving normal configs...', repo, JSON.stringify(normalConfigs));
          await automationActions.updateEnvironmentConfigs(repo, branch, normalConfigs);
        }

        // For serverless files, we need to apply updates to all stacks
        const serverlessConfigs = modifiedConfigs.filter((c) => c.key.endsWith(SERVERLESS_FILE) && c.type !== TYPE_ASSETS);
        if (serverlessConfigs && serverlessConfigs.length > 0) {
          const { data: stacks } = await automationActions.getAwsCoreStacks(branch);
          // console.log(`saving serverless configs for ${stacks.length} stacks...`, repo, JSON.stringify(serverlessConfigs));
          for (let index = 0; index < stacks.length; index++) {
            const newPath = `${stacks[index]}${SERVERLESS_FILE}`;
            const newConfigs = [{ ...serverlessConfigs[0], key: newPath, path: newPath }];
            this.setState({ loadingConfigMessage: `Updating ${newPath}...` });
            await automationActions.updateEnvironmentConfigs(repo, branch, newConfigs);
          }
        }

        // Save assets
        const assetConfigs = modifiedConfigs.filter((c) => c.type === TYPE_ASSETS);
        if (assetConfigs && assetConfigs.length > 0) {
          for (let i = 0; i < assetConfigs.length; i++) {
            const config = assetConfigs[i];
            for (let j = 0; j < config.values.length; j++) {
              const asset = config.values[j];
              const newConfigs = [{ ...config, values: [{ ...asset }] }];
              // console.log('saving asset...', newConfigs);
              this.setState({ loadingConfigMessage: `Updating ${asset.label}...` });
              await automationActions.updateEnvironmentConfigs(repo, branch, newConfigs);
            }
          }
        }

        this.setState(
          { loading: false, loadingConfigMessage: `Environment ${branch} in ${repo} updated successfully` },
          this.loadEnvironmentConfigs,
        );
      } catch (error) {
        const message = getApiError(error).message;
        this.setState({ loading: false, loadingConfigMessage: `Error: ${message}` });
      }
    });
  };

  convertDropdownOptions = (values) => {
    return values.map((val) => {
      return { Title: val, Key: val };
    });
  };

  loadEnvironmentConfigs = () => {
    const { branch, stepIndex } = this.state;
    if (stepIndex === 0 || _.isEmpty(branch)) return;

    const repo = this.repositories[stepIndex - 1].Key;
    this.setState({ loading: true, loadingConfigMessage: `Loading ${branch} configurations...`, configs: [] }, async () => {
      try {
        const { data } = await automationActions.getEnvironmentConfigs(repo, branch);
        // console.log(branch, JSON.stringify(data));
        this.setState({ loading: false, loadingConfigMessage: '', configs: data });
      } catch (error) {
        const message = getApiError(error).message;
        this.setState({ loading: false, loadingConfigMessage: `Error: ${message}` });
      }
    });
  };

  getSecurableValue = (config) => {
    return config.value || (config.uuid && config.secured ? '******' : '');
  };

  renderConfig(entityKey, item) {
    const helpText = item.default ? `Default: ${item.default}` : '';
    return (
      <div key={item.key} className="flex flex-row">
        <div style={{ width: 200 }}>{item.label}</div>
        {item.values && item.values.length > 1 ? (
          <DropdownInput
            id={`value_${item.key}`}
            style={{ marginLeft: 10, marginBottom: 0, width: 400 }}
            value={item.value}
            options={this.convertDropdownOptions(item.values)}
            onSelect={(key) => this.onChangeEnvironmentConfig(entityKey, item.key, key)}
            help={helpText}
          />
        ) : (
          <GenericInput
            id={`value_${item.key}`}
            style={{ marginLeft: 10, marginBottom: 0, width: 400 }}
            type="text"
            value={this.getSecurableValue(item)}
            onChange={(event) => this.onChangeEnvironmentConfig(entityKey, item.key, event.target.value)}
            help={helpText}
          />
        )}
        {item.default ? (
          <Button
            className="marginLeft-10"
            textStyle={{ fontSize: 10 }}
            inline
            buttonType="primary"
            onClick={() => this.onChangeEnvironmentConfig(entityKey, item.key, item.default)}
            isActive
          >
            Set Default
          </Button>
        ) : null}
      </div>
    );
  }

  renderConfigs(repo) {
    const { configs, branch, loading, loadingConfigMessage } = this.state;
    const isRepoValid = !_.isEmpty(repo);
    const isEnvironmentValid = isRepoValid && !_.isEmpty(branch);
    const canSave = isEnvironmentValid && !loading;
    return (
      <div className="marginTop-10">
        {configs.map((type) => {
          return (
            <div key={type.key}>
              <p className="fontHeavy fontSize-16 text-dark">{type.key}</p>
              {type.values.map((config) => this.renderConfig(type.key, config))}
            </div>
          );
        })}
        <div className="flex flex-row flex-center marginTop-16">
          {_.isEmpty(configs) ? (
            <div>
              <Button inline buttonType="primary" onClick={this.loadEnvironmentConfigs} isActive={canSave}>
                Load Configurations
              </Button>
            </div>
          ) : (
            <div>
              <Button inline buttonType="primary" onClick={this.onUseDefaults} isActive={canSave}>
                Use Defaults
              </Button>
              <Button
                className="marginLeft-16"
                inline
                buttonType="primary"
                onClick={() => this.onSaveEnvironmentConfig(repo)}
                isActive={canSave}
              >
                Save Configurations
              </Button>
            </div>
          )}
          <div className="marginLeft-16">{loadingConfigMessage}</div>
        </div>
      </div>
    );
  }

  renderNewBranchInput() {
    const { branch } = this.state;
    const isBranchValid = !_.isEmpty(branch);

    return (
      <GenericInput
        className="marginTop-10"
        id="branch"
        style={{ width: 300 }}
        type="text"
        placeholder="Branch name"
        isValid={() => isBranchValid}
        value={branch}
        onChange={this.onBranchNameChanged}
        isRequired
      />
    );
  }

  renderCreateBranches() {
    const { branch, branchStates } = this.state;
    const isBranchValid = !_.isEmpty(branch);

    const branches = this.repositories.map((repo) => {
      return {
        key: repo.Key,
        ...branchStates[repo.Key],
      };
    });
    const isCreatingAny = branches.some((b) => b.creating);
    const isDeletingAny = branches.some((b) => b.deleting);
    return (
      <div className="marginTop-8">
        <div>
          <ul>
            {branches.map((b) => {
              return (
                <li key={b.key}>
                  <div className="flex flex-row flex-center marginBottom-16">
                    <div style={{ width: '50%' }}>
                      <b>{`${b.key}: `}</b>
                      {b.message || `${branch || '[NO BRANCH]'}`}
                    </div>
                    <Button
                      className="marginLeft-16"
                      inline
                      buttonType="primary"
                      onClick={() => this.onCreateBranch(b.key)}
                      isActive={isBranchValid && !b.creating && !b.deleting}
                    >
                      Create
                    </Button>
                    <Button
                      className="marginLeft-16"
                      inline
                      buttonType="primary"
                      onClick={() => this.onDeleteBranch(b.key)}
                      isActive={isBranchValid && !b.creating && !b.deleting}
                    >
                      Delete
                    </Button>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <Button inline buttonType="primary" onClick={this.onCreateAllBranches} isActive={isBranchValid && !isCreatingAny && !isDeletingAny}>
          Create All
        </Button>
      </div>
    );
  }

  renderStep1 = () => {
    return (
      <div>
        {renderTitle('Create/Configure Production Environment')}
        {renderDescription('In this step, we will create a set of new code repositories to deploy for the new environment.')}
        <ol>
          {renderTextStep(
            <div>
              Enter environment branch name (e.g. aveo)
              {this.renderNewBranchInput()}
            </div>,
          )}
          {renderTextStep(
            <div>
              Create new repository branches
              {this.renderCreateBranches()}
            </div>,
          )}
        </ol>
      </div>
    );
  };

  renderStep2 = () => {
    const repo = this.repositories[0].Key;
    return (
      <div>
        {renderTitle('Update AWS Configuration')}
        {renderDescription('In this step, we will configure AWS services for newly created environment.')}
        <ol>
          {renderTextStep(
            <div>
              Update configurations
              {this.renderConfigs(repo)}
            </div>,
          )}
        </ol>
      </div>
    );
  };

  renderStep3 = () => {
    const repo = this.repositories[1].Key;
    return (
      <div>
        {renderTitle('Update Web Configuration')}
        {renderDescription('In this step, we will configure web admin console for newly created environment.')}
        <ol>
          {renderTextStep(
            <div>
              Update configurations
              {this.renderConfigs(repo)}
            </div>,
          )}
        </ol>
      </div>
    );
  };

  renderStep4 = () => {
    const repo = this.repositories[2].Key;
    return (
      <div>
        {renderTitle('Update App Configuration')}
        {renderDescription('In this step, we will configure app for newly created environment.')}
        <ol>
          {renderTextStep(
            <div>
              Update configurations
              {this.renderConfigs(repo)}
            </div>,
          )}
        </ol>
      </div>
    );
  };

  render() {
    if (!isTheBest(this.props.auth, true)) return null;

    const { stepIndex } = this.state;
    return (
      <div className="flex-1 automation">
        <StepProgressBar
          startingStep={stepIndex}
          progressClass="progressBar"
          primaryBtnClass="primaryBtn"
          secondaryBtnClass="secondaryBtn"
          submitBtnName="Done"
          onPrevious={() => this.onMoveStep(stepIndex - 1)}
          onNext={() => this.onMoveStep(stepIndex + 1)}
          onSubmit={() => {
            if (this.canMoveNext()) return this.props.onDone ? this.props.onDone() : null;
          }}
          steps={this.steps}
        />
        {this.steps[stepIndex].render()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, automation } = state;
  return {
    auth,
    activeWhiteLabel: automation.active,
  };
};

export default connect(mapStateToProps, {})(Repository);
