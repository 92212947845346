import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { Header, AddButton } from '../../components';
import { validateAccess } from '../../session';
import { facilitiesLoaded, setAuth } from '../../actions';
import { facilityActions } from '../../webapi';
import ListFacilities from './ListFacilities';
import { COLOUR_BRANDING_OFF } from '../../js';
import { CONST_STRINGS } from '../../config';

class FacilityHub extends Component {
  state = {
    selectedSection: 'all',
    loadingFacilities: false,
    facilities: [],
  };

  UNSAFE_componentWillMount() {
    this.updateProps(this.props);
    this.loadFacilities();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps);
  }

  updateProps(props) {
    const facilities = [];

    props.facilities.forEach((ev) => {
      if (ev != null && !ev.Deleted) facilities.push(ev);
    });

    this.setState({ facilities });
  }

  loadFacilities = async () => {
    this.setState({ loadingFacilities: true });

    try {
      const res = await facilityActions.getFacilities(this.props.auth.site);
      if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
        this.props.facilitiesLoaded(res.data);
      }
    } catch (error) {
      alert('Something went wrong with the request. Please try again.');
    } finally {
      this.setState({ loadingFacilities: false });
    }
  };

  addNew() {
    if (validateAccess(this.props.auth.site, 'facilities', this.props.auth)) {
      this.props.history.push(`/facilities/facility`);
    }
  }

  canAddNew(isClass) {
    if (validateAccess(this.props.auth.site, 'facilities', this.props.auth)) {
      return isClass ? '' : true;
    }
    return isClass ? ' hub-sideContent-topButton--hide' : false;
  }

  getSideBarSectionColour(id) {
    return this.state.selectedSection === id ? '#fff' : 'transparent';
  }

  renderStats(stat, loading) {
    if (loading) return <FontAwesome style={styles.spinner} name="spinner fa-pulse fa-fw" />;
    return stat;
  }

  renderLeftBar() {
    return (
      <div className="hub-sideContent" style={{ minWidth: 200 }}>
        {/* Top Add Button */}
        <div className="hub-sideContent-topButton" />
        <div style={{ paddingLeft: 15, width: '100%' }}>
          {/* Title */}
          <div className="fontMedium fontSize-36 text-dark" style={styles.sideBarTitleSection}>
            {CONST_STRINGS.FACILITIES}
          </div>
          {/* Content */}
          {/* All Facilities */}
          <div
            onClick={() => {
              this.setState({ selectedSection: 'all' });
            }}
            style={{ ...styles.sideBarSection, backgroundColor: this.getSideBarSectionColour('all') }}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats(this.state.facilities.length, this.state.loadingFacilities)}
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">Facilities</div>
          </div>
        </div>
      </div>
    );
  }

  renderRight() {
    return <ListFacilities />;
  }

  render() {
    return (
      <div className="hub-wrapperContainer">
        {this.renderLeftBar()}
        <div className="hub-headerContentWrapper">
          <Header>
            {this.canAddNew() && <AddButton onClick={this.addNew.bind(this)} text={`NEW ${CONST_STRINGS.FACILITY.toUpperCase()}`} />}
          </Header>
          <div className="hub-contentWrapper">{this.renderRight()}</div>
        </div>
      </div>
    );
  }
}

const styles = {
  sideBarTitleSection: {
    lineHeight: '50px',
    marginTop: 30,
    marginBottom: 30,
    paddingLeft: 24,
  },
  sideBarSection: {
    weight: '100%',
    minWidth: 200,
    padding: 32,
    paddingLeft: 24,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  spinner: {
    fontSize: 32,
    color: COLOUR_BRANDING_OFF,
  },
};

const mapStateToProps = (state) => {
  const { facilities } = state.facilities;
  const { auth } = state;
  return {
    facilities,
    auth,
  };
};

export default connect(mapStateToProps, { facilitiesLoaded, setAuth })(FacilityHub);
