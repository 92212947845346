import axios from 'axios';
import { getUrl } from './helper';
import { authedFunction } from '../session';

export const paymentActions = {
  createSession: (auth) => {
    const request = {
      method: 'POST',
      url: getUrl('wallet', 'createSession'),
      headers: {},
    };
    if (auth) {
      request.headers.authkey = auth;
      return axios(request);
    }
    return authedFunction(request);
  },
  setDefaultPaymentMethod: (paymentMethodId, customerId, auth) => {
    const request = {
      method: 'POST',
      url: getUrl('wallet', 'setDefaultPaymentMethod'),
      data: {
        paymentMethodId,
        customerId,
      },
      headers: {},
    };
    if (auth) {
      request.headers.authkey = auth;
      return axios(request);
    }
    return authedFunction(request);
  },
  getEventSlotTransactions: (site, eventId, slotId, auth) => {
    const query = {
      site,
      productId: eventId,
      itemId: slotId,
    };
    const request = {
      method: 'GET',
      url: getUrl('wallet', 'getProductItemTransactions', query),
      headers: {},
    };
    if (auth) {
      request.headers.authkey = auth;
      return axios(request);
    }
    return authedFunction(request);
  },
  savePaymentToken: (sessionId, token, card, auth) => {
    const request = {
      method: 'POST',
      url: getUrl('wallet', 'savePaymentToken'),
      data: {
        sessionId,
        token,
        card,
      },
      headers: {},
    };
    if (auth) {
      request.headers.authkey = auth;
      return axios(request);
    }
    return authedFunction(request);
  },
  refund: (site, userId, productId, itemId, tickets, transactionId) => {
    const request = {
      method: 'POST',
      url: getUrl('wallet', 'refund'),
      data: {
        site,
        userId,
        productId,
        itemId,
        tickets,
        transactionId,
      },
      headers: {},
    };
    return authedFunction(request);
  },
  getAvailablePlans: (auth) => {
    const request = {
      method: 'GET',
      url: getUrl('wallet', 'getAvailablePlans'),
      headers: {},
    };
    if (auth) {
      request.headers.authkey = auth;
      return axios(request);
    }
    return authedFunction(request);
  },
  getCurrentPlans: (auth) => {
    const request = {
      method: 'GET',
      url: getUrl('wallet', 'getCurrentPlans'),
      headers: {},
    };
    if (auth) {
      request.headers.authkey = auth;
      return axios(request);
    }
    return authedFunction(request);
  },
  savePlans: (plans, auth) => {
    const request = {
      method: 'POST',
      url: getUrl('wallet', 'savePlans'),
      data: { plans },
      headers: {},
    };
    if (auth) {
      request.headers.authkey = auth;
      return axios(request);
    }
    return authedFunction(request);
  },
  getPaymentDetails: (id, auth) => {
    const request = {
      method: 'GET',
      url: getUrl('wallet', `getPaymentDetails/${id}`),
      headers: {},
    };
    if (auth) {
      request.headers.authkey = auth;
      return axios(request);
    }
    return authedFunction(request);
  },
  getInvoices: (auth) => {
    const request = {
      method: 'GET',
      url: getUrl('wallet', 'getInvoices'),
      headers: {},
    };
    if (auth) {
      request.headers.authkey = auth;
      return axios(request);
    }
    return authedFunction(request);
  },
  createConnectedAccount: (site) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('wallet', 'createConnectedAccount'),
      data: { site },
    });
  },
  deleteConnectedAccount: (site, accountId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('wallet', 'deleteConnectedAccount'),
      data: { site, accountId },
    });
  },
  getConnectedAccounts: (site) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('wallet', 'getConnectedAccounts', { site }),
    });
  },
  getOnboardingLink: (site, accountId) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('wallet', 'getOnboardingLink', { site, accountId }),
    });
  },
  getConnectedAccountLogin: (site, accountId) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('wallet', 'getConnectedAccountLogin', { site, accountId }),
    });
  },
  getSiteTransactions: (site, minTime, maxTime) => {
    const query = { site };
    if (minTime) query.minTime = minTime;
    if (maxTime) query.maxTime = maxTime;
    return authedFunction({
      method: 'GET',
      url: getUrl('wallet', 'getSiteTransactions', query),
    });
  },
};
