import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { withRouter } from 'react-router';
import { infoPagesLoaded, removeInfoPage } from '../../actions';
import { COLOUR_BRANDING_OFF } from '../../js';
import { checkLoggedIn, validateAccess } from '../../session';
import { infoActions } from '../../webapi';
import { CONST_STRINGS } from '../../config';
import { AddButton } from '../../components';
import { Text } from '../../components/text';

class InfoPages extends Component {
  state = {
    infoPageList: [],
    showCompleted: false,
    sortColumn: 'order',
    sortDesc: false,
    savingOrder: false,
    loading: true,
  };

  UNSAFE_componentWillMount() {
    this.updateProps(this.props);
    checkLoggedIn(this, this.props.auth);
  }

  componentDidMount() {
    this.getData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps);
  }

  updateProps(props) {
    console.log('updating props');
    console.log(props.infoPages);
    this.setState({
      infoPageList: props.infoPages,
    });
  }

  moveDown(ev) {
    if (this.state.savingOrder) {
      return;
    }
    let source = _.sortBy(this.state.infoPageList, 'order');
    source = _.filter(source, (ev) => {
      if (!ev) {
        return false;
      }
      return true;
    });
    const index = source.indexOf(ev);
    if (index === source.length - 1) {
      return;
    }
    source.splice(index, 1);
    source.splice(index + 1, 0, ev);

    this.saveOrder(source);
  }

  moveUp(ev) {
    if (this.state.savingOrder) {
      return;
    }
    let source = _.sortBy(this.state.infoPageList, 'order');
    source = _.filter(source, (ev) => {
      if (!ev) {
        return false;
      }
      return true;
    });
    const index = source.indexOf(ev);
    if (index === 0) {
      return;
    }
    source.splice(index, 1);
    source.splice(index - 1, 0, ev);

    this.saveOrder(source);
  }

  saveOrder(source) {
    const changes = [];
    source.forEach((item, index) => {
      if (item.order !== index) {
        changes.push({
          id: item.RowId,
          order: index,
        });
        item.order = index;
      }
    });
    this.setState({
      savingOrder: true,
    });
    infoActions.changeInfoPageOrder(this.props.auth.site, changes).then((res) => {
      console.log('success');
      this.setState({
        savingOrder: false,
      });
    });
  }

  getData() {
    infoActions.getInfoPages(this.props.auth.site).then((res) => {
      if (res.data != null) {
        this.props.infoPagesLoaded(res.data);
      }
      this.setState({ loading: false });
    });
  }

  getUpStyle(ev) {
    if (this.state.savingOrder || ev.order === 0) {
      return { visibility: 'hidden' };
    }
    return null;
  }

  getDownStyle(ev) {
    if (this.state.savingOrder || ev.order === this.state.infoPageList.length - 1) {
      return { visibility: 'hidden' };
    }
    return null;
  }

  removeInfoPage(ev) {
    if (window.confirm(`Are you sure you want to delete ${ev.title}?`)) {
      infoActions
        .deleteInfoPage(this.props.auth.site, ev.RowId)
        .then((res) => {
          this.getData();
          // action
          console.log('success');
          this.props.removeInfoPage(ev.RowId);
        })
        .catch((res) => {
          alert('Something went wrong with the request. Please try again.');
        });
    }
  }

  renderList() {
    let source = _.sortBy(this.state.infoPageList, this.state.sortColumn);
    source = _.filter(source, (ev) => {
      if (!ev) {
        return false;
      }
      return true;
    });

    return source.map((ev, index) => {
      if (ev != null) {
        return (
          <tr key={index}>
            <td className="table-TitleColumn">
              <Link to={`/info/infopage/${ev.RowId}`}>{ev.title}</Link>
            </td>
            {validateAccess(this.props.auth.site, 'infoPages', this.props.auth) && (
              <td className="table-options">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <a
                    onClick={() => {
                      this.moveUp(ev);
                    }}
                    style={this.getUpStyle(ev)}
                  >
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8, cursor: 'pointer' }} name="arrow-up" />
                  </a>
                  <a
                    onClick={() => {
                      this.moveDown(ev);
                    }}
                    style={this.getDownStyle(ev)}
                  >
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8, cursor: 'pointer' }} name="arrow-down" />
                  </a>
                  <Link to={`/info/infopage/${ev.RowId}`}>
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 12, cursor: 'pointer' }} name="pencil" />
                  </Link>
                  <a
                    onClick={() => {
                      this.removeInfoPage(ev);
                    }}
                  >
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8, cursor: 'pointer' }} name="minus-circle" />
                  </a>
                </div>
              </td>
            )}
          </tr>
        );
      }
      return null;
    });
  }

  addNew() {
    this.props.history.push(`/info/infopage`);
  }

  renderView() {
    return (
      <Table className="plussTable" striped bordered condensed hover style={{ minWidth: '100%' }}>
        <thead>
          <tr>
            <th>Title</th>
            <th style={{ width: 140 }} />
          </tr>
        </thead>
        <tbody>{this.renderList()}</tbody>
      </Table>
    );
  }

  renderEmpty() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 32 }}>
        <div className="emptyState" />
        <div className="marginTop-32" style={{ maxWidth: 500, textAlign: 'center' }}>
          <Text type="h3">There are no information pages</Text>
        </div>
      </div>
    );
  }

  renderContent() {
    if (_.isEmpty(this.state.infoPageList)) {
      return this.renderEmpty();
    }
    return this.renderView();
  }

  render() {
    if (this.state.infoPageList.length === 0 && this.state.loading) {
      return (
        <div style={{ minWidth: '100%' }}>
          <div className="padding-60 paddingVertical-40" style={{ textAlign: 'center' }}>
            <FontAwesome style={{ fontSize: 30, color: COLOUR_BRANDING_OFF }} name="spinner fa-pulse fa-fw" />
          </div>
        </div>
      );
    }
    return (
      <div style={{ minWidth: '100%' }}>
        <AddButton onClick={this.addNew.bind(this)} text={`NEW ${CONST_STRINGS.FAQ_ENTRY.toUpperCase()}`} style={{ marginBottom: 32 }} />
        {this.renderContent()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { infoPages } = state.infoPages;
  const { auth } = state;
  return { infoPages, auth };
};

export default connect(mapStateToProps, { infoPagesLoaded, removeInfoPage })(withRouter(InfoPages));
