import React, { Component } from 'react';
import { Table, Form, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { Storage } from 'aws-amplify';
import SignatureCanvas from 'react-signature-canvas';
import { Link } from 'react-router-dom';
import { visitorActions } from '../../webapi';
import { Button, CheckBox, InputGroup, AddButton } from '../../components';
import { generateImageName } from '../../helper';
import { baseStorageUrl } from '../../config';

class ContractorList extends Component {
    state = {
        sortColumn: 'StartTime', // column to sort by
        sortDesc: false, // if true, sort descending rather than ascending
        now: moment().startOf('d'),
        onlySignedIns: true,

        showNewVisitor: false,
        signOutShowing: false,

        date: moment().format('YYYY-MM-DD'),
        timeIn: moment().format('HH:mm'),

        timeOut: moment().format('HH:mm'),
        workDone: false,

        showWarnings: false,
        showPast: false,

        workerName: '',
        compName: '',
        workDes: '',
        phone: '',

        visitors: {},

        newForm: {
            workerName: '',
            compName: '',
            workDes: '',
            phone: '',
            permits: false,
            safety: false,
            conId: false,
            terms: false,
            hotWorksPermit: false,
            heightsPermit: false,
            confinedSpacePermit: false,
            firePermit: false,
            hazardPermit: false,
        },

        selectedEntry: {},

        width: 0, height: 0,

        permits: false,
        safety: false,
        conId: false,
        terms: false,
        hotWorksPermit: false,
        heightsPermit: false,
        confinedSpacePermit: false,
        firePermit: false,
        hazardPermit: false,

        workCompleted: false,

        search: '',
    }


    UNSAFE_componentWillMount() {
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        //docHeight = $(document).height();

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.getData();
    }

    getData() {
        visitorActions.getContractors(this.props.auth.site)
            .then((res) => {
                console.log(res.data);
                this.setState({
                    visitors: res.data
                });
            });
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    sigPad = {};
    sigPadEnd = {};

    clear = () => {
        this.sigPad.clear()
    }

    clearEnd = () => {
        this.sigPadEnd.clear()
    }

    handleCheckChange(event) {
        var stateChange = {};
        stateChange[event.target.getAttribute('id')] = event.target.checked;
        this.setState(stateChange);
    }

    showNewVisitor(byPass) {
        if (this.state.submitting) {
            return;
        }

        this.setState({ showNewVisitor: !this.state.showNewVisitor, date: moment().format('YYYY-MM-DD'), timeIn: moment().format('HH:mm'), signatureEmpty: false })
    }

    handleChange(event) {
        var stateChange = {};
        stateChange[event.target.getAttribute('id')] = event.target.value;
        this.setState(stateChange);
    }
    getTime(input) {
        var timeSplit = input.split(":");
        return {
            hours: timeSplit[0],
            minutes: timeSplit[1]
        };
    }

    renderSuccess(newStates) {
        this.setState({ success: true });
        setTimeout(() => {
            this.setState(newStates);
        }, 2000);
    }

    handleEndSubmit() {
        if (!this.validateDateTime(this.state.timeOut)) {
            return;
        }
        if (this.sigPadEnd.isEmpty()) {
            this.setState({ signatureEmpty: true });
            return
        }
        this.setState({ submitting: true });

        const TimeOut = new Date(this.state.selectedEntry.Date.trim());
        var time = this.getTime(this.state.timeOut.trim());
        TimeOut.setHours(time.hours);
        TimeOut.setMinutes(time.minutes);

        const entry = {
            RowId: this.state.selectedEntry.RowId,
            Id: this.state.selectedEntry.Id,
            TimeOut: moment(TimeOut).toISOString(),
            Completed: this.state.workCompleted,
            Site: this.props.auth.site
        };

        const signImOut = this.sigPadEnd.getTrimmedCanvas()
            .toDataURL('image/png');
        const imageData = new Buffer(signImOut.replace(/^data:image\/\w+;base64,/, ""), 'base64');

        this.sigPadEnd.off();

        const imgName = generateImageName('signature');

        var self = this;

        Storage.put(`images/${imgName}.png`, imageData, {
            contentType: 'image/png',
            level: 'public'
        }).then((res) => {
            entry.TimeOutSig = res.key;

            visitorActions.saveContractorEntry(entry, this.props.auth.site)
                .then((res) => {
                    self.sigPadEnd.on();
                    var newStates = { success: false, submitting: false, showNewVisitor: false, signOutShowing: false, selectedEntry: {} };
                    self.renderSuccess(newStates);
                    self.getData();
                }).catch((res) => {
                    self.sigPadEnd.on();
                    self.setState({ submitting: false });
                    alert("Something went wrong with the request. Please try again.");
                });
        }).catch((uploadErrorRes) => {
            self.sigPadEnd.on();
            self.setState({ submitting: false });
        });
    }

    handleSubmit() {
        if (!this.validateForm()) {
            this.setState({
                showWarnings: true
            });
            return;
        }
        if (this.sigPad.isEmpty()) {
            this.setState({ signatureEmpty: true });
            return
        }

        this.setState({ submitting: true, signatureEmpty: false });

        /* const repTime = new Date(this.state.date.trim());
        var time = this.getTime(this.state.timeIn.trim());
        repTime.setHours(time.hours);
        repTime.setMinutes(time.minutes); */

        const TimeIn = new Date(this.state.date.trim());
        var time = this.getTime(this.state.timeIn.trim());
        TimeIn.setHours(time.hours);
        TimeIn.setMinutes(time.minutes);

        const entry = {
            Id: 100000 + this.state.visitors.length + 1,
            Date: moment(this.state.date).toISOString(),
            TimeIn: moment(TimeIn).toISOString(),
            EntryTime: moment().toISOString(),
            WorkerName: this.state.workerName,
            CompName: this.state.compName,
            Phone: this.state.phone,
            WorkDes: this.state.workDes,
            ConId: this.state.conId,
            Safety: this.state.safety,
            Permits: this.state.permits,
            PermitsOwned: {
                HotWorks: this.state.hotWorksPermit,
                Heights: this.state.heightsPermit,
                ConfinedSpace: this.state.confinedSpacePermit,
                Fire: this.state.firePermit,
                Hazardous: this.state.hazardPermit
            },
            Terms: this.state.terms,
            Site: this.props.auth.site
        };

        const signIm = this.sigPad.getTrimmedCanvas()
            .toDataURL('image/png');
        const imageData = new Buffer(signIm.replace(/^data:image\/\w+;base64,/, ""), 'base64')

        this.sigPad.off();

        const imgName = generateImageName('signature');

        var self = this;

        Storage.put(`images/${imgName}.png`, imageData, {
            contentType: 'image/png',
            level: 'public'
        }).then((res) => {
            console.log('success');
            console.log(res);
            entry.TimeInSig = res.key;

            visitorActions.saveContractorEntry(entry, this.props.auth.site)
                .then((res) => {
                    self.sigPad.on();
                    var newStates = { ...self.state.newForm, success: false, submitting: false, showNewVisitor: false };
                    self.renderSuccess(newStates);
                    self.getData();
                }).catch((res) => {
                    self.sigPad.on();
                    self.setState({ submitting: false });
                    alert("Something went wrong with the request. Please try again.")
                });
        }).catch((uploadErrorRes) => {
            self.sigPad.on();
            self.setState({ submitting: false });
        });
    }

    validateForm() {
        if (!this.validateDateTime(this.state.timeIn) || !this.validateDateTime(this.state.date)) {
            return false
        }

        if (this.isEmpty(this.state.workerName) || this.isEmpty(this.state.compName) || this.isEmpty(this.state.phone)) {
            return false
        }

        if (!this.state.terms || !this.state.conId) {
            return false;
        }

        return true;
    }

    renderSubmitEnd() {
        if (this.state.submitting) {
            return <Button buttonType='secondary'>Saving...</Button>;
        }
        return (
            <Button
                inline
                buttonType='primary'
                onClick={this.handleEndSubmit.bind(this)}
                isActive={this.validateDateTime(this.state.timeOut)}
            >
                Save
             </Button>
        );
    }

    renderSubmit() {
        if (this.state.submitting) {
            return <Button buttonType='secondary'>Saving...</Button>;
        }
        return (
            <Button
                inline
                buttonType='primary'
                onClick={this.handleSubmit.bind(this)}
                isActive={this.validateForm()}
            >
                Save
             </Button>
        );
    }

    validateDateTime(e) {
        return e.trim() !== '';
    }

    isEmpty(text) {
        return text === '';
    }


    renderForm() {
        if (this.state.success) {
            return (
                <div>
                    <div style={{ textAlign: 'center', fontSize: 30, fontWeight: 'bold' }}>
                        Contractor Logged Successfully
                </div>
                </div>
            );
        }
        if (this.state.showNewVisitor) {
            return this.renderNewVisitorForm();
        }
        return this.renderSignOut();
    }

    renderNewVisitorForm() {
        return (
            <div style={{}}>
                <div style={{ textAlign: 'center', marginBottom: 10, fontSize: 30, fontWeight: 'bold' }}>
                    New Contractor Sign In
                </div>
                <div>
                    <Form>
                        <div style={{ marginBottom: 10, fontSize: 16 }}>
                            <div style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 5 }}>
                                Job Id
                            </div>
                            <div>{100000 + this.state.visitors.length + 1}</div>
                        </div>
                        {/*  <Row className="show-grid">
                            <Col xs={6}>
                                <InputGroup
                                    id={`jobId`}
                                    type="text"
                                    label="Job ID"
                                    value={this.state.jobId}
                                    placeholder="1118563"
                                    showError={this.state.showWarnings && this.isEmpty(this.state.jobId)}
                                    onChange={(e) => this.handleChange(e)}
                                />
                            </Col>
                        </Row> */}

                        <Row className="show-grid">
                            <Col xs={6}>
                                {/* menu start date */}
                                <InputGroup
                                    id={`date`}
                                    type="date"
                                    label="Date"
                                    value={this.state.date}
                                    showError={this.state.showWarnings && !this.validateDateTime(this.state.date)}
                                    onChange={(e) => this.handleChange(e)}
                                />
                            </Col>
                            <Col xs={6}>
                                <InputGroup
                                    id={`timeIn`}
                                    type="time"
                                    label="Time In"
                                    value={this.state.timeIn}
                                    showError={this.state.showWarnings && !this.validateDateTime(this.state.timeIn)}
                                    onChange={(e) => this.handleChange(e)}
                                />
                            </Col>
                        </Row>

                        <Row className="show-grid">
                            <Col xs={6}>
                                <InputGroup
                                    id="workerName"
                                    type="text"
                                    label="Workers name"
                                    placeholder="Jamie Sogan"
                                    value={this.state.workerName}
                                    showError={this.state.showWarnings && this.isEmpty(this.state.workerName)}
                                    onChange={(e) => this.handleChange(e)}
                                />
                            </Col>
                            <Col xs={6}>
                                <InputGroup
                                    id="compName"
                                    type="text"
                                    label="Company name"
                                    placeholder="Jamie's Electrical"
                                    value={this.state.compName}
                                    showError={this.state.showWarnings && this.isEmpty(this.state.compName)}
                                    onChange={(e) => this.handleChange(e)}
                                />
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col xs={6}>
                                <InputGroup
                                    id="phone"
                                    type="text"
                                    label="Contact No."
                                    placeholder="04XX XXX XXX"
                                    value={this.state.phone}
                                    showError={this.state.showWarnings && this.isEmpty(this.state.phone)}
                                    onChange={(e) => this.handleChange(e)}
                                />
                                <InputGroup
                                    id="workDes"
                                    type="text"
                                    label="Brief description of work"
                                    placeholder="Replacing Electrical Stuff"
                                    value={this.state.workDes}
                                    showError={this.state.showWarnings && this.isEmpty(this.state.workDes)}
                                    onChange={(e) => this.handleChange(e)}
                                />
                            </Col>
                            <Col xs={6}>
                                <div style={{ fontSize: 14, marginBottom: 5 }}>
                                    <p style={{ fontWeight: 'bold' }}>Permits</p>
                                    <p>Check applicable permits</p>
                                </div>
                                <CheckBox
                                    id='hotWorksPermit'
                                    label='Hot Works Permit'
                                    isActive={this.state.hotWorksPermit}
                                    onChange={() => this.setState({ hotWorksPermit: !this.state.hotWorksPermit })}
                                />
                                <CheckBox
                                    id='heightsPermit'
                                    label='Working at Heights Permit'
                                    isActive={this.state.heightsPermit}
                                    onChange={() => this.setState({ heightsPermit: !this.state.heightsPermit })}
                                />
                                <CheckBox
                                    id='confinedSpacePermit'
                                    label='Confined Space Permit'
                                    isActive={this.state.confinedSpacePermit}
                                    onChange={() => this.setState({ confinedSpacePermit: !this.state.confinedSpacePermit })}
                                />
                                <CheckBox
                                    id='firePermit'
                                    label='Fire Impairment Permit'
                                    isActive={this.state.firePermit}
                                    onChange={() => this.setState({ firePermit: !this.state.firePermit })}
                                />
                                <CheckBox
                                    id='hazardPermit'
                                    label='Other Hazardous Work Permit'
                                    isActive={this.state.hazardPermit}
                                    onChange={() => this.setState({ hazardPermit: !this.state.hazardPermit })}
                                />
                            </Col>
                        </Row>
                    </Form>
                    <div>

                        <CheckBox
                            id='conId'
                            label='Id Checked'
                            isActive={this.state.conId}
                            onChange={() => this.setState({ conId: !this.state.conId })}
                            showWarning={this.state.showWarnings && !this.state.conId}
                        />
                        <CheckBox
                            id='safety'
                            label='Safety Inducted'
                            isActive={this.state.safety}
                            onChange={() => this.setState({ safety: !this.state.safety })}
                        />
                        <CheckBox
                            id='terms'
                            label='Has Read '
                            isActive={this.state.terms}
                            onChange={() => this.setState({ terms: !this.state.terms })}
                            style={{ marginTop: 16 }}
                            showWarning={this.state.showWarnings && !this.state.terms}
                        >
                            <span className="actionText" style={{ marginLeft: 5, lineHeight: '20px' }}>
                                <Link to="/contractorTerms" target="_blank">
                                    Terms of Entry.
                                </Link>
                            </span>
                        </CheckBox>
                    </div>

                    <div style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 5 }}>Signature</div>
                    {this.state.signatureEmpty && <div style={{ color: 'red' }}>Please provide a signature</div>}
                    <div style={{ backgroundColor: 'gray', padding: 5, alignContent: 'center', justifyContent: 'center', display: 'flex' }}>
                        <SignatureCanvas penColor='black'
                            canvasProps={{ width: 400, height: 150, className: 'sigCanvas' }}
                            backgroundColor={'#fff'}
                            ref={(ref) => { this.sigPad = ref }}
                        />
                    </div>
                    <Button style={{ marginTop: 5 }} isActive buttonType='secondary' onClick={() => this.clear()}>Clear Signature</Button>
                </div>
                <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', paddingTop: 15, borderTop: '1px solid #ccc' }}>
                    <Button
                        inline
                        buttonType='tertiary'
                        onClick={() => { this.showNewVisitor(); }}
                        isActive
                        style={{ marginRight: 16 }}
                    >
                        Cancel
                    </Button>
                    {this.renderSubmit()}
                </div>
            </div >
        )
    }

    renderSignOut() {
        return (
            <div style={{}}>
                <div style={{ textAlign: 'center', marginBottom: 10, fontSize: 24, fontWeight: 'bold' }}>
                    Sign Out
                </div>
                <div>
                    <div style={{ fontSize: 20, marginBottom: 10 }}>
                        {this.state.selectedEntry.WorkerName}
                        <span><i> - {this.state.selectedEntry.CompName}</i></span>
                    </div>
                    <div>
                        <span style={{ fontWeight: 'bold' }}> Job Number: </span> {this.state.selectedEntry.Id}
                    </div>
                    <div>
                        {moment.utc(this.state.selectedEntry.Date).local().format('D MMM YY')} -
                  {moment.utc(this.state.selectedEntry.TimeIn).local().format('h:mma')}
                    </div>
                </div>
                <div style={{ marginTop: 20 }}>
                    <Form>
                        <Row className="show-grid">
                            <Col xs={6}>
                                <InputGroup
                                    id={`timeOut`}
                                    type="time"
                                    label="Departing Time"
                                    value={this.state.timeOut}
                                    showError={this.state.showWarnings && !this.validateDateTime(this.state.timeOut)}
                                    onChange={(e) => this.handleChange(e)}
                                />
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col xs={6}>
                                <CheckBox
                                    id='workCompleted'
                                    label='Work Completed'
                                    isActive={this.state.workCompleted}
                                    onChange={() => this.setState({ workCompleted: !this.state.workCompleted })}
                                />
                            </Col>
                        </Row>
                    </Form>
                    <div style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 5 }}>Signature</div>
                    {this.state.signatureEmpty && <div style={{ color: 'red' }}>Please provide a signature</div>}
                    <div style={{ backgroundColor: 'gray', padding: 5, alignContent: 'center', justifyContent: 'center', display: 'flex' }}>
                        <SignatureCanvas penColor='black'
                            canvasProps={{ width: 400, height: 150, className: 'sigCanvas' }}
                            backgroundColor={'#fff'}
                            ref={(ref) => { this.sigPadEnd = ref }}
                        />
                    </div>
                    <Button style={{ marginTop: 5 }} isActive buttonType='secondary' onClick={() => this.clearEnd()}>Clear Signature</Button>
                </div>
                <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', paddingTop: 15, borderTop: '1px solid #ccc' }}>
                    <Button
                        inline
                        buttonType='tertiary'
                        onClick={() => { this.closeSignOut(); }}
                        isActive
                        style={{ marginRight: 16 }}
                    >
                        Cancel
                    </Button>
                    {this.renderSubmitEnd()}
                </div>
            </div>
        )
    }

    renderPopup() {
        if (!this.state.showNewVisitor && !this.state.signOutShowing) {
            return null;
        }
        return (
            <div style={styles.popUpOuter}>
                <div style={styles.whiteBox}>
                    <div style={{ borderRadius: 15, backgroundColor: 'white', padding: 20, marginBottom: 30 }}>
                        {this.renderForm()}
                    </div>
                </div>
            </ div >
        );
    }
    closeSignOut() {
        if (this.state.submitting) {
            return;
        }
        this.setState({ signOutShowing: false, selectedEntry: {}, signatureEmpty: false })
    }
    openSignout(entry) {
        this.setState({ signOutShowing: true, signatureEmpty: false, selectedEntry: entry, timeOut: moment().format('HH:mm') })
    }

    renderTimeOut(entry) {
        if (_.isEmpty(entry.TimeOut)) {
            return (
                <Button buttonType='primary' isActive onClick={() => this.openSignout(entry)}>Sign Out</Button>
            )
        }
        return (
            <div>
                <div style={{ fontWeight: 'bold' }}>
                    {moment.utc(entry.TimeOut).local().format('h:mma')}
                </div>
                {!_.isEmpty(entry.TimeOutSig) &&
                    <div style={{ width: 120, height: 50, border: '1px solid #ccc', borderRadius: 5, backgroundColor: 'yellow', backgroundImage: `url(${baseStorageUrl}${entry.TimeOutSig})`, backgroundSize: '120px 50px' }}>
                    </div>
                }
                {entry.Completed &&
                    (<div style={{ fontWeight: 'bold' }}>
                        Work Completed
                    </div>)}
            </div>
        )
    }

    renderVisTest() {
        let source = _.sortBy(this.state.visitors, 'EntryTimeUnix');
        source = _.filter(source, (ev) => {
            if (!ev) {
                return false
            }
            return (!this.state.onlySignedIns || _.isEmpty(ev.TimeOut)) &&
                (ev.WorkerName.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1);
            /*  if (!this.state.showPast) {
                 return moment.utc(ev.Date).isSameOrAfter(this.state.now);
             }
             return true; */
        });

        return (source.map((ev, index) => {
            if (ev != null) {
                return (
                    <tr key={index}>
                        <td>{ev.Id}</td>
                        <td>{moment.utc(ev.Date).local().format('D MMM YY')}</td>
                        <td className="table-TitleColumn">{ev.WorkerName}</td>
                        <td>{ev.CompName}</td>
                        <td>{ev.WorkDes}</td>
                        <td>{ev.Phone}</td>
                        <td style={{ backgroundColor: ev.ConId ? 'rgba(166, 255, 158, 0.5)' : 'rgba(255, 158, 158, 1)' }}>
                            {ev.ConId ? 'Yes' : 'No'}
                        </td>
                        <td style={{ backgroundColor: ev.Safety ? 'rgba(166, 255, 158, 0.5)' : 'rgba(255, 158, 158, 1)' }}>
                            {ev.Safety ? 'Yes' : 'No'}
                        </td>
                        <td>
                            {ev.PermitsOwned && ev.PermitsOwned.HotWorks && (<p style={{ marginBottom: 0 }}>• Hot Works</p>)}
                            {ev.PermitsOwned && ev.PermitsOwned.Heights && (<p style={{ marginBottom: 0 }}>• Working at Heights</p>)}
                            {ev.PermitsOwned && ev.PermitsOwned.ConfinedSpace && (<p style={{ marginBottom: 0 }}>• Confined Space</p>)}
                            {ev.PermitsOwned && ev.PermitsOwned.Fire && (<p style={{ marginBottom: 0 }}>• Fire Impairment</p>)}
                            {ev.PermitsOwned && ev.PermitsOwned.Hazardous && (<p style={{ marginBottom: 0 }}>• Other Hazardous Work</p>)}
                        </td>
                        <td style={{ backgroundColor: ev.Terms ? 'rgba(166, 255, 158, 0.5)' : 'rgba(255, 158, 158, 1)' }}>
                            {ev.Terms ? 'Yes' : 'No'}
                        </td>
                        <td>
                            <div style={{ fontWeight: 'bold' }}>
                                {moment.utc(ev.TimeIn).local().format('h:mma')}
                            </div>
                            {!_.isEmpty(ev.TimeInSig) &&
                                <div style={{ border: '1px solid #ccc', borderRadius: 5, width: 120, height: 50, backgroundImage: `url(${baseStorageUrl}${ev.TimeInSig})`, backgroundSize: '120px 50px' }}>
                                </div>
                            }
                        </td>
                        <td>
                            {this.renderTimeOut(ev)}
                        </td>
                    </tr >
                );
            }
            return null;
        })
        )
    }

    renderTable() {
        return (
            <Table className="plussTable" striped bordered condensed hover style={{ minWidth: '100%' }}>
                <thead>
                    <tr>
                        <th>
                            Job ID
                    </th>
                        <th>
                            Date
                    </th>
                        <th>
                            Name
                    </th>
                        <th>
                            Company
                    </th>
                        <th>
                            Work
                    </th>
                        <th style={{}}>
                            Phone No.
                    </th>
                        <th style={{}}>
                            ID Checked
                    </th>
                        <th style={{}}>
                            Safety Ind.
                    </th>
                        <th style={{}}>
                            Permits
                    </th>
                        <th style={{}}>
                            Terms Read
                    </th>
                        <th style={{}}>
                            Time In
                    </th>
                        <th style={{}}>
                            Time Out
                    </th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderVisTest()}
                </tbody>
            </Table>
        );
    }


    render() {
        return (
            <div style={{ minWidth: '100%' }}>
                {this.renderPopup()}

                <AddButton
                    onClick={this.showNewVisitor.bind(this)}
                    text='NEW CONTRACTOR SIGN IN'
                    style={{ marginBottom: 32 }}
                />

                <Form>
                    <Row className="show-grid">
                        <Col xs={6}>
                            <InputGroup
                                id={`search`}
                                type="text"
                                label="Contractor Search"
                                placeholder="Search by contractor's name."
                                value={this.state.search}
                                onChange={(e) => this.handleChange(e)}
                            />
                        </Col>
                        <Col xs={6}>
                            <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                                <CheckBox
                                    id='onlySignedIns'
                                    label='Only show signed in contractors'
                                    isActive={this.state.onlySignedIns}
                                    onChange={() => this.setState({ onlySignedIns: !this.state.onlySignedIns })}
                                />
                            </div>
                        </Col>
                    </Row>
                </Form>
                {this.renderTable()}
            </div >
        );
    }
}

const styles = {
    popUpOuter: {
        zIndex: 100,
        width: '100%',
        height: '100%',
        position: 'fixed',
        backgroundColor: 'rgba(0,0,0,0.7)',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 30,
        overflow: 'auto',
        flex: '0 1 auto',
        top: 0,
        left: 0
        //alignItems: 'center'
    },
    whiteBox: {
        minWidth: '60%',
        maxWidth: '80%'
        //maxHeight: '90%',
        //overflow: 'auto',
    }
};

const mapStateToProps = (state) => {
    const { auth } = state;
    return { auth };
};

export default connect(mapStateToProps, {})(ContractorList);