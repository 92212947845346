import _ from 'lodash';
import { authedFunction } from '../session';
import { getUrl } from './helper';

export const reactionActions = {
  addComment: (entityId, entityType, site, comment, image) => {
    const data = {
      entityId,
      entityType,
      site,
      comment,
    };
    if (!_.isEmpty(image)) {
      data.image = image;
    }
    return authedFunction({
      method: 'POST',
      url: getUrl('reactions', 'comments/add'),
      data,
    });
  },
  removeComment: (id) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('reactions', 'comments/remove'),
      data: {
        id,
      },
    });
  },
  getComments: (entityId, entityType, minTime) => {
    const query = {
      entityId,
      entityType,
    };
    if (minTime) {
      query.minTime = minTime;
    }
    return authedFunction({
      method: 'GET',
      url: getUrl('reactions', 'comments/get', query),
    });
  },
};
