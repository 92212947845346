import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import { Header, AddButton } from '../../components';
import { validateAccess } from '../../session';
import { eventsLoaded, eventSubmissionsLoaded, setAuth } from '../../actions';
import { eventActions } from '../../webapi';
import ListEvents from './ListEvents';
import EventSubmissions from './EventSubmissions';
import { COLOUR_BRANDING_OFF, COLOUR_BRANDING_ACTION, TEXT_LIGHT } from '../../js';
import SelectedEventDate from './SelectedEventDate';
import EventHubAnalytics from './EventHubAnalytics';
import { getUrlParams, isContentSource } from '../../helper';
import AvailableEvents from './AvailableEvents';
import { hasAvailableEvents } from '../../config';
import { Text } from '../../components/text';

class EventsHub extends Component {
  constructor(props) {
    super(props);

    const params = getUrlParams();
    let selectedSection = 'all';
    if (params.tab) {
      selectedSection = params.tab;
    }

    this.state = {
      selectedSection,
      selectedView: 'calendar',
      location: '',

      loadingEvents: false,
      loadingSubmissions: false,

      submissionEntries: [],

      events: [],
      upcomingEvents: [],
      pastEvents: [],
      now: moment.utc(),
      onlyFuture: true,
      search: '',
    };
  }

  UNSAFE_componentWillMount() {
    this.updateProps(this.props);
    if (Object.keys(this.props.events).length > 0) {
      var maxElem = _.maxBy(this.props.events, (event) => {
        if (!event) {
          return 0;
        }
        return event.Changed;
      });
      this.loadEvents(maxElem.Changed + 1);
    } else {
      this.loadEvents();
    }
    this.getSubmissions();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps);
  }

  updateProps(props) {
    const events = [];

    props.events.forEach((ev) => {
      if (ev != null && !ev.Deleted) {
        events.push(ev);
      }
    });

    const upcomingEvents = _.filter(events, (ev) => {
      if (ev.IsEveryday) {
        return true;
      }
      if (ev.RepeatedTimes) {
        if (
          _.some(ev.RepeatedTimes, (rep) => {
            return moment.utc(rep.Time).isSameOrAfter(this.state.now);
          })
        ) {
          return true;
        }
        return false;
      }
      return (
        moment.utc(ev.StartTime).isSameOrAfter(this.state.now) ||
        (ev.EndTime != null && ev.EndTime !== '' && moment.utc(ev.EndTime).isSameOrAfter(this.state.now))
      );
    });

    const pastEvents = _.reject(events, (item) =>
      _.find(upcomingEvents, (t) => {
        return t.Id === item.Id;
      }),
    );

    this.setState({ events, upcomingEvents, submissionEntries: props.submissions, pastEvents });
  }

  loadEvents(time) {
    this.setState({
      loadingEvents: true,
    });

    eventActions
      .getEvents(this.props.auth.site, time, true)
      .then((res) => {
        this.setState({
          loadingEvents: false,
        });
        if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
          this.props.eventsLoaded(res.data);
        }
      })
      .catch((res) => {
        this.setState({ loadingEvents: false });
        alert('Something went wrong with the request. Please try again.');
      });
  }

  getSubmissions() {
    this.setState({
      loadingSubmissions: true,
    });

    eventActions
      .getEventSubmissions(this.props.auth.site)
      .then((res) => {
        this.setState({
          loadingSubmissions: false,
        });
        if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
          this.props.eventSubmissionsLoaded(res.data);
        }
      })
      .catch((res) => {
        this.setState({ loadingSubmissions: false });
      });
  }

  updateView(choice, useCache) {
    if (useCache && !_.isUndefined(this.props.auth.viewChoice)) {
      choice = this.props.auth.viewChoice;
    }
    this.setState({ selectedView: choice, selectedDate: null });
    if (choice !== 'calendar') {
      this.props.setAuth({ viewChoice: choice });
    }
  }

  selectDate(date) {
    this.setState({
      selectedDate: date,
    });
  }

  addNew = (action) => {
    if (
      validateAccess(this.props.auth.site, 'events', this.props.auth) ||
      validateAccess(this.props.auth.site, 'eventSubmit', this.props.auth)
    ) {
      if (action && typeof action === 'string') {
        this.props.history.push(`/events/event?action=${action}`);
      } else {
        this.props.history.push('/events/event');
      }
    }
  };

  canAddNew(isClass) {
    if (validateAccess(this.props.auth.site, 'events', this.props.auth)) {
      return isClass ? '' : true;
    }
    return isClass ? ' hub-sideContent-topButton--hide' : false;
  }

  getSideBarSectionColour(id) {
    return this.state.selectedSection === id ? { backgroundColor: '#fff' } : {};
  }

  renderSelectedDate() {
    return <SelectedEventDate date={this.state.selectedDate} onClose={this.selectDate.bind(this, null)} />;
  }

  renderStats(gweg, loading) {
    if (loading) {
      return <FontAwesome style={styles.spinner} name="spinner fa-pulse fa-fw" />;
    }
    return gweg;
  }

  renderLeftBar() {
    if (
      validateAccess(this.props.auth.site, 'eventSubmit', this.props.auth) &&
      !validateAccess(this.props.auth.site, 'events', this.props.auth) &&
      !validateAccess(this.props.auth.site, 'eventAttendance', this.props.auth)
    ) {
      return null;
    }
    return (
      <div className="hub-sideContent">
        {/* Top Add Button */}
        <div className="hub-sideContent-topButton" />
        <div style={{ paddingLeft: 15, width: '100%' }}>
          {/* Title */}
          <div className="fontMedium fontSize-36 text-dark" style={styles.sideBarTitleSection}>
            Events
          </div>
          {/* Content */}
          {/* Analytics */}
          <div
            onClick={() => {
              this.setState({ selectedSection: 'Analytics' });
            }}
            className="sideBarSection"
            style={this.getSideBarSectionColour('Analytics')}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              <FontAwesome name="line-chart" className="sideBarSection__icon" />
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">Analytics</div>
          </div>
          {/* All Events */}
          <div
            onClick={() => {
              this.setState({ selectedSection: 'all' });
              this.updateView('calendar');
            }}
            className="sideBarSection"
            style={this.getSideBarSectionColour('all')}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats(this.state.events.length, this.state.loadingEvents)}
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">All Events</div>
          </div>
          {/* Upcoming Events */}
          <div
            onClick={() => {
              this.setState({ selectedSection: 'upcoming' });
              this.updateView('list', true);
            }}
            className="sideBarSection"
            style={this.getSideBarSectionColour('upcoming')}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats(this.state.upcomingEvents.length, this.state.loadingEvents)}
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">Upcoming events</div>
          </div>
          {/* Past Events */}
          <div
            onClick={() => {
              this.setState({ selectedSection: 'past' });
              this.updateView('list', true);
            }}
            className="sideBarSection"
            style={this.getSideBarSectionColour('past')}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats(this.state.pastEvents.length, this.state.loadingEvents)}
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">Past events</div>
          </div>
          {/* Event Submissions */}
          {validateAccess(this.props.auth.site, 'events', this.props.auth) && (
            <div
              onClick={() => {
                this.setState({ selectedSection: 'submissions' });
                this.updateView('list', true);
              }}
              className="sideBarSection"
              style={this.getSideBarSectionColour('submissions')}
            >
              <div
                className="fontMedium fontSize-36"
                style={{
                  lineHeight: '50px',
                  color: !this.state.loadingSubmissions && this.state.submissionEntries.length > 0 ? COLOUR_BRANDING_ACTION : TEXT_LIGHT,
                }}
              >
                {this.renderStats(this.state.submissionEntries.length, this.state.loadingSubmissions)}
              </div>
              <div className="fontRegular fontSize-16 text-light lineHeight-22">Submissions</div>
            </div>
          )}
          {hasAvailableEvents && (
            <div
              onClick={() => {
                this.setState({ selectedSection: 'available' });
              }}
              className="sideBarSection"
              style={this.getSideBarSectionColour('available')}
            >
              <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
                <FontAwesome name="calendar-o" className="sideBarSection__icon" />
              </div>
              <div className="fontRegular fontSize-16 text-light lineHeight-22">Available events</div>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderEventSubmitButton() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: 'fit-content' }}>
        <AddButton onClick={this.addNew.bind(this)} text="SUBMIT NEW EVENT" />
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 32 }}>
          <div className="emptyState" />
          <div className="marginTop-32" style={{ maxWidth: 500, textAlign: 'center' }}>
            <Text type="h3">Submit your new event here. You will be notified once your event has been approved.</Text>
          </div>
        </div>
      </div>
    );
  }

  renderRight() {
    if (
      validateAccess(this.props.auth.site, 'eventSubmit', this.props.auth) &&
      !validateAccess(this.props.auth.site, 'events', this.props.auth) &&
      !validateAccess(this.props.auth.site, 'eventAttendance', this.props.auth)
    ) {
      return this.renderEventSubmitButton();
    }
    if (this.state.selectedSection === 'Analytics') {
      return <EventHubAnalytics events={this.state.events} />;
    }
    if (this.state.selectedSection === 'submissions') {
      return <EventSubmissions />;
    }
    if (this.state.selectedSection === 'available') {
      return <AvailableEvents />;
    }
    return (
      <ListEvents
        filterOption={this.state.selectedSection}
        viewType={this.state.selectedView}
        updateView={this.updateView.bind(this)}
        selectDate={this.selectDate.bind(this)}
        selectedDate={this.state.selectedDate}
      />
    );
  }

  renderAddButton() {
    if (isContentSource(this.props.auth.site)) {
      return (
        <div className="topHeader-addButtonWithDropdown" style={{ position: 'relative' }}>
          <AddButton text="NEW EVENT" />
          <div
            className="hub-sideContent-topButton-dropdown"
            style={{
              backgroundColor: 'white',
              position: 'absolute',
              top: 30,
              minWidth: 100,
              boxShadow: '0px 0px 16px rgba(106, 163, 216, 0.5)',
            }}
          >
            <div
              onClick={() => {
                this.addNew('global');
              }}
              className="fontRegular fontSize-16 subtleHover"
              style={{ padding: 8 }}
            >
              New global event
            </div>
            <div
              onClick={() => {
                this.addNew('template');
              }}
              className="fontRegular fontSize-16 subtleHover"
              style={{ padding: 8 }}
            >
              New event template
            </div>
          </div>
        </div>
      );
    }
    return <AddButton onClick={this.addNew.bind(this)} text="NEW EVENT" />;
  }

  render() {
    return (
      <div className="hub-wrapperContainer">
        {this.renderSelectedDate()}
        {this.renderLeftBar()}
        <div className="hub-headerContentWrapper">
          <Header>{this.canAddNew() && this.renderAddButton()}</Header>
          <div className="hub-contentWrapper">{this.renderRight()}</div>
        </div>
      </div>
    );
  }
}

const styles = {
  sideBarTitleSection: {
    lineHeight: '50px',
    marginTop: 30,
    marginBottom: 30,
    paddingLeft: 24,
  },
  spinner: {
    fontSize: 32,
    color: COLOUR_BRANDING_OFF,
  },
};

const mapStateToProps = (state) => {
  const { events, submissions } = state.events;
  const { auth } = state;
  return {
    events,
    submissions,
    auth,
    paymentEnabled: auth.user && auth.user.paymentInfo && auth.user.paymentInfo.enabled,
  };
};

export default connect(mapStateToProps, { eventsLoaded, eventSubmissionsLoaded, setAuth })(EventsHub);
