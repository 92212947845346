import React, { Component } from 'react';
import { Table, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { keysLoaded, removeKey } from '../../actions';
import { Button, GenericInput } from '../../components';
import { checkLoggedIn, getSessionTokenAWS, validateAccess } from '../../session';
import { keyActions } from '../../webapi';
import { COLOUR_BRANDING_ACTION, COLOUR_BRANDING_OFF } from '../../js';

class Keys extends Component {
  state = {
    keyList: [],
    showCompleted: false,
    sortColumn: 'name',
    sortDesc: false,
    keyToRegister: null,
    showWarnings: false,
    nameInput: '',
    phoneInput: '',
    emailInput: '',

    loading: true,
  };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
    this.updateProps(this.props);
  }

  componentDidMount() {
    this.getData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps);
  }

  updateProps(props) {
    this.setState({
      keyList: props.keys,
    });
  }

  getData() {
    keyActions.getKeys(this.props.auth.site).then(res => {
      if (res.data != null) {
        this.props.keysLoaded(res.data);
      }
      this.setState({ loading: false });
    });
  }

  registerKey(ev) {
    this.setState({
      keyToRegister: ev,
    });
  }

  closeRegister() {
    this.setState({
      keyToRegister: null,
    });
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  handleDeregisterKey(ev) {
    if (window.confirm(`Mark ${ev.name} as returned?`)) {
      getSessionTokenAWS()
        .then(idToken => {
          keyActions
            .deregisterKey(this.props.auth.site, ev.RowId, this.props.auth.user.uid, idToken)
            .then(res => {
              console.log('success');
              this.getData();
            })
            .catch(res => {
              alert('Something went wrong with the request. Please try again.');
            });
        })
        .catch(error2 => {});
    }
  }

  handleSubmit() {
    if (!this.validateRegister()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({ submitting: true });

    getSessionTokenAWS()
      .then(idToken => {
        keyActions
          .registerKey(
            this.props.auth.site,
            this.state.keyToRegister.RowId,
            this.state.nameInput,
            this.state.phoneInput,
            this.state.emailInput,
            this.props.auth.user.uid,
            idToken,
          )
          .then(res => {
            this.setState({
              success: true,
              submitting: false,
              keyToRegister: null,
            });
            this.getData();
          })
          .catch(res => {
            this.setState({ submitting: false });
            alert('Something went wrong with the request. Please try again.');
          });
      })
      .catch(error2 => {
        this.setState({ submitting: false });
      });
  }

  validateRegister() {
    if (this.state.submitting) {
      return false;
    }
    if (_.isEmpty(this.state.nameInput)) {
      return false;
    }
    return true;
  }

  removeKey(ev) {
    if (window.confirm(`Are you sure you want to delete ${ev.name}?`)) {
      getSessionTokenAWS().then(idToken => {
        keyActions
          .deleteKey(this.props.auth.site, ev.RowId, this.props.auth.user.uid, idToken)
          .then(res => {
            // action
            console.log('success');
            this.props.removeKey(ev.RowId);
          })
          .catch(res => {
            alert('Something went wrong with the request. Please try again.');
          });
      });
    }
  }

  sortByCol(col) {
    if (this.state.sortColumn === col) {
      this.setState({
        sortDesc: !this.state.sortDesc,
      });
    } else {
      this.setState({
        sortColumn: col,
        sortDesc: false,
      });
    }
  }

  renderRegistered(ev) {
    if (!ev.heldBy) {
      return (
        <a
          style={{ cursor: 'pointer' }}
          onClick={() => {
            this.registerKey(ev);
          }}
        >
          Register to person
        </a>
      );
    }
    return (
      <div>
        <p>
          Currently held by
          <br />
          {ev.heldBy.name}
          {ev.heldBy.phone && (
            <span>
              <br />
              {ev.heldBy.phone}
            </span>
          )}
          {ev.heldBy.email && (
            <span>
              <br />
              {ev.heldBy.email}
            </span>
          )}
        </p>
        <a
          style={{ cursor: 'pointer' }}
          onClick={() => {
            this.handleDeregisterKey(ev);
          }}
        >
          Mark as returned
        </a>
      </div>
    );
  }

  renderList() {
    if (_.isEmpty(this.state.keyList)) {
      return null;
    }
    let source = _.sortBy(this.state.keyList, this.state.sortColumn);
    if (this.state.sortDesc) {
      source.reverse();
    }
    source = _.filter(source, ev => {
      if (!ev) {
        return false;
      }
      return true;
    });

    return source.map((ev, index) => {
      if (ev != null) {
        return (
          <tr key={index}>
            <td className="lineClamp-2 table-id">{ev.id}</td>
            <td>{ev.name}</td>
            {validateAccess(this.props.auth.site, 'keysCards', this.props.auth) && (
              <td className="table-options">{this.renderRegistered(ev)}</td>
            )}
            <td className="table-options">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {validateAccess(this.props.auth.site, 'keysCards', this.props.auth) && (
                  <Link to={`/signinHub/keyhistory/${ev.RowId}`}>
                    <span style={{ padding: 5, whiteSpace: 'nowrap' }}>View history</span>
                  </Link>
                )}
                {validateAccess(this.props.auth.site, 'keysCards', this.props.auth) && (
                  <Link to={`/signinHub/key/${ev.RowId}`}>
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 12, cursor: 'pointer' }} name="pencil" />
                  </Link>
                )}
                {validateAccess(this.props.auth.site, 'keysCards', this.props.auth) && (
                  <a
                    onClick={() => {
                      this.removeKey(ev);
                    }}
                  >
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8, cursor: 'pointer' }} name="minus-circle" />
                  </a>
                )}
              </div>
            </td>
          </tr>
        );
      }
      return null;
    });
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div>
        <Button inline buttonType="outlined" onClick={this.closeRegister.bind(this)} isActive style={{ marginRight: 16 }}>
          Cancel
        </Button>
        <Button inline buttonType="primary" onClick={this.handleSubmit.bind(this)} isActive={this.validateRegister()}>
          Save
        </Button>
      </div>
    );
  }

  renderForm() {
    return (
      <div style={{}}>
        <div style={{ textAlign: 'center', marginBottom: 10, fontSize: 30, fontWeight: 'bold' }}>
          Register {this.state.keyToRegister.name}
        </div>
        <div>
          <Row className="show-grid">
            <Col xs={6}>
              <GenericInput
                id={`nameInput`}
                type="text"
                label="Name"
                value={this.state.nameInput}
                onChange={e => this.handleChange(e)}
                isRequired
                isValid={() => {
                  return !_.isEmpty(this.state.nameInput);
                }}
                showError={() => {
                  return this.state.showWarnings && _.isEmpty(this.state.nameInput);
                }}
              />
            </Col>
            <Col xs={6}>
              <GenericInput
                id={`phoneInput`}
                type="text"
                label="Phone (optional)"
                value={this.state.phoneInput}
                onChange={e => this.handleChange(e)}
              />
              <GenericInput
                id={`emailInput`}
                type="text"
                label="Email (optional)"
                value={this.state.emailInput}
                onChange={e => this.handleChange(e)}
              />
            </Col>
          </Row>
        </div>

        <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', paddingTop: 15, borderTop: '1px solid #ccc' }}>
          {this.renderSubmit()}
        </div>
      </div>
    );
  }

  renderPopup() {
    if (!this.state.keyToRegister) {
      return null;
    }
    return (
      <div style={styles.popUpOuter}>
        <div style={styles.whiteBox}>
          <div style={{ borderRadius: 15, backgroundColor: 'white', padding: 20, marginBottom: 30 }}>{this.renderForm()}</div>
        </div>
      </div>
    );
  }

  renderSort(col) {
    if (col !== this.state.sortColumn) {
      return null;
    }
    return <FontAwesome style={{ marginLeft: 5 }} name={this.state.sortDesc ? 'chevron-up' : 'chevron-down'} />;
  }

  sortIsActive(col) {
    if (col !== this.state.sortColumn) {
      return '';
    }
    return ' table--columnActive';
  }

  newKey() {
    this.props.history.push(`/signinHub/key`);
  }

  render() {
    if (this.state.keyList.length === 0 && this.state.loading) {
      return (
        <div style={{ minWidth: '100%' }}>
          <div className="padding-60 paddingVertical-40" style={{ textAlign: 'center' }}>
            <FontAwesome style={{ fontSize: 30, color: COLOUR_BRANDING_OFF }} name="spinner fa-pulse fa-fw" />
          </div>
        </div>
      );
    }
    return (
      <div style={{ minWidth: '100%' }}>
        {this.renderPopup()}
        <div
          onClick={this.newKey.bind(this)}
          className="flex borderRadius-4 genericBoxShadow genericBoxShadow-heavy pointer"
          style={{ width: 'fit-content', marginBottom: 32 }}
        >
          <div
            className="paddingHorizontal-16 sf-semibold text-brandingAction fontSize-13"
            style={{ minWidth: 120, lineHeight: '30px', paddingRight: 16 }}
          >
            NEW KEY
          </div>
          <div style={{ color: '#fff', backgroundColor: COLOUR_BRANDING_ACTION, paddingLeft: 12, paddingRight: 12 }}>
            <FontAwesome className="fontSize-13" style={{ color: '#fff', lineHeight: '30px' }} name={'plus'} />
          </div>
        </div>

        <Table className="plussTable" striped bordered condensed hover style={{ minWidth: '100%' }}>
          <thead>
            <tr>
              <th className="table-id" style={{ width: 80 }}>
                ID
              </th>
              <th
                className={`${this.sortIsActive('name')}`}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  this.sortByCol('name');
                }}
              >
                Key/Card name{this.renderSort('name')}
              </th>
              <th>Registered to</th>
              <th style={{ width: 150 }} />
            </tr>
          </thead>
          <tbody>{this.renderList()}</tbody>
        </Table>
      </div>
    );
  }
}

const styles = {
  popUpOuter: {
    zIndex: 100,
    width: '100%',
    height: '100%',
    position: 'fixed',
    backgroundColor: 'rgba(0,0,0,0.7)',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 30,
    overflow: 'auto',
    flex: '0 1 auto',
    top: 0,
    left: 0,
  },
  whiteBox: {
    minWidth: '60%',
    maxWidth: '80%',
    //maxHeight: '90%',
    //overflow: 'auto',
  },
};

const mapStateToProps = state => {
  const { keys } = state.keys;
  const { auth } = state;
  return {
    keys,
    auth,
  };
};

export default connect(mapStateToProps, { keysLoaded, removeKey })(withRouter(Keys));
