import _ from 'lodash';
import { authedFunction } from '../session';
import { getUrl } from './helper';

export const userActions = {
  createNewUser: (user, userExtra) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('users', 'add'),
      data: {
        user,
        userExtra,
      },
    });
  },
  bulkNewUser: (users, site) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('users', 'addbulk'),
      data: {
        site,
        users,
      },
    });
  },
  getbulks: (site) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('users', 'getbulks'),
      data: {
        site,
      },
    });
  },
  fetchUsers: (site) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('users', 'get'),
      data: {
        site,
      },
    });
  },
  fetchUser: (site, userId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('users', 'getsingle'),
      data: {
        site,
        userId,
      },
    });
  },
  updateUser: (site, userId, user) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('users', 'manageUser'),
      data: {
        site,
        userId,
        user,
      },
    });
  },
  getSiteResidentsCount: (site) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('users', 'getexp'),
      data: { site, isCount: true },
    });
  },
  sendWelcomeEmail: (site, userId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('users', 'welcome/email'),
      data: { site, userId },
    });
  },
  sendWelcomeText: (site, userId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('users', 'welcome/text'),
      data: { site, userId },
    });
  },
  getInviteCode: (userId, type, site) => {
    const query = {};
    if (!_.isEmpty(userId)) {
      query.userId = userId;
    }
    if (!_.isEmpty(type)) {
      query.type = type;
    }
    if (!_.isEmpty(site)) {
      query.site = site;
    }
    return authedFunction({
      method: 'GET',
      url: getUrl('users', 'invite/get', query),
    });
  },
  generateInviteCode: (userId, type, site) => {
    const data = {};
    if (!_.isEmpty(userId)) {
      data.userId = userId;
    }
    if (!_.isEmpty(type)) {
      data.type = type;
    }
    if (!_.isEmpty(site)) {
      data.site = site;
    }
    return authedFunction({
      method: 'POST',
      url: getUrl('users', 'invite/generate'),
      data,
    });
  },
  updateProfile: (input) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('users', 'updateProfile'),
      data: {
        details: input,
      },
    });
  },
};
