import _ from 'lodash';

export const compileStats = (stats) => {
    const totalStats = {};
    Object.keys(stats).forEach((id) => {
        Object.keys(stats[id]).forEach((activityId) => {
            if (Array.isArray(stats[id][activityId])) {
                if (!totalStats[activityId]) {
                    totalStats[activityId] = [];
                }
                Array.prototype.push.apply(totalStats[activityId], stats[id][activityId]);
                totalStats[activityId] = _.uniq(totalStats[activityId]);
            } else {
                if (!totalStats[activityId]) {
                    totalStats[activityId] = 0;
                }
                totalStats[activityId] += stats[id][activityId];
            }
        });
    });
    return totalStats;
}

export const getInsight = (stats, previousStats, source, key, activity) => {
    let top = { Stats: { [activity]: 0 } };
    Object.keys(stats).forEach((entityId) => {
        if (stats[entityId][activity] && stats[entityId][activity] > top.Stats[activity]) {
            const entity = _.find(source, (s) => {
                return s[key] === entityId;
            });
            if (entity) {
                top = { Stats: stats[entityId], Entity: entity };
            }
        }
    });
    if (top.Stats[activity] === 0) {
        return null;
    }
    if (previousStats) {
        top.PreviousStats = previousStats[top.Entity[key]];
    }
    return top;
}

export const getAnalyticsFilterOptions = () => {
    return [
        {
            text: 'Yesterday',
            dayCount: 1,
            prevText: 'day before'
        },
        {
            text: 'Last 7 days',
            dayCount: 7,
            prevText: 'previous 7 days'
        },
        {
            text: 'Last 14 days',
            dayCount: 14,
            prevText: 'previous 14 days'
        },
        {
            text: 'Last 30 days',
            dayCount: 30,
            prevText: 'previous 30 days'
        },
        {
            text: 'Custom Range',
            dayCount: 0,
            prevText: 'previous * days'
        }
    ]
}