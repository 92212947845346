import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { Header, AddButton } from '../../components';
import { servicesLoaded } from '../../actions';
import { serviceActions } from '../../webapi';
import { COLOUR_BRANDING_OFF } from '../../js';
import ServicesList from './ServicesList';
import ServiceHubAnalytics from './ServiceHubAnalytics';

class ServicesHub extends Component {
  state = {
    selectedSection: 'all',

    loadingAll: false,
    loadingHQ: this.props.auth.site !== 'hq',

    allList: [],
    isActive: [],
    sponsored: [],

    hqList: [],
  };

  UNSAFE_componentWillMount() {
    this.updateProps(this.props);
  }

  componentDidMount() {
    this.getData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps);
  }

  updateProps(props, hqServices) {
    const hqServicesToUse = hqServices || this.state.hqList;
    const allList = _.filter([...props.services, ...hqServicesToUse], (s) => {
      return !s.Sponsored || s.Internal;
    });

    this.setState({
      allList,
      isActive: _.filter(allList, (s) => {
        return this.isActive(s);
      }),
      sponsored: _.filter(allList, (s) => {
        return s.Sponsored;
      }),
    });
  }

  isLocal(service) {
    return this.props.auth.site === service.Site;
  }

  isActive(service) {
    if (this.isLocal(service)) {
      return !service.Inactive;
    }
    return service.SitesOptIn && _.includes(service.SitesOptIn, this.props.auth.site);
  }

  updateServiceList() {
    this.updateProps(this.props, this.state.hqServices);
  }

  getData() {
    this.setState({
      loadingAll: true,
    });
    serviceActions
      .getServices(this.props.auth.site)
      .then((res) => {
        this.setState({
          loadingAll: false,
        });
        if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
          this.props.servicesLoaded(res.data);
        }
      })
      .catch((res2) => {
        this.setState({ loadingAll: false });
        console.log('fail from getServices');
        console.log(res2);
      });
    if (this.props.auth.site !== 'hq') {
      serviceActions.getServices('hq').then((res) => {
        this.setState({
          hqList: res.data,
          loadingHQ: false,
        });
        this.updateProps(this.props, res.data);
      });
    }
  }

  getSource() {
    if (this.state.selectedSection === 'all') {
      return this.state.allList;
    }
    if (this.state.selectedSection === 'active') {
      return this.state.isActive;
    }
    if (this.state.selectedSection === 'sponsored') {
      return this.state.sponsored;
    }
    return [];
  }

  addNew() {
    this.props.history.push(`/services/service`);
  }

  canAddNew(isClass) {
    return isClass ? '' : true;
  }

  getSideBarSectionColour(id) {
    return this.state.selectedSection === id ? { backgroundColor: '#fff' } : {};
  }

  renderStats(gweg, loading) {
    if (loading) {
      return <FontAwesome style={styles.spinner} name="spinner fa-pulse fa-fw" />;
    }
    return gweg;
  }

  renderLeftBar() {
    return (
      <div className="hub-sideContent">
        {/* Top Add Button */}
        <div className="hub-sideContent-topButton" />
        <div style={{ paddingLeft: 15, width: '100%' }}>
          {/* Title */}
          <div className="fontMedium fontSize-36 text-dark" style={styles.sideBarTitleSection}>
            Services
          </div>
          {/* Content */}
          {/* Analytics */}
          <div
            onClick={() => {
              this.setState({ selectedSection: 'Analytics' });
            }}
            className="sideBarSection"
            style={this.getSideBarSectionColour('Analytics')}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              <FontAwesome name="line-chart" className="sideBarSection__icon" />
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">Analytics</div>
          </div>
          {/* All Articles */}
          {this.props.auth.site !== 'hq' && (
            <div
              onClick={() => {
                this.setState({ selectedSection: 'active' });
              }}
              className="sideBarSection"
              style={this.getSideBarSectionColour('active')}
            >
              <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
                {this.renderStats(this.state.isActive.length, this.state.loadingAll || this.state.loadingHQ)}
              </div>
              <div className="fontRegular fontSize-16 text-light lineHeight-22">Active Services</div>
            </div>
          )}
          <div
            onClick={() => {
              this.setState({ selectedSection: 'all' });
            }}
            className="sideBarSection"
            style={this.getSideBarSectionColour('all')}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats(this.state.allList.length, this.state.loadingAll || this.state.loadingHQ)}
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">
              {this.props.auth.site === 'hq' ? 'All' : 'Available'} Services
            </div>
          </div>
          <div
            onClick={() => {
              this.setState({ selectedSection: 'sponsored' });
            }}
            className="sideBarSection"
            style={this.getSideBarSectionColour('sponsored')}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats(this.state.sponsored.length, this.state.loadingAll || this.state.loadingHQ)}
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">Sponsored Services</div>
          </div>
        </div>
      </div>
    );
  }

  renderRight() {
    if (this.state.selectedSection === 'Analytics') {
      console.log('all the list');
      console.log(this.state.allList);
      return <ServiceHubAnalytics services={this.state.allList} />;
    }
    return <ServicesList page={'services'} source={this.getSource()} updateServiceList={this.updateServiceList.bind(this)} />;
  }

  render() {
    return (
      <div className="hub-wrapperContainer">
        {this.renderLeftBar()}
        <div className="hub-headerContentWrapper">
          <Header>{this.canAddNew() && <AddButton onClick={this.addNew.bind(this)} text="NEW SERVICE" />}</Header>
          <div className="hub-contentWrapper">{this.renderRight()}</div>
        </div>
      </div>
    );
  }
}

const styles = {
  sideBarTitleSection: {
    lineHeight: '50px',
    marginTop: 30,
    marginBottom: 30,
    paddingLeft: 24,
  },
  sideBarSection: {
    weight: '100%',
    minWidth: 200,
    padding: 32,
    paddingLeft: 24,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  spinner: {
    fontSize: 32,
    color: COLOUR_BRANDING_OFF,
  },
};

const mapStateToProps = (state) => {
  const { services } = state.services;
  const { auth } = state;
  return {
    services,
    auth,
  };
};

export default connect(mapStateToProps, { servicesLoaded })(ServicesHub);
