import React from 'react';
import FontAwesome from 'react-fontawesome';

const renderTitle = (title) => {
  return <p className="fontRegular fontSize-32 marginBottom-20">{title}</p>;
};

const renderDescription = (description) => {
  return <p className="fontRegular fontSize-18 marginBottom-20">{description}</p>;
};

const renderOpenLinkStep = (url, incognito = true, label = null) => {
  return (
    <li className="paddingBottom-16">
      {incognito ? (
        <div>
          Right click on the following link and click <b>Open Link in Incognito Window</b>
        </div>
      ) : (
        <div>Click the following link</div>
      )}
      <br />
      <a href={url} target="_blank" rel="noopener noreferrer">
        {label || url}
      </a>
    </li>
  );
};

const renderTextStep = (content, style) => {
  return (
    <li className="paddingBottom-20" style={style}>
      {content}
    </li>
  );
};

const renderImageStep = (textContent, imageSrc, textWidth = '50%', imageWidth = '35%') => {
  return (
    <li className="paddingBottom-20">
      <div className="flex flex-row flex-align-start">
        <div style={{ width: textWidth, marginRight: 10 }}>{textContent}</div>
        <img style={{ width: imageWidth, objectFit: 'contain' }} src={imageSrc} alt="Step" />
      </div>
    </li>
  );
};

const renderEmbed = (text) => {
  return (
    <pre className="marginTop-10" style={{ fontSize: 8 }}>
      {renderCopyToClipboardText(text)}
    </pre>
  );
};

const copyToClipboard = async (text) => {
  const id = `copied${text}ToClipboard`;
  document.getElementById(id).style.opacity = 1;
  if ('clipboard' in navigator) {
    await navigator.clipboard.writeText(text);
  } else {
    document.execCommand('copy', true, text);
  }
  setTimeout(() => {
    document.getElementById(id).style.opacity = 0;
  }, 2000);
};

const renderCopyToClipboardText = (text) => {
  const id = `copied${text}ToClipboard`;
  return (
    <div className="flex flex-row flex-center">
      {text}
      <FontAwesome className="marginLeft-10" name={'clone'} onClick={() => copyToClipboard(text)} />
      <div id={id} className="text-tang" style={{ marginLeft: 4, zIndex: 0, opacity: 0 }}>
        Copied!
      </div>
    </div>
  );
};

export { renderTitle, renderDescription, renderOpenLinkStep, renderTextStep, renderImageStep, renderEmbed, renderCopyToClipboardText };
