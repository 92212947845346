import React, { Component } from 'react';
import { Table, Form, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { Storage } from 'aws-amplify';
import SignatureCanvas from 'react-signature-canvas';
import { visitorActions } from '../../webapi';
import { Button, CheckBox, InputGroup, AddButton } from '../../components';
import { generateImageName } from '../../helper';
import { getSessionTokenAWS } from '../../session';
import { baseStorageUrl } from '../../config';

class VisitorList extends Component {
    state = {
        events: [],
        sortColumn: 'StartTime', // column to sort by
        sortDesc: false, // if true, sort descending rather than ascending
        now: moment().startOf('d'),
        onlyFuture: true,

        showNewVisitor: false,
        signOutShowing: false,
        onlySignedIns: true,

        date: moment().format('YYYY-MM-DD'),
        timeIn: moment().format('HH:mm'),

        timeOut: moment().format('HH:mm'),

        showWarnings: false,

        resName: '',
        resUnit: '',
        visName: '',
        visPhone: '',

        visitors: {},

        newForm: {
            resName: '',
            resUnit: '',
            visName: '',
            visPhone: ''
        },

        selectedEntry: {},

        width: 0, height: 0,
        showPast: false,

        search: '',
    }


    UNSAFE_componentWillMount() {
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        //docHeight = $(document).height();

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        this.getData();
    }

    getData() {
        visitorActions.getVisitors(this.props.auth.site)
            .then((res) => {
                this.setState({
                    visitors: res.data
                });
            });
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    sigPad = {};
    sigPadEnd = {};

    clear = () => {
        if (this.state.submitting) {
            return;
        }
        this.sigPad.clear();
    }

    clearEnd = () => {
        if (this.state.submitting) {
            return;
        }
        this.sigPadEnd.clear()
    }

    sortByCol(col) {
        if (this.state.sortColumn === col) {
            this.setState({
                sortDesc: !this.state.sortDesc
            });
        } else {
            this.setState({
                sortColumn: col,
                sortDesc: false
            });
        }
    }

    renderSort(col) {
        if (col !== this.state.sortColumn) {
            return null;
        }
        return (
            <FontAwesome style={{ marginLeft: 5 }} name={this.state.sortDesc ? 'chevron-up' : 'chevron-down'} />
        );
    }

    handleCheckChange(event) {
        var stateChange = {};
        stateChange[event.target.getAttribute('id')] = event.target.checked;
        this.setState(stateChange);
    }

    showNewVisitor(byPass) {
        if (this.state.submitting) {
            return;
        }

        this.updateWindowDimensions();
        this.setState({ showNewVisitor: !this.state.showNewVisitor, signatureEmpty: false })
    }

    handleChange(event) {
        var stateChange = {};
        stateChange[event.target.getAttribute('id')] = event.target.value;
        this.setState(stateChange);
    }

    getTime(input) {
        var timeSplit = input.split(":");
        return {
            hours: timeSplit[0],
            minutes: timeSplit[1]
        };
    }

    handleEndSubmit() {
        if (!this.validateDateTime(this.state.timeOut)) {
            return;
        }
        if (this.sigPadEnd.isEmpty()) {
            this.setState({ signatureEmpty: true });
            return
        }
        this.setState({ submitting: true });

        const TimeOut = new Date(this.state.selectedEntry.Date.trim());
        var time = this.getTime(this.state.timeOut.trim());
        TimeOut.setHours(time.hours);
        TimeOut.setMinutes(time.minutes);

        const entry = {
            RowId: this.state.selectedEntry.RowId,
            Id: this.state.selectedEntry.Id,
            TimeOut: moment(TimeOut).toISOString(),
            Site: this.props.auth.site
        };


        const signImOut = this.sigPadEnd.getTrimmedCanvas()
            .toDataURL('image/png');
        const imageData = new Buffer(signImOut.replace(/^data:image\/\w+;base64,/, ""), 'base64');

        this.sigPadEnd.off();

        const imgName = generateImageName('signature');

        var self = this;

        Storage.put(`images/${imgName}.png`, imageData, {
            contentType: 'image/png',
            level: 'public'
        }).then((res) => {
            entry.TimeOutSig = res.key;

            getSessionTokenAWS()
                .then((token) => {
                    visitorActions.saveVisitorEntry(entry, this.props.auth.site, this.props.auth.user.uid, token)
                        .then((res) => {
                            var newStates = { success: true, submitting: false, showNewVisitor: false, signOutShowing: false, selectedEntry: {} };
                            self.sigPadEnd.on();
                            self.setState(newStates);
                            self.getData();
                        }).catch((res) => {
                            self.sigPadEnd.on();
                            self.setState({ submitting: false });
                            alert("Something went wrong with the request. Please try again.");
                        });
                });
        }).catch((uploadErrorRes) => {
            self.sigPadEnd.on();
            self.setState({ submitting: false });
        });
    }

    handleSubmit() {
        if (!this.validateForm()) {
            return;
        }
        if (this.sigPad.isEmpty()) {
            this.setState({ signatureEmpty: true });
            return
        }

        this.setState({ submitting: true, signatureEmpty: false });
        /* const repTime = new Date(this.state.date.trim());
        var time = this.getTime(this.state.timeIn.trim());
        repTime.setHours(time.hours);
        repTime.setMinutes(time.minutes); */

        const TimeIn = new Date(this.state.date.trim());
        var time = this.getTime(this.state.timeIn.trim());
        TimeIn.setHours(time.hours);
        TimeIn.setMinutes(time.minutes);

        const entry = {
            ResName: this.state.resName,
            ResUnit: this.state.resUnit,
            VisName: this.state.visName,
            VisPhone: this.state.visPhone,
            TimeIn: moment(TimeIn).toISOString(),
            Date: moment(this.state.date).toISOString(),
            EntryTime: moment().toISOString(),
            Site: this.props.auth.site
        };

        const signIm = this.sigPad.getTrimmedCanvas()
            .toDataURL('image/png');
        const imageData = new Buffer(signIm.replace(/^data:image\/\w+;base64,/, ""), 'base64')

        this.sigPad.off();

        const imgName = generateImageName('signature');

        var self = this;

        Storage.put(`images/${imgName}.png`, imageData, {
            contentType: 'image/png',
            level: 'public'
        }).then((res) => {
            console.log('success');
            entry.TimeInSig = res.key;

            getSessionTokenAWS()
                .then((token) => {
                    visitorActions.saveVisitorEntry(entry, this.props.auth.site, this.props.auth.user.uid, token)
                        .then((res) => {
                            var newStates = { ...self.state.newForm, success: true, submitting: false, showNewVisitor: false };
                            self.sigPad.on();
                            self.setState(newStates);
                            self.getData();
                        }).catch((res) => {
                            self.sigPad.on();
                            self.setState({ submitting: false });
                            alert("Something went wrong with the request. Please try again.")
                        });
                });
        }).catch((err) => {
            console.log('errored on upload');
            console.log(err);
            self.sigPad.on();
            self.setState({ submitting: false });
        });
    }

    validateForm() {
        if (!this.validateDateTime(this.state.timeIn) || !this.validateDateTime(this.state.date)) {
            return false
        }

        if (this.isEmpty(this.state.resName) || this.isEmpty(this.state.resUnit) || this.isEmpty(this.state.visName) || this.isEmpty(this.state.visPhone)) {
            return false
        }

        return true;
    }

    renderSubmitEnd() {
        if (this.state.submitting) {
            return <Button buttonType='secondary'>Saving...</Button>;
        }
        return (
            <Button
                inline
                buttonType='primary'
                onClick={this.handleEndSubmit.bind(this)}
                isActive={this.validateDateTime(this.state.timeOut)}
            >
                Save
             </Button>
        );
    }

    renderSubmit() {
        if (this.state.submitting) {
            return <Button buttonType='secondary'>Saving...</Button>;
        }
        return (
            <Button
                inline
                buttonType='primary'
                onClick={this.handleSubmit.bind(this)}
                isActive={this.validateForm()}
            >
                Save
             </Button>
        );
    }

    validateDateTime(e) {
        return e.trim() !== '';
    }

    isEmpty(text) {
        return text === '';
    }


    renderForm() {
        if (this.state.showNewVisitor) {
            return this.renderNewVisitorForm();
        }
        return this.renderSignOut();
    }

    renderNewVisitorForm() {
        return (
            <div style={{}}>
                <div style={{ textAlign: 'center', marginBottom: 10, fontSize: 36, fontWeight: 'bold' }}>
                    New Visitor Sign In
                </div>
                <div>
                    <Form>
                        <Row className="show-grid">
                            <Col xs={6}>
                                {/* menu start date */}
                                <InputGroup
                                    id={`date`}
                                    type="date"
                                    label="Visiting Date"
                                    value={this.state.date}
                                    showError={this.state.showWarnings && !this.validateDateTime(this.state.date)}
                                    onChange={(e) => this.handleChange(e)}
                                />
                            </Col>
                            <Col xs={6}>
                                <InputGroup
                                    id={`timeIn`}
                                    type="time"
                                    label="Time Arrived"
                                    value={this.state.timeIn}
                                    showError={this.state.showWarnings && !this.validateDateTime(this.state.timeIn)}
                                    onChange={(e) => this.handleChange(e)}
                                />
                            </Col>
                        </Row>

                        <Row className="show-grid">
                            <Col xs={6}>
                                <InputGroup
                                    id="resName"
                                    type="text"
                                    label="Name"
                                    placeholder="Ashley Davis"
                                    value={this.state.resName}
                                    showError={this.state.showWarnings && this.isEmpty(this.state.resName)}
                                    onChange={(e) => this.handleChange(e)}
                                />
                            </Col>
                            <Col xs={6}>
                                <InputGroup
                                    id="resUnit"
                                    type="text"
                                    label="Address"
                                    placeholder="Unit 111"
                                    value={this.state.resUnit}
                                    showError={this.state.showWarnings && this.isEmpty(this.state.resUnit)}
                                    onChange={(e) => this.handleChange(e)}
                                />
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col xs={6}>
                                <InputGroup
                                    id="visName"
                                    type="text"
                                    label="Visitor's Name"
                                    placeholder="Mia Davis"
                                    value={this.state.visName}
                                    showError={this.state.showWarnings && this.isEmpty(this.state.visName)}
                                    onChange={(e) => this.handleChange(e)}
                                />
                            </Col>
                            <Col xs={6}>
                                <InputGroup
                                    id="visPhone"
                                    type="text"
                                    label="Phone Number"
                                    placeholder="04XX XXX XXX"
                                    value={this.state.visPhone}
                                    showError={this.state.showWarnings && this.isEmpty(this.state.visPhone)}
                                    onChange={(e) => this.handleChange(e)}
                                />
                            </Col>
                        </Row>
                    </Form>
                    <div style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 5 }}>Signature</div>
                    {this.state.signatureEmpty && <div style={{ color: 'red' }}>Please provide a signature</div>}
                    <div style={{ backgroundColor: 'gray', padding: 5, alignContent: 'center', justifyContent: 'center', display: 'flex' }}>
                        <SignatureCanvas penColor='black'
                            canvasProps={{ width: 400, height: 150, className: 'sigCanvas' }}
                            backgroundColor={'#fff'}
                            ref={(ref) => { this.sigPad = ref }}
                        />
                    </div>
                    <Button style={{ marginTop: 5 }} isActive buttonType='secondary' onClick={() => this.clear()}>Clear Signature</Button>
                </div>
                <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', paddingTop: 15, borderTop: '1px solid #ccc' }}>
                    <Button
                        inline
                        buttonType='tertiary'
                        onClick={() => { this.showNewVisitor(); }}
                        isActive
                        style={{ marginRight: 16 }}
                    >
                        Cancel
                    </Button>
                    {this.renderSubmit()}
                </div>
            </div>
        )
    }

    renderSignOut() {
        return (
            <div style={{}}>
                <div style={{ textAlign: 'center', marginBottom: 10, fontSize: 24, fontWeight: 'bold' }}>
                    Sign Out
                </div>
                <div>
                    <div style={{ fontSize: 20 }}>
                        {this.state.selectedEntry.VisName}
                    </div>
                    <div>
                        <span style={{ fontWeight: 'bold' }}> Visiting: </span> {this.state.selectedEntry.ResName}
                    </div>
                    <div>
                        {moment.utc(this.state.selectedEntry.Date).local().format('D MMM YY')} -
                  {moment.utc(this.state.selectedEntry.TimeIn).local().format('h:mma')}
                    </div>
                </div>
                <div style={{ marginTop: 20 }}>
                    <Form>
                        <Row className="show-grid">
                            {/*  <Col xs={6}>
                                <InputGroup
                                    id={`endDate`}
                                    type="date"
                                    label="Visiting Date"
                                    value={this.state.endDate}
                                    showError={this.state.showWarnings && !this.validateDateTime(this.state.endDate)}
                                    onChange={(e) => this.handleChange(e)}
                                />
                            </Col> */}
                            <Col xs={6}>
                                <InputGroup
                                    id={`timeOut`}
                                    type="time"
                                    label="Departing Time"
                                    value={this.state.timeOut}
                                    showError={this.state.showWarnings && !this.validateDateTime(this.state.timeOut)}
                                    onChange={(e) => this.handleChange(e)}
                                />
                            </Col>
                        </Row>
                    </Form>
                    <div style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 5 }}>Signature</div>
                    {this.state.signatureEmpty && <div style={{ color: 'red' }}>Please provide a signature</div>}
                    <div style={{ backgroundColor: 'gray', padding: 5, alignContent: 'center', justifyContent: 'center', display: 'flex' }}>
                        <SignatureCanvas penColor='black'
                            canvasProps={{ width: 400, height: 150, className: 'sigCanvas' }}
                            backgroundColor={'#fff'}
                            ref={(ref) => { this.sigPadEnd = ref }}
                        />
                    </div>
                    <Button style={{ marginTop: 5 }} isActive buttonType='secondary' onClick={() => this.clearEnd()}>Clear Signature</Button>
                </div>
                <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', paddingTop: 15, borderTop: '1px solid #ccc' }}>
                    <Button
                        inline
                        buttonType='tertiary'
                        onClick={() => { this.closeSignOut(); }}
                        isActive
                        style={{ marginRight: 16 }}
                    >
                        Cancel
                    </Button>
                    {this.renderSubmitEnd()}
                </div>
            </div>
        );
    }
    renderPopup() {
        if (!this.state.showNewVisitor && !this.state.signOutShowing) {
            return null;
        }
        return (
            <div style={styles.popUpOuter}>
                <div style={styles.whiteBox}>
                    <div style={{ borderRadius: 15, backgroundColor: 'white', padding: 20, marginBottom: 30 }}>
                        {this.renderForm()}
                    </div>
                </div>
            </div>
        );
    }
    closeSignOut() {
        if (this.state.submitting) {
            return;
        }
        this.setState({ signOutShowing: false, selectedEntry: {}, signatureEmpty: false })
    }

    openSignout(entry) {
        this.updateWindowDimensions();
        this.setState({ signOutShowing: true, signatureEmpty: false, selectedEntry: entry, timeOut: moment().format('HH:mm') })
    }

    renderTimeOut(entry) {
        if (_.isEmpty(entry.TimeOut)) {
            return (
                <Button buttonType='primary' isActive onClick={() => this.openSignout(entry)}>Sign Out</Button>
            )
        }
        return (
            <div>
                <div style={{ fontWeight: 'bold' }}>
                    {moment.utc(entry.TimeOut).local().format('h:mma')}
                </div>
                {!_.isEmpty(entry.TimeOutSig) &&
                    <div style={{ width: 150, height: 60, border: '1px solid #ccc', borderRadius: 5, backgroundImage: `url(${baseStorageUrl}${entry.TimeOutSig})`, backgroundSize: '150px 60px' }}>
                    </div>
                }
            </div>
        )
    }

    renderVisTest() {
        if (_.isEmpty(this.state.visitors)) {
            return null;
        }
        let source = _.sortBy(this.state.visitors, 'EntryTimeUnix');
        source = _.filter(source, (ev) => {
            if (!ev) {
                return false
            }
            return (!this.state.onlySignedIns || _.isEmpty(ev.TimeOut)) &&
                (this.state.showPast || moment.utc(ev.Date).isSameOrAfter(this.state.now)) &&
                (ev.VisName.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1);
        });

        return (source.map((ev, index) => {
            if (ev != null) {
                return (
                    <tr key={index}>
                        <td>{moment.utc(ev.Date).local().format('D MMM YY')}</td>
                        <td>{ev.ResName}</td>
                        <td>{ev.ResUnit}</td>
                        <td className="table-TitleColumn">{ev.VisName}</td>
                        <td>{ev.VisPhone}</td>
                        <td>
                            <div style={{ fontWeight: 'bold' }}>
                                {moment.utc(ev.TimeIn).local().format('h:mma')}
                            </div>
                            {!_.isEmpty(ev.TimeInSig) &&
                                <div style={{ width: 150, height: 60, border: '1px solid #ccc', borderRadius: 5, backgroundImage: `url(${baseStorageUrl}${ev.TimeInSig})`, backgroundSize: '150px 60px' }}>
                                </div>
                            }
                        </td>
                        <td>
                            {this.renderTimeOut(ev)}
                        </td>

                    </tr >
                );
            }
            return null;
        })
        )
    }

    render() {
        return (
            <div style={{ minWidth: '100%' }}>
                {this.renderPopup()}

                <AddButton
                    onClick={this.showNewVisitor.bind(this)}
                    text='NEW VISITOR SIGN IN'
                    style={{ marginBottom: 32 }}
                />

                <Form>
                    <Row className="show-grid">
                        <Col xs={6}>
                            <InputGroup
                                id={`search`}
                                type="text"
                                label="Visitor Search"
                                placeholder="Search by visitor's name."
                                value={this.state.search}
                                onChange={(e) => this.handleChange(e)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <CheckBox
                                id='onlySignedIns'
                                label='Only show signed in visitors'
                                isActive={this.state.onlySignedIns}
                                onChange={() => this.setState({ onlySignedIns: !this.state.onlySignedIns })}
                            />
                        </Col>
                        <Col xs={6}>
                            <CheckBox
                                id='showPast'
                                label='Show Previous Days'
                                isActive={this.state.showPast}
                                onChange={() => this.setState({ showPast: !this.state.showPast })}
                            />
                        </Col>
                    </Row>
                </Form>
                <Table className="plussTable" striped bordered condensed hover style={{ minWidth: '100%' }}>
                    <thead>
                        <tr>
                            <th style={{ cursor: 'pointer' }} onClick={() => { this.sortByCol('Id'); }}>
                                Date
                                </th>
                            <th style={{ cursor: 'pointer' }} onClick={() => { this.sortByCol('StartTime'); }}>
                                Name
                                </th>
                            <th style={{ cursor: 'pointer' }} onClick={() => { this.sortByCol('Title'); }}>
                                Address
                                </th>
                            <th style={{ cursor: 'pointer' }} onClick={() => { this.sortByCol('Location'); }}>
                                Visitor Name
                                </th>
                            <th style={{}}>
                                Visitor Phone No.
                                </th>
                            <th style={{}}>
                                Time In
                                </th>
                            <th style={{}}>
                                Time Out
                                </th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Render Current Visitors here! */}
                        {this.renderVisTest()}
                    </tbody>
                </Table>
            </div>
        );
    }
}

const styles = {
    popUpOuter: {
        zIndex: 100,
        width: '100%',
        height: '100%',
        position: 'fixed',
        backgroundColor: 'rgba(0,0,0,0.7)',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 30,
        overflow: 'auto',
        flex: '0 1 auto',
        top: 0,
        left: 0
        //alignItems: 'center'
    },
    whiteBox: {
        minWidth: '60%',
        maxWidth: '80%'
        //maxHeight: '90%',
        //overflow: 'auto',
    }
};

const mapStateToProps = (state) => {
    const { auth } = state;
    return { auth };
};

export default connect(mapStateToProps, {})(VisitorList);