import React, { Component } from 'react';
import _ from 'lodash';
import { SideNav, UserRefresh } from './';
import { OnboardingBlocker } from './onboarding';
import GoogleAnalytics from './GoogleAnalytics';

class AppWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      hideHeaderSidenavPages: [
        '/',
        '/login',
        '/logout',
        '/android',
        '/contractorTerms',
        '/commBank/addcard',
        '/stripe/addcard',
        '/newsitefromtemplate',
        '/featurepicker',
        '/newsite',
        '/interface',
        '/signup',
        '/signup/features',
        '/signup/complete',
        '/tv',
        '/tvpicker',
        '/signin',
        '/signinpreview',
        '/newsletter/generate',
      ],
      hideHeaderSidenavStartWith: ['/interface', '/tvpicker', '/embed'],
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  exludeOuter() {
    return (
      _.includes(this.state.hideHeaderSidenavPages, window.location.pathname) ||
      _.some(this.state.hideHeaderSidenavStartWith, (uri) => {
        return window.location.pathname.indexOf(uri) === 0;
      })
    );
  }

  renderSideNav() {
    if (!this.exludeOuter()) {
      return <SideNav />;
    }
    return null;
  }

  render() {
    return (
      <div style={styles.mainWrapper}>
        <UserRefresh />
        <OnboardingBlocker />
        {this.renderSideNav()}
        <div style={styles.pageWrapper}>{this.props.children}</div>
        <GoogleAnalytics />
      </div>
    );
  }
}

const styles = {
  mainWrapper: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'transparent',
    overflow: 'hidden',
  },
  pageWrapper: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
};

export { AppWrapper };
