import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import { eventSubmissionsLoaded } from '../../actions';
import { eventActions } from '../../webapi';
import { checkLoggedIn } from '../../session';
import { COLOUR_BRANDING_ACTION } from '../../js';
import { isPaidItem } from '../../helper';

class EventSubmissions extends Component {
  state = {
    eventEntries: [],
    sortColumn: 'Changed', // column to sort by
    sortDesc: false, // if true, sort descending rather than ascending
    now: moment.utc(),
    onlyFuture: true,
  };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
    this.updateProps(this.props);
  }

  componentDidMount() {
    this.getData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps);
  }

  updateProps(props) {
    this.setState({
      eventEntries: props.submissions,
    });
  }

  getData() {
    eventActions.getEventSubmissions(this.props.auth.site).then((res) => {
      if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
        this.props.eventSubmissionsLoaded(res.data);
      }
    });
  }

  sortByCol(col) {
    if (this.state.sortColumn === col) {
      this.setState({
        sortDesc: !this.state.sortDesc,
      });
    } else {
      this.setState({
        sortColumn: col,
        sortDesc: false,
      });
    }
  }

  renderEvents() {
    let source = [];
    if (this.state.sortColumn === 'SubmittedBy') {
      source = _.sortBy(this.state.eventEntries, (user) => {
        return user.SubmittedBy ? user.SubmittedBy.displayName : '';
      });
    } else {
      source = _.sortBy(this.state.eventEntries, this.state.sortColumn);
    }
    if (this.state.sortDesc) {
      source.reverse();
    }
    source = _.filter(source, (ev) => {
      if (!ev) {
        return false;
      }
      return true;
    });

    return source.map((ev) => {
      if (ev != null) {
        return (
          <tr key={ev.RowId}>
            <td>{moment.utc(ev.Timestamp).local().format('DD-MM-YYYY h:mma')}</td>
            <td className="table-TitleColumn">
              <Link to={`/events/eventsubmission/${ev.RowId}`}>
                {isPaidItem(this.props.paymentEnabled, ev.Tickets) && (
                  <FontAwesome style={{ color: COLOUR_BRANDING_ACTION }} name="usd fa-fw" />
                )}
                {ev.Title}
              </Link>
            </td>
            <td>{ev.SubmittedBy ? ev.SubmittedBy.displayName : ''}</td>
          </tr>
        );
      }
      return null;
    });
  }

  renderSort(col) {
    if (col !== this.state.sortColumn) {
      return null;
    }
    return <FontAwesome style={{ marginLeft: 5 }} name={this.state.sortDesc ? 'chevron-up' : 'chevron-down'} />;
  }

  sortIsActive(col) {
    if (col !== this.state.sortColumn) {
      return '';
    }
    return ' table--columnActive';
  }

  render() {
    return (
      <div style={{ minWidth: '100%', paddingTop: 75 }}>
        <Table className="plussTable" striped bordered condensed hover>
          <thead>
            <tr>
              <th
                className={`${this.sortIsActive('Changed')}`}
                style={{ cursor: 'pointer', width: 100 }}
                onClick={() => {
                  this.sortByCol('Changed');
                }}
              >
                Time{this.renderSort('Changed')}
              </th>
              <th
                className={`${this.sortIsActive('Title')}`}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  this.sortByCol('Title');
                }}
              >
                Title{this.renderSort('Title')}
              </th>
              <th
                className={`${this.sortIsActive('SubmittedBy')}`}
                style={{ cursor: 'pointer', width: 150 }}
                onClick={() => {
                  this.sortByCol('SubmittedBy');
                }}
              >
                Submitted By{this.renderSort('SubmittedBy')}
              </th>
            </tr>
          </thead>
          <tbody>{this.renderEvents()}</tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { submissions } = state.events;
  const { auth } = state;
  return {
    submissions,
    auth,
    paymentEnabled: auth.user && auth.user.paymentInfo && auth.user.paymentInfo.enabled,
  };
};

export default connect(mapStateToProps, { eventSubmissionsLoaded })(EventSubmissions);
