import { getUrl } from './helper';
import { authedFunction } from '../session';

export const facilityActions = {
  addOrEditFacility: (data) => {
    let url = getUrl('facilities', 'add');
    if (data.RowId != null) {
      url = getUrl('facilities', 'edit');
    }
    const request = {
      method: 'POST',
      url,
      data: {
        facility: data,
      },
    };
    return authedFunction(request);
  },
  removeFacility: (site, id) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('facilities', 'remove'),
      data: {
        id,
        site,
      },
    });
  },
  getFacilities: (site) => {
    let query = { site };
    return authedFunction({
      method: 'GET',
      url: getUrl('facilities', 'getFacilities', query),
    });
  },
  getFacility: (site, id) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('facilities', `get/${id}`, { site }),
    });
  },
};
